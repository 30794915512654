import React from 'react';
import Cancel from '../../assets/miscIcons/Cancel.svg';
import PrimarySecondaryModalButtons from '../PrimarySecondaryModalButtons';

function CancelSubModal({ handleClose, handleCancel }) {
  return (
    <div>
      <div className='flex flex-col justify-center sm:w-full'>
        <img className='mt-40 mx-auto h-160 w-160' src={Cancel} />
        <p className='w-10/12 mx-auto mt-27 leading-8 text-24 font-bold text-center sm:w-369 md:text-32'>
          You are about to cancel your subscription
        </p>
      </div>
      <hr className='w-10/12 mx-auto mt-37 border-gray-hrLine border-solid sm:w-456' />

      <div className='mt-33 flex flex-wrap justify-center'>
        <p className='w-10/12 text-16 text-center sm:w-434'>
          After your subscription end date, your child won&apos;t be able to
          access the platform anymore.
        </p>
        <p className='text-16 font-black text-center'>
          Are you sure you want to cancel your subscription?
        </p>
      </div>
      <PrimarySecondaryModalButtons
        containerClassName='mt-25 mb-40 space-x-20 sm:mt-63'
        primaryBtnClassName='h-48 w-138'
        primaryBtnText='Yes'
        primaryBtnOnClick={handleCancel}
        secondaryBtnClassName='h-48 w-138'
        secondaryBtnText='No'
        secondaryBtnOnClick={handleClose}
      />
    </div>
  );
}

export default CancelSubModal;
