import React, { useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import FormInput from '../../../components/FormInput';
import Error from '../../../components/Error';
import useForm from '../../../hooks/useForm';
import {
  requestCreateAccount,
  requestAccountCreated,
  requestGTMEvent,
  setLoading,
  requestSetSpinner,
  requestResetSpinner,
} from '../../../store/redux/actions';
import { useRegisterUser } from '../../../hooks/useAPIs';
import InputWithToggle from '../../../components/InputWithToggle';
import { createAccount } from '../constants';
import BackToStepButton from '../../../components/BackButtons/BackToStepButton';
import Loading from '../../../components/Loading';
import LoadingSpinner from '../../../components/LoadingSpinner';

let firstName = '';
let lastName = '';
let email = '';
let emailConfirm = '';
let password = '';
let passwordConfirm = '';
let termsAndPrivacy = false;
let errorMessage = '';
let emailNotification = true;

function CreateAccount({
  createUserAccount,
  setAccountCreated,
  isLoggedIn,
  loading,
  setSpinner,
  resetSpinner,
}) {
  const [errMessage, setErrMessage] = useState('');
  const { userProfileError, setError, execute, isLoading } = useRegisterUser();
  const { values, handleChange } = useForm({
    initialValues: {
      firstName,
      lastName,
      email,
      emailConfirm,
      password,
      passwordConfirm,
      termsAndPrivacy,
      emailNotification,
    },
  });

  const history = useHistory();
  const isValid = () => {
    errorMessage = '';

    ({
      firstName,
      lastName,
      email,
      emailConfirm,
      password,
      passwordConfirm,
      termsAndPrivacy,
    } = values);

    const isEmptyInput =
      !firstName ||
      !lastName ||
      !email ||
      !emailConfirm ||
      !termsAndPrivacy ||
      !password;

    if (isEmptyInput) {
      errorMessage =
        'Need to complete the form below before submitting request.';
    } else if (email !== emailConfirm) {
      errorMessage = 'The email and confirm email values do not match.';
    } else if (password !== passwordConfirm) {
      errorMessage = 'The password and password confirm values do not match.';
    } else if (password.length < 8) {
      errorMessage = 'Password must be 8 or more characters long';
    }

    return !errorMessage;
  };

  if (
    isLoggedIn &&
    [
      '/subscribe/create-account',
      '/subscribe/plan-details',
      'subscribe/checkout',
      '/subscribe/success',
    ].includes(location.pathname)
  ) {
    history.replace('/parent-portal/manage-subscription');
  }

  const showSpinner = useSelector((state) => state.user.showSpinner);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  function setSpinnerAndDisableButton() {
    setSpinner();
    setButtonDisabled(true);
  }

  function resetSpinnerAndDisabledButton() {
    resetSpinner();
    setButtonDisabled(false);
    values.termsAndPrivacy = false;
  }

  const handleAccountCreation = async (event) => {
    event.preventDefault();
    setSpinnerAndDisableButton();
    setErrMessage('');
    await setError('');

    if (isValid()) {
      const { error } = await execute(values);

      if (error?.code === 'UsernameExistsException') {
        setErrMessage(errorMessage);
        resetSpinnerAndDisabledButton();
      } else {
        createUserAccount();
        if (!error) {
          setAccountCreated();
          history.replace('plan-details');
        }
      }
    } else {
      setErrMessage(errorMessage);
      resetSpinnerAndDisabledButton();
    }
  };

  function isTermsChecked() {
    return values.termsAndPrivacy === true;
  }
  const inputClassNames =
    'h-56 w-full m-w-360 mb-15 border pl-20 pr-45 border-light-grey bg-transparent text-16 sm:mb-30';

  return (
    <>
      <div
        className='h-auto pb-25 px-25 bg-white flex flex-wrap content-center justify-center rounded-3xl shadow-encantosShadow sm:h-450 md:px-45'
        style={{ justifyContent: 'center' }}
      >
        <div className='w-full'>
          <div
            className={`${
              errMessage || userProfileError ? 'mb-0' : 'mb-35'
            } flex justify-start mt-25  text-16 sm:mt-0 `}
          >
            <p
              className='mr-5'
              dangerouslySetInnerHTML={{
                __html: createAccount.title,
              }}
            ></p>
            <Link to='/login'>
              <p className='font-bold'>{createAccount.signIn}</p>
            </Link>
          </div>
          {userProfileError && <Error error={userProfileError.message} />}
          {errMessage && (
            <div className='mb-35'>
              <Error error={errMessage} />
            </div>
          )}
          {isLoading || loading ? (
            <Loading />
          ) : (
            <form id='create-account-form' className='w-full'>
              <div className='flex flex-col sm:flex-row md:space-x-15 '>
                <FormInput
                  required={true}
                  type={'text'}
                  className={inputClassNames}
                  placeholder={'First Name'}
                  name={'firstName'}
                  value={values.firstName}
                  handleChange={handleChange}
                />
                <FormInput
                  required={true}
                  type={'text'}
                  className={inputClassNames}
                  placeholder={'Last Name'}
                  name={'lastName'}
                  value={values.lastName}
                  handleChange={handleChange}
                />
              </div>
              <div className='flex flex-col sm:flex-row md:space-x-15 '>
                <FormInput
                  required={true}
                  type={'email'}
                  className={inputClassNames}
                  placeholder={'Email Address'}
                  name={'email'}
                  value={values.email}
                  handleChange={handleChange}
                />
                <FormInput
                  required={true}
                  type={'email'}
                  className={inputClassNames}
                  placeholder={'Confirm Email Address'}
                  name={'emailConfirm'}
                  value={values.emailConfirm}
                  handleChange={handleChange}
                />
              </div>

              <div className='flex flex-col sm:flex-row md:space-x-15'>
                <InputWithToggle
                  required={true}
                  placeholder='Password'
                  name='password'
                  value={values.password}
                  handleChange={handleChange}
                  className={inputClassNames}
                  containerClassName='relative w-full'
                  iconClassName='text-2xl text-purple-light'
                  iconContainerClassName='top-20 right-20'
                />
                <InputWithToggle
                  required={true}
                  placeholder='Confirm Password'
                  name='passwordConfirm'
                  value={values.passwordConfirm}
                  handleChange={handleChange}
                  className={inputClassNames}
                  containerClassName='relative w-full'
                  iconClassName='text-2xl text-purple-light'
                  iconContainerClassName='top-20 right-20'
                />
              </div>

              <div className='flex items-center max-w-fitcontent'>
                <input
                  className='mx-5 mt-auto mb-auto'
                  name='emailNotification'
                  type='checkbox'
                  onChange={handleChange}
                  defaultChecked={true}
                />
                <p className='mx-auto md:mt-0'>
                  Keep me posted on the newest storyworlds, learning tips, and
                  more with emails from Encantos
                </p>
              </div>

              <div className='flex items-center max-w-fitcontent justify-center'>
                <input
                  id='termsInputBox'
                  className='mx-5 mt-auto mb-auto'
                  name='termsAndPrivacy'
                  type='checkbox'
                  onChange={handleChange}
                />
                <p
                  className='mx-auto md:mt-0'
                  dangerouslySetInnerHTML={{
                    __html: createAccount.termsOfService,
                  }}
                ></p>
              </div>
            </form>
          )}
        </div>
      </div>
      <div className='w-full flex justify-end'>
        <button
          id='create-account-form-button'
          onClick={handleAccountCreation}
          type='submit'
          disabled={isTermsChecked() === false || buttonDisabled}
          className={`flex justify-center items-center ${
            isTermsChecked()
              ? 'bg-purple-light border-purple-light'
              : 'bg-gray-light border-gray-light'
          } px-26 mt-45 min-h-56 rounded-2xl epp-hover text-white`}
        >
          <p className='text-16 font-extrabold capitalize text-white'>
            {createAccount.buttonTitle}
          </p>
          <LoadingSpinner
            isOn={showSpinner}
            displaySpinner={showSpinner}
            className={'flex min-w-fitcontent justify-right h-27'}
          />
        </button>
      </div>
    </>
  );
}

function mapStateToProps({ user }, ownProps) {
  const { loading, accountCreated, authToken } = user;
  return { loading, accountCreated, isLoggedIn: !!authToken };
}

function mapDispatchToProps(dispatch) {
  return {
    createUserAccount: (payload) => dispatch(requestCreateAccount(payload)),
    setAccountCreated: () => dispatch(requestAccountCreated()),
    updateLoading: (loading) => dispatch(setLoading(loading)),
    requestGTM: (payload) => dispatch(requestGTMEvent(payload)),
    setSpinner: () => dispatch(requestSetSpinner()),
    resetSpinner: () => dispatch(requestResetSpinner()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateAccount);
