import React from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { requestGTMEvent } from '../store/redux/actions';

function ExternalButton({
  linkClassName,
  name,
  path,
  pClassName,
  id = '',
  requestGTM,
}) {
  const defaultLinkClassNames = 'hover:underline rounded-large text-white';
  const defaultPClassNames =
    'cursor-pointer flex text-white h-50 items-center w-max';

  const linkClasses = clsx(linkClassName, defaultLinkClassNames);
  const pClasses = clsx(pClassName, defaultPClassNames);

  function handleClick(event) {
    requestGTM({
      type: 'event',
      event: {
        category: 'download',
        action: 'click',
        label: 'location',
        value: id || 'external button',
      },
    });
  }

  return (
    <a
      id={id}
      className={linkClasses}
      href={path}
      target='_blank'
      rel='noreferrer'
      onClick={handleClick}
    >
      <p className={pClasses}>{name}</p>
    </a>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    requestGTM: (payload) => dispatch(requestGTMEvent(payload)),
  };
}

export default connect(null, mapDispatchToProps)(ExternalButton);
