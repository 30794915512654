import { useState, useCallback, useContext } from 'react';
import { useDispatch } from 'react-redux';
import AWS from 'aws-sdk';
import UserPool from '../UserPool';
import { MessagingContext } from '../contexts/MessagingContext';
import useAuth from '../hooks/useAuth';

export const editUserProfile = (values, access_token) => {
  const dispatch = useDispatch();
  const cognito = new AWS.CognitoIdentityServiceProvider({
    region: 'us-east-1',
  });
  const params = {
    AccessToken: access_token,
    UserAttributes: [
      {
        Name: 'custom:firstName',
        Value: values.firstName,
      },
      {
        Name: 'custom:lastName',
        Value: values.lastName,
      },
      {
        Name: 'phone_number',
        Value: values.phoneNumber,
      },
      {
        Name: 'custom:address1',
        Value: values.address1,
      },
      {
        Name: 'custom:address2',
        Value: values.address2,
      },
      {
        Name: 'custom:city',
        Value: values.city,
      },
      {
        Name: 'custom:state',
        Value: values.state,
      },
      {
        Name: 'custom:zip',
        Value: values.zip,
      },
    ],
  };

  cognito.updateUserAttributes(params, function (err, data) {
    if (err) {
      return err;
    } else {
      dispatch({ type: 'SET_USER_DATA', payload: data });
      return data;
    }
  });
};

export const useEditUserProfile = ({ authToken }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const { addSuccessMessage, addErrorMessage } = useContext(MessagingContext);

  const execute = async (values = {}) => {
    try {
      setIsLoading(true);
      const resp = await editUserProfile(values, authToken);
      setData(resp);
      dispatch({ type: 'SET_USER_DATA', payload: resp });
      addSuccessMessage(
        'Edit Profile',
        'Your profile was successfully updated.'
      );
      return resp;
    } catch (e) {
      setError(e);
      setIsLoading(false);
      addErrorMessage('Edit Profile', 'Error while updating profile: ', e);
      throw e;
    }
  };

  return {
    isLoading,
    error,
    data,
    execute: useCallback(execute, []), // to avoid infinite calls when inside a `useEffect`
  };
};

export const useRegisterUser = () => {
  const dispatch = useDispatch();
  const [userProfileError, setError] = useState('');
  const [userRegistered, setUserRegistered] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);
  const { addSuccessMessage, addErrorMessage } = useContext(MessagingContext);

  const execute = async (values) => {
    const attributeList = [];
    for (const [key, value] of Object.entries(values)) {
      if (
        typeof value === 'string' &&
        !['password', 'passwordConfirm'].includes(key)
      ) {
        values[key] = value.trim();
      }
    }
    attributeList.push({ Name: 'custom:firstName', Value: values.firstName });
    attributeList.push({ Name: 'custom:lastName', Value: values.lastName });
    attributeList.push({ Name: 'email', Value: values.email });

    setIsLoading(true);
    return new Promise((resolve, reject) => {
      UserPool.signUp(
        values.email,
        values.password,
        attributeList,
        null,
        (err, data) => {
          if (err) {
            console.error(err);
            setUserRegistered(false);
            setError(err);
            addErrorMessage('Registration', 'Error while registering: ', err);
          } else if (data && data.userSub) {
            dispatch({
              type: 'SET_USER_DATA',
              payload: {
                sub: data.userSub,
                emailNotification: values.emailNotification,
                email: values.email,
                password: values.password,
                first_name: values.firstName,
                last_name: values.lastName,
              },
            });
            window.heap.identify(data.userSub);
            setData(data);
            setUserRegistered(true);
            setError('');
          }
          setIsLoading(false);
          resolve({ error: err, data });
        }
      );
    });
  };

  return {
    isLoading,
    userProfileError,
    setError,
    userRegistered,
    data,
    execute: useCallback(execute, []), // to avoid infinite calls when inside a `useEffect`
  };
};

export const useUpdatePassword = () => {
  const { addSuccessMessage, addErrorMessage } = useContext(MessagingContext);
  const { getSession, loginUser } = useAuth();

  const run = async ({ password, newPassword }) => {
    function changePasswordHandler(err, result) {
      try {
        if (err) {
          console.error(err);
          addErrorMessage(
            'Change Password',
            'Your password was successfully updated: ',
            err.message
          );
        } else {
          addSuccessMessage(
            'Change Password',
            'Your password was successfully updated.'
          );
        }
      } catch (error) {
        // TODO: Handle Error
      }
    }

    const { user } = await getSession();
    try {
      await loginUser({
        Username: user.username,
        Password: password,
      });
      user.changePassword(password, newPassword, changePasswordHandler);
    } catch (error) {
      addErrorMessage('Change Password', error.message);
    }
  };

  return {
    run,
  };
};
