import React from 'react';

import InternalButton from '../InternalButton';
import ExternalButton from '../ExternalButton';
export default function PreAuthButtons({ pathname }) {
  const isRegister = pathname.search('subscribe') !== -1;
  const isMobile = window.matchMedia('(max-width: 640px)').matches;

  return (
    <div className='flex space-x-5'>
      {!isRegister && (
        // <InternalButton
        //   linkClassName='bg-purple-light mr-8 px-8 py-2 md:px-19 md:py-4 lg:mr-29'
        //   name={isMobile ? 'Download the App!' : 'Get the App!'}
        //   path='/subscribe/plan-details'
        //   pClassName='text-base'
        // />

        <ExternalButton
          id='download-app-button-header'
          linkClassName='bg-purple-light mt-20 px-30 text-center w-fitContent'
          name={isMobile ? 'Download the App!' : 'Get the App!'}
          path='https://www.encantosworld.com/encantos-app'
          pClassName='font-bold text-16 text-white'
        />
      )}
      <InternalButton
        linkClassName='border-2 border-purple-light border-solid px-8 text-purple-light md:px-19'
        name='Sign In'
        path='/login'
        pClassName='text-base'
      />
    </div>
  );
}
