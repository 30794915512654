import React from 'react';
//import InlineButton from '../../InlineButton';
import {
  formatDiscountPrice,
  displaySubscriptionPrice,
} from '../../../formatters';

export default function CardFront({
  flipCard,
  promo = {},
  front: { footerProps, subTitle, savings },
  price,
  period,
}) {
  const { abovePrice, belowPrice } = footerProps;
  const { discount, promoCanBeApplied, type } = promo;

  const discountPrice = formatDiscountPrice(discount, price, type);
  const displayPrice = displaySubscriptionPrice(price, period);

  return (
    <>
      <p className='mt-10 text-16 font-medium text-center'>{subTitle}</p>
      <hr className='border-gray-light border-1 border-solid my-30 w-full' />
      <p className='font-medium text-14 text-center text-grayText'>
        {abovePrice}
      </p>
      <p
        className={`font-medium mt-11 text-16 text-center capitalize ${
          promoCanBeApplied ? 'line-through' : ''
        }`}
      >
        {displayPrice}
      </p>
      {belowPrice && <p className='pt-5 text-sm'>{belowPrice}</p>}
      {savings && (
        <p className='mt-10 text-16 font-medium text-center text-successText'>
          {savings}
        </p>
      )}
      {promoCanBeApplied && (
        <div>
          <p className='font-medium mt-11 text-16 text-center text-green-600 capitalize'>
            {discountPrice}
          </p>
          <p className='font-medium mt-11 text-12 text-center text-green-600'>
            Promo Code Applied!
          </p>
        </div>
      )}

      {/* <InlineButton
        className='font-black mt-25'
        handleClick={flipCard}
        id='flipCard'
        name='More Info →'
      /> */}
    </>
  );
}
