import React from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { isMobile, isMobileOnly, isTablet } from 'react-device-detect';

import {
  characterDictionary,
  desktop,
  gradientDictionary,
  mobile,
  tablet,
} from './constants';

function BackgroundImage({ loading }) {
  const { pathname } = useLocation();
  const screenType = isMobileOnly ? mobile : isTablet ? tablet : desktop;

  const isGift = pathname.split('/')[1] === 'send-gift';
  const { image: src, style: backgroundStyle } =
    gradientDictionary[screenType][pathname] ||
    gradientDictionary[screenType]['default'];
  const CharacterComponent = !isMobile && characterDictionary[pathname];

  return (
    <>
      {CharacterComponent && !loading ? <CharacterComponent /> : <div></div>}
      {!isGift && (
        <img
          className={`absolute bottom-0 w-full -z-100 ${backgroundStyle}`}
          src={src}
        />
      )}
    </>
  );
}

function mapStateToProps({ user }) {
  const { loading } = user;
  return { loading };
}

export default connect(mapStateToProps, null)(BackgroundImage);
