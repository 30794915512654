import React, { useState } from 'react';
import Plus from '../../assets/miscIcons/Plus.svg';
import ModalBase from '../modals/ModalBase';
import ChildModal from './ChildModal';

function AddChildCard() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  function handleAddChildButton() {
    setIsModalOpen(true);
  }

  function handleModalClose() {
    setIsModalOpen(false);
  }

  return (
    <>
      <div className='bg-white h-auto w-auto max-h-264 max-w-264 rounded-xlarge flex flex-wrap content-center shadow-encantosShadow sm:max-w-264'>
        <div className='flex flex-wrap content-center m-auto h-264 sm:pt-1 max-h-264'>
          <button
            className='w-150 h-150 hover:bg-gray-300 rounded-full'
            onClick={handleAddChildButton}
          >
            <img className='h-135 w-135 m-auto ' src={Plus} />
          </button>
        </div>
      </div>
      <ModalBase
        isOpen={isModalOpen}
        handleClose={handleModalClose}
        containerClassName='w-full h-570 flex flex-wrap content-center justify-center sm:h-450 w-2/3  md:w-800 bg-white rounded-3xl p-10 relative'
      >
        <ChildModal handleClose={handleModalClose}></ChildModal>
      </ModalBase>
    </>
  );
}

export default AddChildCard;
