import { isMobileOnly, isTablet } from 'react-device-detect';

import ForgotPw from '../../assets/backgrounds/gradients/desktop/forgot_pw.png';
import ForgotPwMobile from '../../assets/backgrounds/gradients/mobile/forgot_pw.png';
import ForgotPwTablet from '../../assets/backgrounds/gradients/tablet/forgot_pw.png';
import LoginBg from '../../assets/backgrounds/gradients/desktop/login.png';
import LoginBgMobile from '../../assets/backgrounds/gradients/mobile/login.png';
import LoginBgTablet from '../../assets/backgrounds/gradients/tablet/login.png';
import DefaultBg from '../../assets/backgrounds/gradients/desktop/default.png';
import DefaultBgMobile from '../../assets/backgrounds/gradients/mobile/default.png';
import DefaultBgTablet from '../../assets/backgrounds/gradients/tablet/default.png';
import LoginChars from './DesktopChars/LoginChars';
import ForgotPwChars from './DesktopChars/ForgotPwChars';
import PlanDetailsChars from './DesktopChars/PlanDetailsChars';
import CreateAcctChars from './DesktopChars/CreateAcctChars';
import PaymentInfoChars from './DesktopChars/PaymentInfoChars';

export const desktop = 'desktop';
export const mobile = 'mobile';
export const tablet = 'tablet';

export const gradientDictionary = {
  desktop: {
    '/login': {
      image: LoginBg,
      style: 'h-967',
    },
    '/forgot-password': {
      image: ForgotPw,
      style: 'h-967',
    },
    'default': {
      image: DefaultBg,
      style: 'h-637'
    },
  },
  mobile: {
    '/forgot-password': {
      image: ForgotPwMobile,
      style: 'h-1575',
    },
    '/login': {
      image: LoginBgMobile,
      style: 'h-1587',
    },
    'default': {
      image: DefaultBgMobile,
      style: 'h-863',
    }
  },
  tablet: {
    '/login': {
      image: LoginBgTablet,
      style: 'h-1303',
    },
    '/forgot-password': {
      image: ForgotPwTablet,
      style: 'h-1303',
    },
    'default': {
      image: DefaultBgTablet,
      style: 'h-682',
    }
  },
};

export const characterDictionary = {
  '/forgot-password': ForgotPwChars,
  '/login': LoginChars,
  '/subscribe/plan-details': PlanDetailsChars,
  '/subscribe/create-account': CreateAcctChars,
  '/subscribe/checkout': PaymentInfoChars,
};

export function screenSpecificStyling(mobileStyling, tabletStyling, desktopStyling) {
  return isMobileOnly ? mobileStyling : isTablet ? tabletStyling : desktopStyling;
}
