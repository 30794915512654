import React from 'react';
import { connect } from 'react-redux';

import ReferAFriendView from './ReferAFriendView';
import AsyncRender from '../../components/AsyncRender';
import PPHeader from '../../components/PPHeader';
import {
  handleInputChange,
  referAFriend,
  resetErrorMessage,
  resetPromoSent,
  setFormError,
} from '../../store/redux/actions';

function ReferAFriend({
  changeInput,
  loading,
  referral,
  resetError,
  resetPromo,
  ...restProps
}) {
  const title = 'Refer a Friend';
  const description =
    "Know someone whose kids would love the storyverse in the Encantos app? We'd love to have them as part of the familia.";

  const handleChange = (event) => {
    if (referral.error) resetError();
    if (referral.promoSent) resetPromo();
    const value = event.target.value;
    const name = event.target.name;
    changeInput({ name, value });
  };

  return (
    <div className='flex flex-col'>
      <PPHeader description={description} title={title} />
      <AsyncRender
        loading={loading}
        Component={ReferAFriendView}
        props={{ handleChange, referral, ...restProps }}
      />
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    changeInput: (payload) =>
      dispatch(handleInputChange({ form: 'referral', ...payload })),
    handleReferAFriend: (payload) => dispatch(referAFriend(payload)),
    resetError: () => dispatch(resetErrorMessage({ form: 'referral' })),
    resetPromo: () => dispatch(resetPromoSent()),
    setError: (payload) =>
      dispatch(setFormError({ form: 'referral', error: payload })),
  };
}

function mapStateToProps({ user }) {
  const { loading, referral, subscription } = user;
  const isActiveSubscription = subscription.isActive;

  return { isActiveSubscription, loading, referral };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReferAFriend);
