import React from 'react';
import AddChildCard from './AddChildCard';
import ChildCard from './ChildCard';

function ChildCardList({ childDataList }) {
  const CanAddChildren = () =>
    childDataList.length < 5 ? <AddChildCard /> : null;
  const filteredData = childDataList.filter((child) => child.active === true);

  if (childDataList) {
    return (
      <div className='grid grid-cols-1 gap-y-20 px-15 max-w-264 mb-25 sm:grid-cols-2 gap-x-25 gap-y-26 md:grid-cols-2 lg:grid-cols-3 gap-x-10'>
        {filteredData.map((child, index) => (
          <ChildCard
            key={index}
            avatar={child.avatar}
            childBirthday={`${child.birth_month}/${child.birth_year}`}
            first_name={child.first_name}
            id={child.id}
          />
        ))}
        <CanAddChildren />
      </div>
    );
  }
}
function index({ childDataList }) {
  return (
    <>
      <ChildCardList childDataList={childDataList} />
    </>
  );
}

export default index;
