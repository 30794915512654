const baseURL = 'https://encantosworld.com';
export const SideNavLinks = [
  {
    href: `${baseURL}/privacy-policy`,
    isInternal: false,
    title: 'Privacy Policy',
  },
  {
    href: `${baseURL}/terms-of-use`,
    isInternal: false,
    title: 'Terms and Conditions',
  },
];

export const SideNavData = [
  {
    title: 'Child Profile',
    path: '/parent-portal/child-profile',
  },
  {
    title: 'Parent Account',
    path: '/parent-portal/parent-account',
  },
  {
    title: 'Manage Subscription',
    path: '/parent-portal/manage-subscription',
  },
  // {
  //   title: 'Refer a Friend',
  //   path: '/parent-portal/refer-a-friend',
  // },
];
