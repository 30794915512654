import CreateAccount from './views/CreateAccount';
import PaymentInfo from './views/PaymentInfo';
import PlanDetails from './views/PlanDetails';
import Success from './views/Success';
import EncantosLogoMonthly from '../../assets/encantos_logo_blue.svg';
import EncantosLogoYearly from '../../assets/encantos_logo_pink.svg';
import EncantosLogoExtra from '../../assets/encantos_logo_yellow.png';

export const viewDictionary = {
  'create-account': {
    Component: CreateAccount,
    step: 1,
    title: 'Create Your Account',
  },
  'plan-details': {
    Component: PlanDetails,
    step: 2,
    title: 'Choose Your Plan',
  },
  checkout: { Component: PaymentInfo, step: 3, title: 'Payment Info' },
  success: { Component: Success, step: 4 },
};

export const parentAccount = {
  title: `Are you already subscribed?`,
  headerTitle: 'Parent Details',
  description:
    'Need to make changes to the info for the adult associated with this account? No worries! Use the form below.',
  preferencesTitle: 'Let Us know Your Preferences',
  preferencesDesc: `So we're communicating in the way you like best`,
  emails: 'Emails',
  sms: 'SMS Messages',
};

export const createAccount = {
  title: `Already subscribed?`,
  termsOfService: `I have read and agree to the <u> <a href='https://encantosworld.com/terms-of-use'>Terms of Service</a> </u> and
                <u> <a href='https://encantosworld.com/privacy-policy'>Privacy Policy. </a> </u>`,
  registrationSuccess:
    'Registration successful. Please check your email to proceed.',
  buttonTitle: 'Create Account',
  signIn: 'Sign In',
};

export const logoDictionary = {
  'encantos_logo_blue.svg': EncantosLogoMonthly,
  'encantos_logo_pink.svg': EncantosLogoYearly,
  'encantos_logo_yellow.svg': EncantosLogoExtra,
};
