import React from 'react';
import { Link } from 'react-router-dom';

export default function StepComplete({ route, step }) {
  return (
    <Link
      className={`flex h-30 items-center justify-center bg-purple-light md:mr-12 rounded-full text-white text-18 w-30 ${
        step === 2 ? 'pointer-events-none' : ''
      }`}
      to={route}
    >
      <svg
        className='h-20 w-20'
        fill='none'
        stroke='currentColor'
        viewBox='0 0 24 24'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth={2}
          d='M5 13l4 4L19 7'
        />
      </svg>
    </Link>
  );
}
