import React from 'react';

import SubscriptionForm from './SubscriptionForm';

export default function EmailSubscribe() {
  return (
    <div className='flex flex-wrap items-center mb-12 lg: mb-0'>
      <p className='text-base text-white md:text-xl mb-4 md:mb-0 mr-20 md:mr-40'>
        Stay in touch
      </p>
      <SubscriptionForm />
    </div>
  );
}
