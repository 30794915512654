import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import {
  clearPromoCode,
  handleInputChange,
  resetPromoCodeErrors,
  resetPromoValidation,
  setPromoCode,
  validatePromoCode,
  setPriceId,
} from '../../../store/redux/actions';
import CTA from '../../CTA';
import FormInput from '../../FormInput';
import ModalBase from '../../modals/ModalBase';

const debounce = (func, timeout = 500) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
};

const verifyPromo = debounce((func, payload) => func(payload));

const ReferredPromoCode = ({
  changeInput,
  validateCode,
  promo,
  promoProductId,
  promoValidated,
  promo_code_error,
  resetPromoError,
  resetValidPromo,
  setPromo,
  clearPromo,
  setSubscriptionId,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const code = useLocation().search;
  const urlSearchParams = new URLSearchParams(code);
  const promo_query = urlSearchParams.get('promo');
  const history = useHistory();
  useEffect(() => {
    const isEmpty = promo_query === '';
    if (promo_query || isEmpty) {
      verifyPromo(validateCode, { promo_code: promo_query });
    }
    if (promo_code_error) {
      resetPromoError();
    }
  }, [promo_query]);

  useEffect(() => {
    if (promo_query && promoProductId) {
      history.replace('/subscribe/create-account');
    }
  }, [promoProductId]);

  const handleReferredPromoCode = () => {
    setIsModalOpen(true);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubscriptionId(promoProductId);
    setIsModalOpen(false);
    if (promoProductId) {
      history.replace('/subscribe/create-account');
    }
  };

  const handleClear = (e) => {
    e.preventDefault();
    clearPromo();
    setPromo('');
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleChange = (event) => {
    if (promoValidated) resetValidPromo();
    if (promo_code_error) resetPromoError();
    const value = event.target.value;
    const name = event.target.name;
    changeInput({ name, value });
    verifyPromo(validateCode, { loading: false, promo_code: value });
  };
  const disabled = !promoValidated && promo_code_error;
  const buttonStyles = disabled
    ? 'border-solid text-purple-light border-2 cursor-auto'
    : 'text-white bg-purple-light epp-hover';

  const ReferredPromoButton = () => {
    return (
      <button
        className={`sm:inline-block w-full h-40 md:h-56  text-16 font-bold rounded-large items-center ${buttonStyles}`}
        disabled={promoValidated}
        onClick={handleReferredPromoCode}
      >
        {promoValidated ? (
          <div>Your Promo Code Has Been Applied!</div>
        ) : (
          <div>
            <p>
              Have a promo code?
              <span className='pl-10 hidden md:inline'>Click Here!</span>
            </p>
          </div>
        )}
      </button>
    );
  };

  return (
    <div className='w-11/12 lg:w-4/5 mb-20'>
      <ReferredPromoButton />
      <ModalBase
        isOpen={isModalOpen}
        handleClose={handleModalClose}
        containerClassName='w-full h-570 flex flex-wrap content-center justify-center sm:h-450 w-2/3  md:w-800 bg-white rounded-3xl p-10 relative'
      >
        <div>
          <div className='flex flex-row mb-10 mt-10'>
            <p className='w-full mb-10 text-32 font-extrabold flex justify-center text-purple-500'>
              Enter your promo code to save!
            </p>
          </div>
          <div className='flex flex-col sm:flex-row w-full'>
            <div className='w-full'>
              <form
                className='px-15 flex flex-col items-center mt-30 w-full'
                onSubmit={handleSubmit}
              >
                <FormInput
                  className={`bg-transparent border w-10/12 h-56 pl-20 text-16 sm:w-360 rounded-xl`}
                  name={'promo'}
                  placeholder={'Enter promo code'}
                  type={'text'}
                  value={promo}
                  handleChange={handleChange}
                  required={true}
                />
                {promo_code_error && (
                  <p className='text-red-600'>{promo_code_error}</p>
                )}
                {promoValidated && (
                  <p className='text-green-600'>Click Apply to continue</p>
                )}

                <div className='w-9/12 mt-10 flex items-center justify-center flex-row space-x-25 sm:w-full justify-start md:mt-60'>
                  <button
                    className='h-48 w-138 px-8 py-2 border-2 text-16 border-solid text-purple-light rounded-xl sm:w-130 hover:underline'
                    onClick={handleClear}
                  >
                    Clear
                  </button>
                  <CTA
                    buttonClassName={`${
                      !promoValidated ? 'bg-gray-300' : 'bg-purple-light'
                    } h-56 rounded-2xl self-end text-white w-145`}
                    name='Apply'
                    textClassName='text-16 font-extrabold text-white'
                    type='submit'
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </ModalBase>
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    changeInput: (payload) =>
      dispatch(handleInputChange({ form: 'checkout', ...payload })),
    validateCode: (payload) => dispatch(validatePromoCode(payload)),
    resetPromoError: () => dispatch(resetPromoCodeErrors()),
    resetValidPromo: () => dispatch(resetPromoValidation()),
    setPromo: (payload) => dispatch(setPromoCode(payload)),
    clearPromo: () => dispatch(clearPromoCode()),
    setSubscriptionId: (priceId) => dispatch(setPriceId(priceId)),
  };
}

function mapStateToProps({ user }) {
  const {
    checkout: {
      promo,
      product_id: promoProductId,
      error: promo_code_error,
      promoValidated,
    },
    loading,
  } = user;

  return {
    promo,
    promo_code_error,
    promoValidated,
    loading,
    promoProductId,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReferredPromoCode);
