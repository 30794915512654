import React, { useState } from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';

import DownloadModal from './modals/DownloadModal';
import { requestGTMEvent } from '../store/redux/actions';

function DownloadButton({ children, containerClassName, id, requestGTM, ...restProps }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const defaultContainerClassNames = 'flex cursor-pointer h-50 hover:underline text-white items-center justify-center';
  const containerClasses = clsx(containerClassName, defaultContainerClassNames);

  function handleClick(event) {
    event.preventDefault();
    setIsModalOpen(true);
    requestGTM({
      type: 'event',
      event: {
        category: 'download',
        action: 'click',
        label: 'location',
        value: id || 'download button',
      },
    });
  }

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div className={containerClasses} onClick={handleClick} {...restProps}>
        {children}
      </div>
      <DownloadModal handleClose={handleModalClose} isOpen={isModalOpen} />
    </>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    requestGTM: (payload) => dispatch(requestGTMEvent(payload)),
  };
}

export default connect(null, mapDispatchToProps)(DownloadButton);

