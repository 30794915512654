import { useState } from 'react';

function getInputValue(target, type) {
  switch (type) {
    case 'checkbox':
      return target.checked;
    default:
      return target.value;
  }
}

export default function useForm({ initialValues }) {
  const [values, setValues] = useState(initialValues);

  const handleChange = (event) => {
    const target = event.target;
    const type = event.target.type;
    const value = getInputValue(target, type);
    const name = event.target.name;

    setValues({
      ...values,
      [name]: value,
    });
  };

  const setPhoneNumber = (number) => {
    setValues({
      ...values,
      phone_number: number,
    });
  };

  return {
    handleChange,
    values,
    setValues,
    setPhoneNumber,
  };
}
