import React from 'react';

import SenorTickTockPng from '../../../assets/backgrounds/characters/senor_tick_tock.png';
import BenjiPng from '../../../assets/backgrounds/characters/benji.png';
import TinyTravelersPng from '../../../assets/backgrounds/characters/tiny_travelers.png';
import PinPonPng from '../../../assets/backgrounds/characters/pin_pon.png';
import NickyPng from '../../../assets/backgrounds/characters/nicky.png';
import SammyPng from '../../../assets/backgrounds/characters/sammy.png';

export default function ForgotPwChars() {
  return (
    <>
      <img
        className='absolute hidden h-133 w-133 md:block'
        src={BenjiPng}
        style={{
          left: 'calc(50vw - 650px)',
          top: '17%',
        }}
      />
      <img
        className='absolute hidden h-110 w-110 md:block'
        src={PinPonPng}
        style={{
          left: 'calc(50vw - 450px)',
          top: '30%',
        }}
      />
      <img
        className='absolute hidden h-146 w-146 md:block'
        src={SammyPng}
        style={{
          left: 'calc(50vw - 675px)',
          top: '38%',
        }}
      />
      <img
        className='absolute hidden h-96 w-96 md:block'
        src={NickyPng}
        style={{
          right: 'calc(50vw - 430px)',
          top: '12%',
        }}
      />
      <img
        className='absolute hidden h-118 w-118 md:block'
        src={SenorTickTockPng}
        style={{
          right: 'calc(50vw - 780px)',
          top: '19%',
        }}
      />
      <img
        className='absolute hidden md:block h-146 w-146'
        src={TinyTravelersPng}
        style={{
          top: '27%',
          right: 'calc(50vw - 550px)',
        }}
      />
    </>
  );
}
