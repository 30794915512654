import React from 'react';
import clsx from 'clsx';

export default function ProfileInitials({
  initials,
  className,
  initialsClassName,
}) {
  const defaultClassNames = `flex items-center justify-center bg-purple-light h-40 rounded-full text-white text-18 w-40 uppercase
  }`;
  const classes = clsx(className, defaultClassNames);
  return (
    <div className={classes}>
      <p className={initialsClassName}>{initials}</p>
    </div>
  );
}
