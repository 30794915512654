import React from 'react';

import SenorTickTockPng from '../../../assets/backgrounds/characters/senor_tick_tock.png';
import BenjiPng from '../../../assets/backgrounds/characters/benji.png';
import TinyTravelersPng from '../../../assets/backgrounds/characters/tiny_travelers.png';
import SkeletinaPng from '../../../assets/backgrounds/characters/skeletina.png';
import KikiPng from '../../../assets/backgrounds/characters/kiki.png';
import PinPonPng from '../../../assets/backgrounds/characters/pin_pon.png';

export default function ChangePwChars() {
  return (
    <div className='flex justify-between w-full'>
      <img
        className='h-110 w-110 mt-65 -ml-15'
        src={SkeletinaPng}
      />
      <img
        className='h-80 w-80 mt-217'
        src={TinyTravelersPng}
      />
      <img
        className='h-88 w-88 mt-88'
        src={KikiPng}
      /> 
      <img
        className='h-92 w-92 mt-192'
        src={SenorTickTockPng}
      />
      <img
        className='h-136 w-136 mt-49'
        src={BenjiPng}
      /> 
      <img
        className='h-67 w-67 mb-40 mt-225 -mr-15'
        src={PinPonPng}
      />
    </div>
  );
}
