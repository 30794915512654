import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

function SideNavMobile({ isSubscriptionActive, sideNavData }) {
  const history = useHistory();
  const location = useLocation();

  const OptionsList = () => {
    return sideNavData.map((option, index) => {
      if (
        !isSubscriptionActive &&
        option.path === '/parent-portal/refer-a-friend'
      )
        return;
      return (
        <option key={index} value={option.path}>
          {option.title}
        </option>
      );
    });
  };
  const handleChange = (event) => {
    history.push(event.target.value);
  };

  return (
    <div className='w-full mt-15 flex justify-center '>
      <select
        value={location.pathname}
        style={{ outline: 'none', width: '92vw' }}
        className='mx-auto px-10 pt-10 pb-10 mt-4  text-lg font-black focus:outline-none border border-black rounded-md'
        onChange={handleChange}
      >
        <OptionsList options={sideNavData} />
      </select>
    </div>
  );
}

export default SideNavMobile;
