import React from 'react';
import edit from '../../assets/miscIcons/edit.svg';
import birthday from '../../assets/miscIcons/birthday.svg';
import ModalBase from '../modals/ModalBase';
import { useState } from 'react';
import ChildModal from './ChildModal';

function ChildCard({ avatar, first_name, childBirthday, id }) {
  const environment = process.env.REACT_APP_STAGE;
  const hasPngSuffix = avatar.split('.png').length > 1;
  const avatarUrl =
    environment === 'prod'
      ? `https://parents-content.encantosworld.com/avatars/${avatar}${!hasPngSuffix ? '.png' : ''}`
      : `https://parents-content.${environment}.encantosworld.com/avatars/${avatar}`;
  const [isModalOpen, setIsModalOpen] = useState(false);

  function handleEditButton() {
    setIsModalOpen(true);
  }

  function handleModalClose() {
    setIsModalOpen(false);
  }

  return (
    <div className='bg-white max-h-264 max-w-264 rounded-xlarge overflow-hidden shadow-encantosShadow sm:flex max-w-264'>
      <ModalBase
        isOpen={isModalOpen}
        handleClose={handleModalClose}
        containerClassName='w-full h-570 flex flex-wrap content-center justify-center sm:h-450 w-2/3  md:w-800 bg-white rounded-3xl p-10 relative'
      >
        <ChildModal
          handleClose={handleModalClose}
          id={id}
          avatar={avatarUrl}
        ></ChildModal>
      </ModalBase>
      <div className='display-block m-auto h-264 sm:pt-1 max-h-200 mt-50'>
        <div className='-mt-20 mr-75 w-max float-right sm:-mr-35'>
          <button
            className='flex items-center justify-center hover:bg-gray-300 rounded-full h-40 w-40'
            onClick={handleEditButton}
          >
            <img className='h-17 w-17 mb-5 ' src={edit}></img>
          </button>
        </div>

        <div className='display-block m-auto w-max'>
          <img
            className='h-106 w-106 sm:max-h-105 max-w-106'
            src={avatarUrl}
          ></img>
        </div>

        <div className='mt-15'>
          <p className='flex justify-center text-16'>{first_name}</p>
        </div>
        <div className='space-x-2 flex justify-center'>
          <img className='mt-2 mr-5' src={birthday}></img>
          <p className='h15 text-16 text-gray-900'>{childBirthday}</p>
        </div>
      </div>
    </div>
  );
}

export default ChildCard;
