import React from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { requestGTMEvent } from '../store/redux/actions';

function InternalButton({
  linkClassName,
  name,
  path,
  pClassName,
  id = '',
  requestGTM,
  priceId = '',
}) {
  const defaultLinkClassNames = 'epp-hover rounded-large text-white';
  const defaultPClassNames =
    'cursor-pointer flex font-black h-50 items-center w-max md:text-lg';

  const linkClasses = clsx(linkClassName, defaultLinkClassNames);
  const pClasses = clsx(pClassName, defaultPClassNames);

  function handleClick(event) {
    if (priceId) {
      requestGTM({
        type: 'event',
        event: {
          category: 'subscribe',
          action: 'select',
          label: 'priceId',
          value: priceId,
        },
      });
    }
    requestGTM({
      type: 'event',
      event: {
        category: 'subscribe',
        action: 'click',
        label: 'location',
        value: id || 'internal button click',
      },
    });
  }

  return (
    <Link className={linkClasses} to={path} onClick={handleClick}>
      <p id={id} className={pClasses}>
        {name}
      </p>
    </Link>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    requestGTM: (payload) => dispatch(requestGTMEvent(payload)),
  };
}

export default connect(null, mapDispatchToProps)(InternalButton);
