import React from 'react';

import CopyrightDetails from './CopyrightDetails';
import EmailSubscribe from './EmailSubscribe';
import InlineButton from '../InlineButton';
import LinkContainer from './LinkContainer';
import Logo from '../../assets/encantos_wordmark_white.png';
import SocialSection from './SocialSection';
import { LINK_MAP } from './constants';

export default function Footer() {
  const scrollToTop = () => {
    window.scrollTo({
      behavior: 'smooth',
      top: 0,
    });
  };

  return (
    <footer className='w-full px-15 md:px-80 pt-60 pb-80 relative'>
      <div className='mx-auto max-w-1200'>
        <div className='flex justify-between mb-25'>
          <img
            className='h-auto max-h-32 max-w-124'
            src={Logo}
            alt='encantos logo'
          />
          <InlineButton
            className='text-white'
            handleClick={scrollToTop}
            name='Scroll to Top ↑'
          />
        </div>
        <div className='flex flex-wrap items-center justify-between mb-50 text-white'>
          <SocialSection />
          <EmailSubscribe />
        </div>
        <div className='flex flex-wrap justify-between mb-50'>
          {LINK_MAP.map((links, idx) => (
            <LinkContainer key={`links-${idx}`} links={links} />
          ))}
        </div>
        <CopyrightDetails />
      </div>
    </footer>
  );
}
