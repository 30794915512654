import React from 'react';

import SkeletinaPng from '../../../assets/backgrounds/characters/skeletina.png';
import NickyPng from '../../../assets/backgrounds/characters/nicky.png';
import PinPonPng from '../../../assets/backgrounds/characters/pin_pon.png';

export default function ChangePwChars() {
  return (
    <div className='flex flex-col justify-between pb-24 w-full'>
      <img
        className='h-155 w-155 ml-96 xs:ml-146 mt-56'
        src={SkeletinaPng}
      />
      <img
        className='h-86 w-86 ml-35 xs:ml-54 mt-46'
        src={NickyPng}
        /> 
      <img
        className='h-98 w-98 ml-160 xs:ml-217 mt-2'
        src={PinPonPng}
      />
    </div>
  );
}
