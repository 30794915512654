import React from 'react';
import Loading from './Loading';

export default function AsyncRender({ Component, loading, props }) {
  return (
    <>
      {loading ?
        <Loading />
        :
        <Component {...props} />
      }
    </>
  );
}
