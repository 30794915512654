import React from 'react';
import { useHistory } from 'react-router-dom';
import { isAndroid, isIOS } from 'react-device-detect';

import EncantosIcon from '../../assets/encantos_icon_purple.png';
import InlineButton from '../InlineButton';
import InternalButton from '../InternalButton';
import ProfileInitials from '../Header/ProfileInitals';
import SocialSection from '../SocialSection';
import PurpleBag from '../../assets/bag.svg';
import ExternalButton from '../ExternalButton';

export default function ExpandedNav({
  authToken,
  handleClick,
  logoutUser,
  parent,
}) {
  const isMobile = isAndroid || isIOS;
  const history = useHistory();
  const firstName = parent.first_name || '';
  const lastName = parent.last_name || '';
  const initials = `${firstName.charAt(0)}${lastName.charAt(0) || ''}`;

  const ParentPortalButton = (
    <InternalButton
      linkClassName='border-2 border-purple-light border-solid flex items-center justify-center mt-22 w-11/12'
      name="Parent's Portal"
      path='/parent-portal'
      pClassName='font-bold items-center text-purple-light text-16'
    />
  );

  const SubscribeButton = (
    // <InternalButton
    //   linkClassName='bg-purple-light flex items-center justify-center mt-22 w-11/12'
    //   name='Download the App!'
    //   path='/subscribe/plan-details'
    //   pClassName='font-bold items-center text-white text-16'
    // />

    <ExternalButton
      id='download-app-button-header'
      linkClassName='flex items-center justify-center bg-purple-light mt-20 px-30 text-center w-11/12'
      name={isMobile ? 'Download the App!' : 'Get the App!'}
      path='https://www.encantosworld.com/encantos-app'
      pClassName='font-bold text-16 text-white'
    />
  );

  const SignInButton = (
    <InternalButton
      linkClassName='border-2 border-purple-light border-solid bg-white text-purple-light mt-45 px-30 w-fitContent'
      name='Sign In'
      path='/login'
      pClassName='font-bold text-16 text-purple-light'
    />
  );

  return (
    <div className='fixed top-0 left-0 bg-white w-full h-full pt-20 px-20 z-20'>
      <div className='flex items-center justify-between'>
        <a href='/'>
          <img alt='encantos logo' className='w-40' src={EncantosIcon} />
        </a>
        <div className='flex items-center justify-center'>
          <a
            className='mr-35'
            href='https://encantosworld.com/'
            rel='noreferrer'
            target='_blank'
          >
            <img src={PurpleBag} />
          </a>
          <button
            className='h-34 w-34 text-3xl text-purple-close font-extralight'
            onClick={handleClick}
          >
            X
          </button>
        </div>
      </div>
      <div className={`${authToken ? 'mt-20' : 'mt-44'} flex flex-col h-full`}>
        {authToken && (
          <div className='flex items-center mb-20'>
            {initials && <ProfileInitials initials={initials} />}
            <p className='font-bold ml-12'>{firstName}</p>
          </div>
        )}
        <a
          className='hover:underline py-10 my-5 border-b'
          href='https://encantosworld.com/storyteaching'
        >
          <p className='font-bold text-24 text-darkBlue'>Storyteaching</p>
        </a>
        <a
          className='hover:underline py-10 my-5 border-b'
          href='https://storyworld.encantosworld.com/'
        >
          <p className='font-bold text-24 text-darkBlue'>Storyworlds</p>
        </a>
        <a
          className='hover:underline py-10 my-5 border-b'
          href='https://www.encantosworld.com/resources'
        >
          <p className='font-bold text-24 text-darkBlue'>Resources</p>
        </a>
        <a
          className='hover:underline py-10 my-5 border-b'
          href='https://encantosworld.com/about'
        >
          <p className='font-bold text-24 text-darkBlue'>About</p>
        </a>
        <a
          className='hover:underline py-10 my-5'
          href='https://shop.encantosworld.com/'
        >
          <p className='font-bold text-24 text-darkBlue'>Shop</p>
        </a>
        {authToken && (
          <span className='mt-20'>
            <a
              className='mt-20 w-max'
              href='https://encantos.helpshift.com/hc/en/'
            >
              <InlineButton className='h-24 pb-8' name='Help' />
            </a>
            <InlineButton
              className='h-24'
              name={'Log Out'}
              handleClick={() => logoutUser({ history })}
            />
          </span>
        )}
        {!authToken && SignInButton}
        {/* <ExternalButton
          id='download-app-button-header'
          linkClassName='bg-purple-light mt-20 px-30 text-center w-fitContent'
          name='Download the App'
          path={`${
            isMobile
              ? 'https://apps.apple.com/us/app/encantos-play-learn-grow/id1565254285'
              : 'https://qrco.de/bcKbmY'
          }`}
          pClassName='font-bold text-16 text-white'
        /> */}
        <div className='absolute bottom-0 left-0 w-full flex flex-col items-center justify-center mb-23'>
          <SocialSection type='header' />
          {authToken ? ParentPortalButton : SubscribeButton}
        </div>
      </div>
    </div>
  );
}
