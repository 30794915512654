import React from 'react';
import { useHistory, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

import ProfileInitials from '../Header/ProfileInitals';

function SideNavDesktop({
  isSubscriptionActive,
  sideNavData,
  sideNavLinks,
  userName,
  email,
  initials,
}) {
  let history = useHistory();

  const handleClick = (event) => {
    history.push(event.target.value);
  };

  const NavItem = ({ sideNavData }) => {
    const location = useLocation();

    return (
      <ul className='bg-white list-none mt-30'>
        {sideNavData.map((item, index) => {
          let itemClasses =
            'py-12 px-25 block cursor-pointer hover:bg-lightGray hover:text-white';
          if (item.path === location.pathname) {
            itemClasses += ' bg-purple-dark text-white';
          }
          if (
            !isSubscriptionActive &&
            item.path === '/parent-portal/refer-a-friend'
          )
            return;
          return (
            <li key={index} onChange={handleClick}>
              <Link to={item.path} className={itemClasses}>
                {item.title}
              </Link>
            </li>
          );
        })}
      </ul>
    );
  };

  const NavHelpfulLinks = ({ sideNavLinks }) => {
    return (
      <ul className='hidden sm:inline-block list-none mt-33'>
        {sideNavLinks.map(({ href, title }, index) => {
          const itemClasses = 'text-grayText cursor-pointer hover:text-black';
          return (
            <li key={index} className='mt-12'>
              <a
                href={href}
                className={itemClasses}
                target='_blank'
                rel='noreferrer'
              >
                {title}
              </a>
            </li>
          );
        })}
      </ul>
    );
  };

  return (
    <div className='sm:min-h-690'>
      <div className='hidden sm:inline-block mx-auto pt-40 mt-40 w-240 rounded-xlarge shadow-encantosShadow overflow-hidden'>
        <ProfileInitials
          className='justify-self-center h-85 w-85 mx-auto'
          initialsClassName='text-3xl uppercase'
          initials={initials}
        ></ProfileInitials>
        <div className='flex-col text-center mt-17'>
          <p className='text-18 font-bold capitalize'>{userName}</p>
          <p className='px-5 break-words text-16 text-grayText'>{email}</p>
        </div>
        <NavItem sideNavData={sideNavData} />
      </div>
      <NavHelpfulLinks sideNavLinks={sideNavLinks} />
    </div>
  );
}

export default SideNavDesktop;
