import React from 'react';
import { isMobileOnly, isTablet } from 'react-device-detect';

import { screenSpecificStyling } from '../components/BackgroundImage/constants';
import Login from '../pages/Login';
import LoginCharsTablet from '../components/BackgroundImage/TabletChars/LoginChars';
import LoginCharsMobile from '../components/BackgroundImage/MobileChars/LoginChars';

export function LoginLayout() {
  const screenStyle = screenSpecificStyling('pt-24', 'pt-40', 'md:pb-260 pt-0 lg:pb-265');

  return (
    <div
      className={`px-15 pb-20 flex flex-col justify-content ${screenStyle}`}
    >
      <Login />
      {isTablet && <LoginCharsTablet /> || isMobileOnly && <LoginCharsMobile />}
    </div>
  );
}
