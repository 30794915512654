/* eslint-disable no-extra-boolean-cast */
import React, { useState } from 'react';
import { BsPlusSquare, BsDashSquare, BsTrash, BsGift } from 'react-icons/bs';
import discountsData from '../../assets/discountsData.json';

const DEFAULT_DISCOUNT = {
  errors: '',
  title: '',
  value: -1,
};

const PurchaseCart = ({ items = [], onItemDelete, onItemAmountChange }) => {
  const getItemsPrice = () => {
    return Number(
      items.reduce((prev, curr) => {
        let sum = Number(prev);
        sum += Number(curr.price) * Number(curr.amount);
        return sum;
      }, 0)
    ).toFixed(2);
  };

  const [discount, setDiscount] = useState(DEFAULT_DISCOUNT);

  const onDiscountChange = (prop, value) => {
    setDiscount((prevDiscount) => ({
      ...prevDiscount,
      errors: null,
      [prop]: value,
    }));
  };

  const onDiscountApply = () => {
    if (!!~discount.value) {
      setDiscount(DEFAULT_DISCOUNT);
      return;
    }

    const foundDiscount = discountsData.find(
      ({ title }) => title === discount.title
    );

    if (!foundDiscount) {
      onDiscountChange('error', 'Invalid discount code applied');
      return;
    }

    onDiscountChange('value', foundDiscount.value);
  };

  const getDiscountedPrice = () => {
    const subtotal = getItemsPrice();

    if (!~discount.value) {
      return subtotal;
    }

    return Number(subtotal - subtotal * (Number(discount.value) / 100)).toFixed(
      2
    );
  };

  return (
    <div className='purchase-list'>
      <h2>Cart</h2>
      <div className='products-lsit small'>
        {items.length > 0 ? (
          items.map((item, idx) => (
            <div className='product-item' key={idx}>
              <div className='product-image'>
                <img src={item.image} alt='Product' />
              </div>
              <div className='product-info'>
                <div className='product-info-header'>
                  <h3>{item.type === 'GIFT' ? 'Gift' : item.title}</h3>
                  {item.type === 'GIFT' && (
                    <span className='gift-tag'>
                      <BsGift />
                    </span>
                  )}
                  <span className='price'>
                    ${Number(item.price).toFixed(2)}
                  </span>
                </div>
                <div className='product-info-options'>
                  {item.type === 'GIFT' && <span>Product: {item.title}</span>}
                  <span>Amount: x{item.amount}</span>
                  <span>Subscription: {item.subscription.label}</span>
                  {item.options &&
                    Object.keys(item.options).map((key) => (
                      <span key={key}>
                        {key}: {item.options[key]}
                      </span>
                    ))}
                </div>
                <div className='product-info-controll mini'>
                  <span
                    className='ctrl default'
                    onClick={() => onItemAmountChange(item, item.amount - 1)}
                  >
                    <BsDashSquare className='icon' />
                  </span>
                  <span className='ctrl default'>
                    <BsPlusSquare
                      className='icon'
                      onClick={() => onItemAmountChange(item, item.amount + 1)}
                    />
                  </span>
                  <span
                    className='ctrl danger'
                    onClick={() => onItemDelete(item)}
                  >
                    <BsTrash className='icon' />
                  </span>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p>Your cart is empty</p>
        )}
      </div>
      {items.length > 0 && (
        <div className='cart-info'>
          <div className='cart-discount'>
            <p className='cart-discount-label'>Discount code</p>
            <div className='cart-discount-input'>
              <input
                className={discount.errors ? 'input input--fail' : 'input'}
                disabled={!!~discount.value}
                value={discount.title}
                onChange={({ target }) =>
                  onDiscountChange('title', target.value)
                }
              />
              <button
                className='btn button'
                onClick={onDiscountApply}
                disabled={discount.title.length < 1}
              >
                {!~discount.value ? 'Apply' : 'Remove'}
              </button>
            </div>
            {discount.errors && (
              <p className='cart-discount-error'>{discount.errors}</p>
            )}
          </div>
          <div className='cart-price'>
            <p>
              <span>Items</span>
              <span>${getItemsPrice()}</span>
            </p>
            <p>
              <span>Taxes</span>
              <span>$0.00</span>
            </p>
            {!!~discount.value && (
              <p>
                <span>Discount</span>
                <span>
                  ${(getItemsPrice() - getDiscountedPrice()).toFixed(2)}
                </span>
              </p>
            )}
            <p>
              <span>Subtotal</span>
              <span>
                ${!!~discount.value ? getDiscountedPrice() : getItemsPrice()}
              </span>
            </p>
            <button className='btn button'>Checkout</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default PurchaseCart;
