import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import axios from 'axios';
import { MessagingProvider } from './contexts/MessagingContext';
import { Provider as ReduxProvider } from 'react-redux';
import store from './store/store';
import { startHelpShift } from './vendors/helpshift';
import { loadHeap } from './vendors/heapSdk';

const stage = process.env.REACT_APP_STAGE || '';
// only start HelpShift in QA/PROD
const shouldStartHelpShift = ['QA', 'PROD'].includes(stage.toUpperCase());

if (shouldStartHelpShift) {
  startHelpShift();
}

loadHeap();

//axios.defaults.baseURL = 'http://127.0.0.1:5000/';
axios.defaults.headers.post['Content-Type'] = 'application/json';

ReactDOM.render(
  <React.StrictMode>
    <ReduxProvider store={store}>
      <MessagingProvider>
        <App />
      </MessagingProvider>
    </ReduxProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
