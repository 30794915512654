import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { requestLogin, resetState } from '../store/redux/actions';
import FormInput from './../components/FormInput';
import CTA from './../components/CTA';
import Prompt from './../components/Prompt';
import Error from './../components/Error';
import useForm from '../hooks/useForm';
import { useHistory } from 'react-router-dom';
import InputWithToggle from '../components/InputWithToggle';

function LoginView({ requestLoginUser, authError }) {
  const history = useHistory();
  const showSpinner = useSelector((state) => state.user.showSpinner);
  const isMobile = window.matchMedia('(max-width: 640px)').matches;
  const { values, handleChange } = useForm({
    initialValues: {
      Password: '',
      Username: '',
    },
  });

  const onSubmit = (event) => {
    event.preventDefault();
    authErrorMessage = '';
    for (const [key, value] of Object.entries(values)) {
      if (
        typeof value === 'string' &&
        !['password', 'passwordConfirm'].includes(key)
      ) {
        values[key] = value.trim();
      }
    }
    requestLoginUser({ ...values, history, historyLocation: '/parent-portal' });
  };

  let authErrorMessage =
    authError && authError.code === 'InvalidParameterException'
      ? 'Please enter a valid email address'
      : authError && authError.message;

  const inputClassNames =
    'h-56 w-full m-w-360 mt-35 mb-15 border pl-20 pr-45 border-light-grey bg-transparent text-16 sm:mb-30';
  return (
    <div className='shadow-encantosShadow max-h-fitcontent max-w-fitcontent m-auto bg-background rounded-xlarge border-white flex justify-center sm:max-w-lg md:max-w-xl px-6 lg:max-w-xl'>
      <div className='px-12 sm:px-35 md:55'>
        <div className=' px-8 text-left'>
          <h1 className='mt-30 mb-19 text-3xl sm:text-3.2xl font-bold '>
            It&apos;s good to see you!
          </h1>
          <p className='text-16 font-medium text-primary mt-11 mb-2'>
            Log into your account below to catch up with your kids. You are not
            subscribed?
            <a
              className='text-16 font-bold epp-hover'
              href='https://www.encantosworld.com/encantos-app'
              target='_blank'
              rel='noreferrer'
            >
              {isMobile ? ' Download the App!' : ' Get the App!'}
            </a>
          </p>
        </div>

        <div className={`text-primary m-6 ${authError ? 'mt-25' : 'mt-35'}`}>
          <form onSubmit={onSubmit}>
            <div className={`${authError ? 'inline-block' : 'hidden'}`}>
              {authError && <Error error={authErrorMessage} />}
            </div>
            <FormInput
              type={'text'}
              placeholder={"Parent's email address"}
              name={'Username'}
              value={values.Username}
              handleChange={handleChange}
              className='h-56 w-full bg-transparent text-16 pl-20'
            />

            <InputWithToggle
              type={'Password'}
              placeholder={'Password'}
              name={'Password'}
              value={values.Password}
              handleChange={handleChange}
              className={inputClassNames}
              containerClassName='relative w-full'
              iconClassName='mt-35 text-2xl text-purple-light'
              iconContainerClassName='top-20 right-20'
            />
            <div>
              <div className='mt-25 mb-30 flex flex-col sm:flex-row justify-end items-center'>
                <div className='mb-32 sm:mb-0'>
                  <Link to='/forgot-password'>
                    <u>
                      <Prompt
                        className='text-16 font-semibold bg-white leading-6 w-full'
                        prompt={'Forgot password?'}
                      />
                    </u>
                  </Link>
                </div>
                <div className='mt-4 ml-25 md:ml-35'>
                  <CTA
                    buttonClassName='flex flex-row justify-center items-center  h-56 w-146 rounded-2xl'
                    textClassName='text-16 w-full font-extrabold w-55 capitalize text-white'
                    name={'log in'}
                    type={'submit'}
                    showSpinner={showSpinner}
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

function Login({ loading, authError, requestLoginUser, requestResetState }) {
  useEffect(() => {
    requestResetState();
  }, []);
  return (
    <LoginView requestLoginUser={requestLoginUser} authError={authError} />
  );
}

function mapDispatchToProps(dispatch) {
  return {
    requestLoginUser: (payload) => dispatch(requestLogin(payload)),
    requestResetState: () => dispatch(resetState()),
  };
}

function mapStateToProps({ user }) {
  const { subscription, userData, authToken, authError, loading } = user;
  return {
    subscription,
    userData,
    authToken,
    authError,
    loading,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
