import React from 'react';
import { isAndroid, isIOS } from 'react-device-detect';
import { useLocation } from 'react-router-dom';

import MobileNav from '../MobileNav';
import Breadcrumbs from '../Breadcrumbs';
import HeaderButtons from './HeaderButtons';
import AmpersandLogo from '../../assets/encantos_icon_purple.png';
import Logo from '../../assets/encantos_wordmark_purple.png';
import { BREADCRUMB_DICTIONARY } from '../Breadcrumbs/constants';

export default function Header({ authToken, logoutUser, parent }) {
  const isMobile = isAndroid || isIOS;
  const location = useLocation();
  const { pathname } = location;
  const isHome = pathname === '/';
  const linkVal = BREADCRUMB_DICTIONARY[pathname];
  const displayHeaderButtons =
    pathname !== '/login' &&
    pathname !== '/forgot-password' &&
    pathname.split('/')[1] !== 'send-gift';

  return (
    <>
      <div className='bg-transparent w-full px-12 mx-auto max-w-1200'>
        <header className='pt-40 md:pb-40'>
          <a href='https://encantosworld.com'>
            <img
              className='hidden md:block md:max-w-185.6 h-auto'
              src={Logo}
              alt='encantos logo'
            />
            <img
              className='max-w-60 md:hidden h-auto'
              src={AmpersandLogo}
              alt='encantos logo'
            />
          </a>
          {isMobile ? (
            <MobileNav
              authToken={authToken}
              logoutUser={logoutUser}
              parent={parent}
            />
          ) : (
            displayHeaderButtons && (
              <HeaderButtons
                pathname={pathname}
                authToken={authToken}
                logoutUser={logoutUser}
                parent={parent}
              />
            )
          )}
        </header>
        {!isHome && linkVal && (
          <Breadcrumbs linkVal={linkVal} pathname={pathname} />
        )}
      </div>
    </>
  );
}
