import React from 'react';
import SideNavMobile from './SideNavMobile';
import SideNavDesktop from './SideNavDesktop';

function index({
  isSubscriptionActive,
  sideNavData,
  sideNavLinks,
  userName,
  email,
  initials,
}) {
  return (
    <>
      <SideNavDesktop
        isSubscriptionActive={isSubscriptionActive}
        sideNavData={sideNavData}
        sideNavLinks={sideNavLinks}
        userName={userName}
        email={email}
        initials={initials}
      />

      <div className='m-w-360 inline-block text-left sm:hidden'>
        <SideNavMobile
          isSubscriptionActive={isSubscriptionActive}
          sideNavData={sideNavData}
        />
      </div>
    </>
  );
}

export default index;
