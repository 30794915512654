import React from 'react';
import { connect } from 'react-redux';

import PlansMap from './PlansMap';
import AsyncRender from '../../components/AsyncRender';
import PPHeader from '../../components/PPHeader';
import {
  requestUpdatePlan,
  resetErrorMessage,
  setErrorMessage,
  setUpdatePlanId,
} from '../../store/redux/actions';

function UpgradePlan({ loading, setPlanId, ...restProps }) {
  return (
    <div className='lg:w-full pb-100 sm:pb-70 lg:pb-0'>
      <PPHeader
        title='Upgrade Plan'
        description='Choose an option below to take your membership to the next level...'
      />
      <AsyncRender
        Component={PlansMap}
        loading={loading}
        props={{ setUpdatePlanId: setPlanId, ...restProps }}
      />
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    resetError: () => dispatch(resetErrorMessage()),
    setError: (payload) => dispatch(setErrorMessage(payload)),
    setPlanId: (payload) => dispatch(setUpdatePlanId(payload)),
    updatePlanRequest: (payload) => dispatch(requestUpdatePlan(payload)),
  };
}

function mapStateToProps({ user }) {
  const {
    activeSubscriptions,
    error,
    loading,
    subscription: {
      details: { name, type },
    },
    updatePlanData,
  } = user;

  return {
    activeSubscriptions,
    currentPlan: name,
    error,
    loading,
    subscriptionType: type,
    updatePlanData,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UpgradePlan);
