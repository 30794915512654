import './App.css';
import React, { useContext, useEffect } from 'react';
import { connect } from 'react-redux';

import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PrivateRoute from './pages/PrivateRoute';
import Landing from './pages/Landing';
import Home from './pages/Home';
import ForgotPw from './pages/ForgotPassword';
import QRCode from './pages/QRCode';
import NotFound from './pages/NotFound';
import Purchase from './pages/Purchase';
import EditProfile from './pages/EditProfile';
import { MessagingContext } from './contexts/MessagingContext';
import TransitionAlert from './components/TransitionAlert';
import WarningDialog from './components/modals/WarningDialog';
import Banner from './components/Banner';
import Header from './components/Header';
import Footer from './components/Footer';
import Subscribe from './pages/Subscribe';
import SendGift from './pages/SendGift';
import { LoginLayout } from './layouts/LoginLayout';
import ParentPortalLayout from './layouts/ParentPortalLayout';
import BackgroundImage from './components/BackgroundImage';
import {
  requestActiveSubscriptions,
  requestSubscriptionDetails,
  requestLogin,
  requestLogout,
  requestUserSession,
} from './store/redux/actions';
import Pool from './UserPool';
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_TRACKING_ID,
};
TagManager.initialize(tagManagerArgs);

const { REACT_APP_STRIPE_PUBLIC_KEY: stripeKey } = process.env;
const stripePromise = loadStripe(stripeKey);

function App({
  requestActiveSubscriptionPlans,
  requestSubscription,
  subscription,
  requestLogoutUser,
  authToken,
  requestSession,
  parent,
}) {
  const { pathname } = window.location;
  const isGift = pathname.split('/')[1] === 'send-gift';
  const { errorMessage, successMessage, warningMessage } =
    useContext(MessagingContext);
  useEffect(() => {
    const currentUser = Pool.getCurrentUser();
    requestActiveSubscriptionPlans();
    if (currentUser) {
      requestSubscription();
    }
  }, []);
  const missingPayment =
    subscription.status === 'incomplete' || subscription.status === 'past_due';

  return (
    <>
      {successMessage && (
        <TransitionAlert
          label={successMessage.label}
          description={successMessage.description}
          show={successMessage.show}
          success={true}
        />
      )}
      {errorMessage && (
        <TransitionAlert
          label={errorMessage.label}
          description={errorMessage.description}
          show={errorMessage.show}
          success={false}
        />
      )}
      <div className='relative flex flex-col min-h-screen min-w-screen w-full'>
        <Elements stripe={stripePromise}>
          <Router>
            <Header
              authToken={authToken}
              logoutUser={requestLogoutUser}
              parent={parent}
            />
            {authToken && missingPayment && (
              <Banner containerClass='mt-10 w-10/12'>
                <svg
                  className='h-25 mr-10 w-25'
                  fill='none'
                  stroke='currentColor'
                  viewBox='0 0 24 24'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth={2}
                    d='M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z'
                  />
                </svg>
                <p className='text-18'>
                  Please update your payment information{' '}
                  <Link
                    className='epp-hover text-white'
                    to='/parent-portal/manage-subscription'
                  >
                    here
                  </Link>
                  .
                </p>
              </Banner>
            )}
            <BackgroundImage />
            <Switch>
              <Route exact path='/' component={Landing} user={authToken} />
              <Route exact path='/subscribe/:view' component={Subscribe} />
              <Route exact path='/login' component={LoginLayout} />
              <Route exact path='/forgot-password' component={ForgotPw} />
              <Route exact path='/send-gift' component={SendGift} />
              <Route exact path='/send-gift/:view' component={SendGift} />
              <PrivateRoute
                path='/home'
                component={Home}
                authToken={authToken}
              />
              <PrivateRoute
                authToken={authToken}
                path='/parent-portal/'
                component={ParentPortalLayout}
                isSubscriptionActive={subscription.isActive}
                parent={parent}
                requestSession={requestSession}
              />
              {/* <PrivateRoute path="/home" component={Home} /> */}
              <PrivateRoute
                path='/editProfile'
                component={EditProfile}
                authToken={authToken}
                requestSession={requestSession}
              />
              <PrivateRoute
                authToken={authToken}
                path='/cart'
                component={Purchase}
                requestSession={requestSession}
              />
              {/* <PrivateRoute path='/resetpassword' component={ResetPassword} /> */}
              <PrivateRoute
                authToken={authToken}
                path='/qrcode'
                component={QRCode}
                requestSession={requestSession}
              />
              <Route component={NotFound} />
            </Switch>
            {!isGift && <Footer />}
          </Router>
        </Elements>
      </div>
      {warningMessage && (
        <WarningDialog
          label={warningMessage.label}
          description={warningMessage.description}
          show={warningMessage.show}
          success={false}
        />
      )}
    </>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    requestActiveSubscriptionPlans: () =>
      dispatch(requestActiveSubscriptions()),
    requestSubscription: () => dispatch(requestSubscriptionDetails()),
    requestLoginUser: (payload) => dispatch(requestLogin(payload)),
    requestLogoutUser: (payload) => dispatch(requestLogout(payload)),
    requestSession: (payload) => dispatch(requestUserSession(payload)),
  };
}

function mapStateToProps({ user }) {
  const { subscription, authToken, error, loading, parent } = user;

  return { subscription, authToken, error, loading, parent };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
