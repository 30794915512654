import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { requestParent } from '../store/redux/actions';
import ChildCardList from '../components/ChildCardList';
import PPHeader from '../components/PPHeader';
import AsyncRender from '../components/AsyncRender';

function ChildProfile({ children, requestParentInfo, parent, loading }) {
  let history = useHistory();
  const preSelectedRoute = '/parent-portal/child-profile';
  const parentFistName = parent.first_name;
  const description = `It's good to see you. Here you'll find everything you need to manage your account, from adding profiles for new kids, to adjusting your payment settings and even referring a friend.`;
  const headerTitle = `Welcome, ${getParentName()}`;

  function getParentName() {
    return !parentFistName ? '' : parentFistName;
  }

  useEffect(() => {
    history.push(preSelectedRoute);
    requestParentInfo();
  }, []);

  return (
    <div className='mx-auto w-full mt-5 sm:mt-60'>
      <PPHeader
        title={parentFistName && headerTitle}
        description={description}
      ></PPHeader>
      <AsyncRender
        loading={loading}
        Component={ChildCardList}
        props={{ childDataList: children }}
      />
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    requestParentInfo: () => dispatch(requestParent()),
  };
}

function mapStateToProps({ user }, ownProps) {
  const { children, parent, loading } = user;
  return {
    children,
    parent,
    loading,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChildProfile);
