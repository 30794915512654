import React from 'react';

import InternalButton from '../../components/InternalButton';
import ModalBase from '../../components/modals/ModalBase';

export default function InactiveModal() {
  return (
    <ModalBase
      isOpen={true}
      containerClassName='w-full flex flex-wrap content-center justify-center sm:w-2/3 md:w-552 bg-white rounded-3xl p-10 relative'
    >
      <div className='flex flex-col items-center justify-center mb-30 mt-40'>
        <p className='leading-normal justify-center text-center text-32 font-black'>Before you can refer a friend...</p>
        <p className='text-16'>You need an active subscription that is not in the trial period.</p>
        <InternalButton
          linkClassName='bg-purple-light px-26 py-2 mt-30 md:px-19 md:py-4'
          name='Back to Child Profile'
          path='/parent-portal/child-profile'
        />
      </div>
    </ModalBase>
  );
}
