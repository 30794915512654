import { CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import Pool from '../../UserPool';
const { REACT_APP_BASE_URL: BASE_URL } = process.env;

const defaultErrorMessages = {
  403: 'Unauthorized',
};

const defaultErrorMessage =
  'Sorry your request could not be completed at this time';

export function requestToken() {
  const user = Pool.getCurrentUser();
  return user.getSession(async (err, session) => {
    if (session) {
      return session.getIdToken().getJwtToken();
    } else {
      return null;
    }
  });
}

export function requestCognitoUser() {
  const user = Pool.getCurrentUser();
  return user.getSession(async (err, session) => {
    if (session) {
      return session.getIdToken().payload;
    } else {
      return null;
    }
  });
}

export function requestCreateSubscription({
  parentId,
  paymentMethod,
  priceId,
  promo,
  refersion,
}) {
  const method = 'POST';
  const resource = 'subscription/create';
  const payload = {
    price_id: priceId,
    payment_method: paymentMethod,
    promo_code: promo,
    parent_id: parentId,
    refersion,
  };
  return makeFetchCall(resource, null, method, { payload });
}

export function requestValidatePromoCode({ payload }) {
  const method = 'POST';
  const resource = 'stripe/promo-code/validate';
  delete payload.loading;
  const _payload = { payload };
  return makeFetchCall(resource, null, method, _payload);
}

export function requestCancelSubscription(authToken) {
  const method = 'DELETE';
  const resource = 'subscription/delete';
  return makeFetchCall(resource, authToken, method);
}

export async function requestSubscriptionDetails(authToken) {
  const method = 'GET';
  const resource = 'subscription-details';
  return makeFetchCall(resource, authToken, method);
}

export async function requestUpdatePaymentMethod({
  token: authToken,
  paymentMethod,
}) {
  const method = 'POST';
  const resource = 'stripe/payment-method/update';
  const payload = { payload: { payment_method: paymentMethod } };
  return makeFetchCall(resource, authToken, method, payload);
}

async function makeFetchCall(resource, token = null, method, payload = null) {
  const url = `${BASE_URL}/${resource}`;
  const headers = {
    Accept: 'application/json',
    Authorization: token,
  };
  const options = {
    headers,
    method: method,
  };
  if (payload) {
    options.body = JSON.stringify(payload);
  }

  const response = await fetch(url, options);
  const data = await processResponse(response);
  if (response.status >= 400) {
    throw data.error
      ? data
      : {
          error: {
            message:
              defaultErrorMessages[response.status] || defaultErrorMessage,
          },
        };
  }
  return data;
}

async function processResponse(response) {
  try {
    const data = await response.json();
    return data;
  } catch (err) {
    return {};
  }
}

export function requestParent(authToken) {
  const method = 'GET';
  const resource = 'account-holder';
  return makeFetchCall(resource, authToken, method);
}

export function requestAddChild({ authToken, payload }) {
  const method = 'POST';
  const resource = 'account-holder/add-child';
  return makeFetchCall(resource, authToken, method, { payload });
}

export function requestUpdateChild({ authToken, Payload }) {
  const payload = Payload.payload;
  const method = 'PUT';
  const resource = `account-holder/edit-child/${Payload.id}`;
  return makeFetchCall(resource, authToken, method, { payload });
}

export function requestDeleteChild({ authToken, payload }) {
  const method = 'DELETE';
  const resource = `account-holder/delete-child/${payload.id}`;
  return makeFetchCall(resource, authToken, method, { payload });
}

export function requestUpdateAccount({ authToken, payload }) {
  const method = 'PUT';
  const resource = 'account-holder/update';
  return makeFetchCall(resource, authToken, method, payload);
}

export function requestReferAFriend({ authToken, payload }) {
  const method = 'POST';
  const resource = 'account-holder/refer-a-friend';
  return makeFetchCall(resource, authToken, method, { payload });
}

export function requestActiveSubscriptions(authToken) {
  const method = 'GET';
  const resource = 'subscription-plans';
  return makeFetchCall(resource, null, method);
}

export function requestValidatePromo({ payload }) {
  const method = 'POST';
  const resource = 'subscription-plans';
  return makeFetchCall(resource, null, method);
}

export function requestUpdatePlan({ authToken, payload }) {
  const { updatePlanId } = payload;
  const body = { price_id: updatePlanId };
  const method = 'PUT';
  const resource = 'update-subscription';
  return makeFetchCall(resource, authToken, method, { payload: body });
}

export function requestLogin({ Username, Password }) {
  return new Promise((resolve, reject) => {
    const user = new CognitoUser({ Pool, Username });
    const authDetails = new AuthenticationDetails({ Password, Username });

    user.authenticateUser(authDetails, {
      onSuccess: (data) => {
        window.heap.identify(data.idToken.payload.sub);
        resolve(data);
      },

      onFailure: (err) => {
        console.error('Error logging user in', err);
        reject(err);
      },
    });
  });
}

export function requestLogout() {
  const user = Pool.getCurrentUser();

  return new Promise((resolve, reject) => {
    if (user) {
      user.signOut();
      resolve({ user });
    } else {
      reject({ err: 'No user available' });
    }
  });
}

export function triggerGTMEvent({ view, type = 'view', event }) {
  if (type == 'view') {
    window.dataLayer.push({
      event: 'pageview',
      page: {
        url: view,
      },
    });
  } else if (type == 'event') {
    window.dataLayer.push({
      event: 'event',
      eventProps: event,
    });
  }
}

export function requestStripePaymentIntent() {
  const method = 'POST';
  const resource = 'create-payment-intent';
  return makeFetchCall(resource, null, method);
}

export function requestGiftPDF({ payload }) {
  const method = 'GET';
  const resource = `gift/pdf?promoCode=${payload.promoCode}`;
  return makeFetchCall(resource, null, method);
}

export function requestGiftPromo({
  authToken,
  gifterEmail,
  gifterName,
  emailNotification,
  giftPurchasePrice,
  recipientEmail,
  recipientName,
}) {
  const requestData = {
    giver: {
      first_name: gifterName,
      email: gifterEmail,
      marketing: !!emailNotification,
    },
    payment_amount: giftPurchasePrice,
    recipient: { email: recipientEmail, first_name: recipientName },
  };

  const method = 'POST';
  const resource = 'gift';
  return makeFetchCall(resource, authToken, method, { payload: requestData });
}
