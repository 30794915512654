import React from 'react';

import useForm from '../../../hooks/useForm';

export default function SubscriptionForm() {
  const { values, handleChange } = useForm({
    initialValues: {
      email: '',
    },
  });

  const onSubmit = (evt) => {
    evt.preventDefault();
    // TODO: setup marketing notifications
  };

  return (
    <form
      className='flex flex-wrap justify-between bg-transparent border-1.5 border-solid border-white min-w-200 lg:w-330 p-6 rounded-lg'
      onSubmit={onSubmit}
    >
      <input
        className='bg-transparent mr-10 min-w-160 lg:mr-0 lg:w-230'
        style={{
          background: 'transparent',
          outline: 'none',
          border: 'none',
        }}
        name='email'
        onChange={handleChange}
        placeholder='Your e-mail address'
        type='email'
        value={values.email}
      />
      <button className='font-black epp-hover text-base'>Subscribe</button>
    </form>
  );
}
