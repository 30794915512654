import React from 'react';
import clsx from 'clsx';

import { FOOTER_DICTIONARY } from './constants';

export default function ContentCard({
  cardFooterProps,
  cardFooterType,
  className,
  children,
  iconProps,
  subTitle,
  subTitleComponent,
  title,
}) {
  const defaultClassNames =
    'min-h-fitcontent py-45 bg-background flex flex-col items-center justify-center px-12 rounded-xlarge border-white sm:px-35 md:px-55 shadow-encantosShadow';
  const CardFooter = FOOTER_DICTIONARY[cardFooterType];
  const classes = clsx(className, defaultClassNames);

  return (
    <div className={classes}>
      {iconProps && <img alt={iconProps.altText} src={iconProps.icon} />}
      <div className='mb-19'>
        {Array.isArray(title) ? (
          title.map((name, idx) => (
            <h1
              key={`${name}-${idx}`}
              className='font-black leading-normal text-center'
            >
              {name}
            </h1>
          ))
        ) : (
          <h1 className='font-black leading-normal text-center'>{title}</h1>
        )}
      </div>
      {(subTitle && (
        <p className='text-16 font-medium text-center'>{subTitle}</p>
      )) ||
        (subTitleComponent && subTitleComponent)}
      {children || null}
      {CardFooter && (
        <hr className='border-gray-300 border-1 border-solid my-30 w-full' />
      )}
      {CardFooter && <CardFooter {...cardFooterProps} />}
    </div>
  );
}
