import React from 'react';
import { Link } from 'react-router-dom';

import InlineButton from '../../InlineButton';

export default function StepLink({ cannotEditStep, isDisabled, route, title }) {
  const stepColor = isDisabled
    ? `pointer-events-none ${
        cannotEditStep ? 'text-purple-light' : 'text-gray-light'
      }`
    : `text-purple-light ${cannotEditStep ? 'pointer-events-none' : ''}`;
  return (
    <Link
      className={`hidden md:flex font-black items-center ${stepColor}`}
      to={route}
    >
      <InlineButton name={title} />
    </Link>
  );
}
