import { useState } from 'react';

export default function useModal(initialValues) {
  const [showModal, openModal] = useState(initialValues);

  const setShowModal = () => {
    openModal((prev) => !prev);
  };

  return {
    setShowModal,
    showModal,
  };
}
