import React, { useState } from 'react';
import { RiEyeCloseLine, RiEyeLine } from 'react-icons/ri';
import clsx from 'clsx';

function InputWithToggle({
  className,
  containerClassName,
  disabled,
  iconClassName,
  handleChange,
  iconContainerClassName,
  placeholder,
  name,
  value,
  required = false,
}) {
  const defaultClassNames =
    'bg-white leading-6 border border-gray-100 rounded-lg w-full';
  const classes = clsx('input', className, defaultClassNames);
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisibility = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  const defaultContainerClassNames = 'flex items-center justify-center';
  const containerClasses = clsx(
    'div',
    containerClassName,
    defaultContainerClassNames
  );

  const defaultIconContainerClassNames = 'cursor-pointer absolute';
  const iconContainerClasses = clsx(
    'i',
    iconContainerClassName,
    defaultIconContainerClassNames
  );

  return (
    <div className={containerClasses}>
      <input
        disabled={disabled}
        required={required}
        onChange={handleChange}
        placeholder={placeholder}
        name={name}
        type={passwordShown ? 'text' : 'password'}
        value={value}
        className={classes}
      />
      <i className={iconContainerClasses} onClick={togglePasswordVisibility}>
        {passwordShown ? (
          <RiEyeLine className={clsx(iconClassName)} />
        ) : (
          <RiEyeCloseLine className={clsx(iconClassName)} />
        )}
      </i>
    </div>
  );
}

export default InputWithToggle;
