import React from 'react';
import clsx from 'clsx';

export default function InlineButton(props) {
  const { className, id } = props;
  const defaultClassNames =
    'flex items-center min-h-0 cursor-pointer epp-hover text-16 w-max';

  const classes = clsx(className, defaultClassNames);

  return (
    <div className={classes} type={props.type} onClick={props.handleClick}>
      <p id={id}>{props.name}</p>
      {props.icon && <img className='ml-9' src={props.icon} />}
    </div>
  );
}
