import React from 'react';

export default function CopyrightDetails() {
  return (
    <div className='flex flex-wrap justify-between text-white font-sans'>
      <div className='flex flex-row flex-wrap text-white'>
        <p>Copyright © {new Date().getFullYear()} Encantos, PBC. |&nbsp;</p>
        <a
          className='text-white epp-hover'
          href='https://encantos.helpshift.com/hc/en/'
          rel='noreferrer'
          target='_blank'
        >
          Support
        </a>
      </div>
      <p>All rights reserved.</p>
    </div>
  );
}
