import React from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { requestGTMEvent } from '../../../store/redux/actions';

function ExternalLink({ className, label, route, id = '', requestGTM }) {
  const defaultClassNames = 'bg-purple-light text-white epp-hover';
  const classes = clsx('a', className, defaultClassNames);

  function handleClick(event) {
    event.preventDefault();
    requestGTM({
      type: 'event',
      event: {
        category: 'download',
        action: 'click',
        label: 'location',
        value: id || 'external link',
      },
    });
    Object.assign(document.createElement('a'), {
      target: '_blank',
      href: route,
    }).click();
  }

  return (
    <a className={classes} rel='noreferrer' onClick={handleClick}>
      {label}
    </a>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    requestGTM: (payload) => dispatch(requestGTMEvent(payload)),
  };
}

export default connect(null, mapDispatchToProps)(ExternalLink);
