import FacebookLightPurple from '../../assets/Socials/facebook_light_purple.svg';
import InstagramLightPurple from '../../assets/Socials/instagram_light_purple.svg';
import TwitterLightPurple from '../../assets/Socials/twitter_light_purple.svg';
import YoutubeLightPurple from '../../assets/Socials/youtube_light_purple.svg';

export const SOCIAL_MAP = [
  {
    altText: 'facebook logo',
    href: 'https://www.facebook.com/EncantosBrands/',
    svg: FacebookLightPurple,
  },
  {
    altText: 'twitter logo',
    href: 'https://twitter.com/EncantosBrands',
    svg: TwitterLightPurple,
  },
  {
    altText: 'instagram logo',
    href: 'https://www.instagram.com/encantos/',
    svg: InstagramLightPurple,
  },
  {
    altText: 'youtube logo',
    href: 'https://www.youtube.com/channel/UCFJJziTVsiIVSyRKvXHX37g',
    svg: YoutubeLightPurple,
  },
];
