import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import ReferredPromoCode from '../../components/Subscribe/ReferredPromoCode';
import Nav from '../../components/Subscribe/SubscriptionNav';
import { viewDictionary } from './constants';
import AsyncRender from '../../components/AsyncRender';
import { requestGTMEvent } from '../../store/redux/actions';

const SubscriptionForm = ({ Component, title }) => {
  return (
    <>
      <h1
        className={`font-black md:hidden leading-normal text-center pb-20 text-purple-light`}
      >
        {title}
      </h1>
      <div className='lg:w-840'>
        <Component />
      </div>
    </>
  );
};

function Subscribe({
  isLoggedIn,
  loading,
  requestGTM,
  location,
  createdAccount,
}) {
  const history = useHistory();
  const {
    params: { view },
  } = useRouteMatch();

  const { Component, step, title } = viewDictionary[view];
  viewDictionary[view] || viewDictionary['create-account'];

  function getView() {
    if (
      !createdAccount &&
      [
        '/subscribe/plan-details',
        '/subscribe/checkout',
        '/subscribe/success',
      ].includes(location.pathname)
    ) {
      history.replace('/subscribe/create-account');
    } else if (createdAccount && location.pathname === '/subscribe/checkout') {
      history.replace('/subscribe/checkout');
    } else if (createdAccount && location.pathname === '/subscribe/success') {
      history.replace('/subscribe/success');
    } else {
      null;
    }
  }
  useEffect(() => {
    // removed until we revert to using the subscription flow
    // getView();
    if (!createdAccount) {
      history.replace('/login');
    }
    requestGTM({ view: title });
  }, [step]);

  return (
    <div>
      {view !== 'success' ? (
        <div className='flex flex-col pt-20 pb-30 px-15 lg:px-80'>
          <div className='flex flex-col items-center justify-center'>
            <ReferredPromoCode />
          </div>
          <div className='flex flex-col md:flex-row items-center lg:items-start justify-center'>
            <Nav currentStep={step} isLoggedIn={isLoggedIn} />
            <AsyncRender
              Component={SubscriptionForm}
              loading={loading}
              props={{ Component, title }}
            />
          </div>
        </div>
      ) : (
        <>
          <AsyncRender Component={Component} loading={loading} />
        </>
      )}
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    requestGTM: (payload) => dispatch(requestGTMEvent(payload)),
  };
}

function mapStateToProps({ user }, ownProps) {
  const { authToken, loading, priceId, createdAccount } = user;
  return {
    isLoggedIn: !!authToken,
    loading,
    priceId,
    createdAccount,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Subscribe);
