import React from 'react';
import { Link } from 'react-router-dom';

export default function StepCircle({ isDisabled, route, step }) {
  const stepColor = isDisabled
    ? 'border-2 border-gray-light border-solid text-gray-light'
    : 'bg-purple-light text-white';

  return (
    <Link
      className={`${stepColor} flex items-center justify-center h-30 md:mr-12 rounded-full text-18 w-30`}
      to={route}
    >
      <p className='font-black'>{step}</p>
    </Link>
  );
}
