export const SET_TOKEN = 'SET_TOKEN';
export const REQUEST_SUBSCRIPTION_DETAILS = 'REQUEST_SUBSCRIPTION_DETAILS';
export const RECEIVED_SUBSCRIPTION_DETAILS = 'RECEIVED_SUBSCRIPTION_DETAILS';
export const RECEIVED_SUBSCRIPTION_DETAILS_FAILED =
  'RECEIVED_SUBSCRIPTION_DETAILS_FAILED';
export const SET_SHOW_UPDATE_PAYMENT_METHOD = 'SET_SHOW_UPDATE_PAYMENT_METHOD';
export const REQUEST_UPDATE_PAYMENT_METHOD = 'REQUEST_UPDATE_PAYMENT_METHOD';
export const RECEIVED_UPDATE_PAYMENT_METHOD = 'RECEIVED_UPDATE_PAYMENT_METHOD';
export const RECEIVED_UPDATE_PAYMENT_METHOD_FAILED =
  'RECEIVED_UPDATE_PAYMENT_METHOD_FAILED';
export const REQUEST_SET_ERROR = 'REQUEST_SET_ERROR';
export const REQUEST_RESET_ERROR = 'REQUEST_RESET_ERROR';
export const SET_LOADING = 'SET_LOADING';
export const SET_PRICE_ID = 'SET_PRICE_ID';
export const REQUEST_CREATE_SUBSCRIPTION = 'REQUEST_CREATE_SUBSCRIPTION';
export const RECEIVED_CREATE_SUBSCRIPTION = 'RECEIVED_CREATE_SUBSCRIPTION';
export const RECEIVED_CREATE_SUBSCRIPTION_FAILED =
  'RECEIVED_CREATE_SUBSCRIPTION_FAILED';
export const REQUEST_PARENT = 'REQUEST_PARENT';
export const RECEIVED_PARENT = 'RECEIVED_PARENT';
export const RECEIVED_PARENT_FAILED = 'RECEIVED_PARENT_FAILED';
export const REQUEST_ADD_CHILD = 'REQUEST_ADD_CHILD';
export const RECEIVED_ADD_CHILD = 'RECEIVED_ADD_CHILD';
export const RECEIVED_ADD_CHILD_FAILED = 'RECEIVED_ADD_CHILD_FAILED';
export const REQUEST_UPDATE_CHILD = 'REQUEST_UPDATE_CHILD';
export const RECEIVED_UPDATE_CHILD = 'RECEIVED_UPDATE_CHILD';
export const RECEIVED_UPDATE_CHILD_FAILED = 'RECEIVED_UPDATE_CHILD_FAILED';
export const REQUEST_DELETE_CHILD = 'REQUEST_DELETE_CHILD';
export const RECEIVED_DELETE_CHILD = 'RECEIVED_DELETE_CHILD';
export const RECEIVED_DELETE_CHILD_FAILED = 'RECEIVED_DELETE_CHILD_FAILED';
export const HANDLE_INPUT_CHANGE = 'HANDLE_INPUT_CHANGE';
export const REQUEST_UPDATE_ACCOUNT = 'REQUEST_UPDATE_ACCOUNT';
export const RECEIVED_UPDATE_ACCOUNT = 'RECEIVED_UPDATE_ACCOUNT';
export const RECEIVED_UPDATE_ACCOUNT_FAILED = 'RECEIVED_UPDATE_ACCOUNT_FAILED';
export const SET_UPDATE_PLAN_ID = 'SET_UPDATE_PLAN_ID';
export const REQUEST_UPDATE_PLAN = 'REQUEST_UPDATE_PLAN';
export const RECEIVED_UPDATE_PLAN = 'RECEIVED_UPDATE_PLAN';
export const RECEIVED_UPDATE_PLAN_FAILED = 'RECEIVED_UPDATE_PLAN_FAILED';
export const REQUEST_ACTIVE_SUBSCRIPTIONS = 'REQUEST_ACTIVE_SUBSCRIPTIONS';
export const RECEIVED_ACTIVE_SUBSCRIPTIONS = 'RECEIVED_ACTIVE_SUBSCRIPTIONS';
export const RECEIVED_ACTIVE_SUBSCRIPTIONS_FAILED =
  'RECEIVED_ACTIVE_SUBSCRIPTIONS_FAILED';
export const SET_USER_DATA = 'SET_USER_DATA';
export const REFER_A_FRIEND = 'REFER_A_FRIEND';
export const RECEIVED_REFER_A_FRIEND = 'RECEIVED_REFER_A_FRIEND';
export const RECEIVED_REFER_A_FRIEND_FAILED = 'RECEIVED_REFER_A_FRIEND_FAILED';
export const RESET_PROMO_SENT = 'RESET_PROMO_SENT';
export const REQUEST_VALIDATE_PROMO_CODE = 'REQUEST_VALIDATE_PROMO_CODE';
export const RESET_PROMO_VALIDATION = 'RESET_PROMO_VALIDATION';
export const SET_PROMO = 'SET_PROMO';
export const VALIDATE_PROMO_CODE_FAILURE = 'VALIDATE_PROMO_CODE_FAILURE';
export const VALIDATE_PROMO_CODE_SUCCESS = 'VALIDATE_PROMO_CODE_SUCCESS';
export const RESET_PROMO_ERROR = 'RESET_PROMO_ERROR';
export const CLEAR_PROMO_CODE = 'CLEAR_PROMO_CODE';
export const SET_FORM_ERROR = 'SET_FORM_ERROR';
export const REQUEST_CANCEL_SUBSCRIPTION = 'REQUEST_CANCEL_SUBSCRIPTION';
export const RECEIVED_CANCEL_SUBSCRIPTION = 'RECEIVED_CANCEL_SUBSCRIPTION';
export const RECEIVED_CANCEL_SUBSCRIPTION_FAILED =
  'RECEIVED_CANCEL_SUBSCRIPTION_FAILED';
export const REQUEST_LOGIN = 'REQUEST_LOGIN';
export const RECEIVED_LOGIN = 'RECEIVED_LOGIN';
export const RECEIVED_LOGIN_FAILED = 'RECEIVED_LOGIN_FAILED';
export const REQUEST_LOGOUT = 'REQUEST_LOGOUT';
export const RECEIVED_LOGOUT = 'RECEIVED_LOGOUT';
export const RECEIVED_LOGOUT_FAILED = 'RECEIVED_LOGOUT_FAILED';
export const REQUEST_USER_SESSION = 'REQUEST_USER_SESSION';
export const RECEIVED_USER_SESSION = 'RECEIVED_USER_SESSION';
export const REQUEST_GTM = 'REQUEST_GTM';
export const REQUEST_CREATE_ACCOUNT = 'REQUEST_CREATE_ACCOUNT';
export const REQUEST_ACCOUNT_CREATED = 'REQUEST_ACCOUNT_CREATED';
export const REQUEST_RESET_STATE = 'REQUEST_RESET_STATE';
export const REQUEST_SET_SPINNER = 'REQUEST_SET_SPINNER';
export const REQUEST_RESET_SPINNER = 'REQUEST_RESET_SPINNER';
export const REQUEST_RESET_SUBSCRIPTION_STATUS =
  'REQUEST_RESET_SUBSCRIPTION_STATUS';
export const REQUEST_PAYMENT_INTENT = 'REQUEST_PAYMENT_INTENT';
export const RECEIVED_PAYMENT_INTENT = 'RECEIVED_PAYMENT_INTENT';
export const REQUEST_GIFT_PROMO_CODE = 'REQUEST_GIFT_PROMO_CODE';
export const RECEIVED_GIFT_PROMO_CODE = 'RECEIVED_GIFT_PROMO_CODE';
export const REQUEST_GIFT_PDF = 'REQUEST_GIFT_PDF';
export const RECEIVED_GIFT_PDF = 'RECEIVED_GIFT_PDF';
export const RECEIVED_GIFT_PDF_FAILED = 'RECEIVED_GIFT_PDF_FAILED';
