import React from 'react';

import SenorTickTockPng from '../../../assets/backgrounds/characters/senor_tick_tock.png';
import BenjiPng from '../../../assets/backgrounds/characters/benji.png';
import TinyTravelersPng from '../../../assets/backgrounds/characters/tiny_travelers.png';
import SammyPng from '../../../assets/backgrounds/characters/sammy.png';
import KikiPng from '../../../assets/backgrounds/characters/kiki.png';
import PinPonPng from '../../../assets/backgrounds/characters/pin_pon.png';

export default function ChangePwSuccessChars() {
  return (
    <div className='flex justify-between w-full'>
      <img
        className='h-98 w-98 mt-54 -ml-10'
        src={BenjiPng}
      /> 
      <img
        className='h-80 w-80 mt-285'
        src={TinyTravelersPng}
      />
      <img
        className='h-115 w-115 mt-118'
        src={KikiPng}
      /> 
      <img
        className='h-92 w-92 mt-255'
        src={SenorTickTockPng}
      />
      <img
        className='h-138 w-138 mt-68'
        src={PinPonPng}
      />
      <img
        className='h-67 w-67 mt-290'
        src={SammyPng}
      />
    </div>
  );
}
