import React from 'react';
import clsx from 'clsx';

export default function Checkbox({ checkboxClasses, isSelected }) {
  const defaultClasses = `absolute flex items-center justify-center border-2 border-solid hover:border-purple-light
  ${isSelected ? 'bg-purple-light border-purple-light' : 'border-gray-light'}
  h-25 mb-20 self-end rounded-full w-25`;

  const classes = clsx('div', checkboxClasses, defaultClasses);

  return (
    <div className={classes}>
      <svg
        className={`${isSelected ? 'block' : 'hidden'}w-20 h-20`}
        fill='none'
        stroke='#FFFFFF'
        viewBox='0 0 24 24'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth={2}
          d='M5 13l4 4L19 7'
        />
      </svg>
    </div>
  );
}
