import React from 'react';
import ToggleSwitch from './ToggleSwitch';

function ToggleSwitchWithLabel({
  checked,
  disabled = false,
  handleChange,
  label,
  inputName,
}) {
  return (
    <div className='flex flex-row w-full'>
      <p className='w-4/6 text-16'>{label}</p>
      <ToggleSwitch
        handleChange={handleChange}
        inputName={inputName}
        checked={checked}
        disabled={disabled}
      />
    </div>
  );
}

export default ToggleSwitchWithLabel;
