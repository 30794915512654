import React from 'react';
import { CardElement } from '@stripe/react-stripe-js';

export default function StripeCardElement({ setError }) {
  return (
    <CardElement
      className='bg-transparent border w-full h-56 mb-30 pl-20 text-16 sm:w-360 rounded-xl p-15'
      onChange={(event) => {
        if (event.error) {
          setError(event.error);
        }
      }}
      options={{
        style: {
          base: {
            fontSize: '16px',
            fontSmoothing: 'antialiased',
            lineHeight: '1.5rem',
            color: '#424770',
            '::placeholder': {
              color: '#aab7c4',
            },
          },
          invalid: {
            color: 'rgba(220, 38, 38, 1)',
          },
        },
      }}
    />
  );
}
