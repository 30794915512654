import React from 'react';

import KikiPng from '../../../assets/backgrounds/characters/kiki.png';
import SkeletinaPng from '../../../assets/backgrounds/characters/skeletina.png';

export default function CreateAcctChars() {
  return (
    <>
      <img
        className='absolute hidden h-135 w-135 md:block'
        src={SkeletinaPng}
        style={{
          bottom: '40%',
          left: 'calc(50vw - 485px)',
        }}
      />
      <img
        className='absolute hidden md:block h-79 w-79'
        src={KikiPng}
        style={{
          bottom: '47%',
          left: 'calc(50vw - 570px)',
        }}
      />
    </>
  );
}
