import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { requestUserSession } from '../../../store/redux/actions';
import SuccessBottomSection from '../../../components/Subscribe/Success/SuccessBottomSection';
import SuccessTopSection from '../../../components/Subscribe/Success/SuccessTopSection';

function Success({ customerId, authToken, getUserSession, parent }) {
  useEffect(() => {
    const hasParent = parent && Object.keys(parent).length; // NOTE: object keys amount > 0 will return true
    if (typeof window.refersion === 'function') {
      window.refersion(function () {
        // eslint-disable-next-line no-undef
        _rfsn._setSource('STRIPE');
        // eslint-disable-next-line no-undef
        _rfsn._addCart(customerId);
      });
    }

    if (authToken && !hasParent) {
      getUserSession({});
    }
  }, []);

  return (
    <div id='successPage' className='flex flex-col items-center desktop:h-800'>
      <SuccessTopSection />
      <SuccessBottomSection />
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    getUserSession: (payload) => dispatch(requestUserSession(payload)),
  };
}

function mapStateToProps({ user }, ownProps) {
  const {
    subscription: {
      details: { customer_id },
    },
    authToken,
    parent,
  } = user;
  return { customerId: customer_id, authToken, parent };
}

export default connect(mapStateToProps, mapDispatchToProps)(Success);
