import React from 'react';

import FooterLink from './FooterLink';

export default function LinkContainer({ links }) {
  return (
    <div className='mr-12'>
      {links.map(({ href, title, ...restProps }, idx) => (
        <FooterLink
          key={`${title}-${idx}`}
          addPadding={idx === 0}
          label={title}
          route={href}
          {...restProps}
        />
      ))}
    </div>
  );
}
