import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';

const DISPLAY_SECONDS = 10;

const useStyles = makeStyles(() => ({
  message: {
    borderRadius: 10,
    marginLeft: 24,
    marginRight: 24,
    marginTop: 24,
    padding: 15,
  },
  label: {
    textTransform: 'uppercase',
  },
}));

export default function TransitionAlert({ label, description, success, show }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  useEffect(() => {
    setTimeout(() => {
      setOpen(false);
    }, DISPLAY_SECONDS * 1000);
  }, []);

  useEffect(() => {
    setOpen(true);
    setTimeout(() => {
      setOpen(false);
    }, DISPLAY_SECONDS * 1000);
  }, [show]);

  return (
    <div style={{ width: '100vw' }}>
      <Collapse in={open}>
        <Alert
          className={classes.message}
          color={success ? 'success' : 'error'}
          severity={success ? 'success' : 'error'}
          action={
            <IconButton
              aria-label='close'
              color='inherit'
              size='small'
              onClick={() => {
                setOpen(false);
              }}
            >
              <CloseIcon fontSize='inherit' />
            </IconButton>
          }
        >
          <div>
            <strong className={classes.label}>{label}</strong>{' '}
            <p>{description}</p>
          </div>
        </Alert>
      </Collapse>
    </div>
  );
}

TransitionAlert.propTypes = {
  label: PropTypes.string,
  description: PropTypes.string,
  success: PropTypes.bool,
  show: PropTypes.bool,
};
