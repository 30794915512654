import React from 'react';

import BenjiPng from '../../../assets/backgrounds/characters/benji.png';
import SammyPng from '../../../assets/backgrounds/characters/sammy.png';
import PinPonPng from '../../../assets/backgrounds/characters/pin_pon.png';

export default function ForgotPwChars() {
  return (
    <div className='flex flex-col justify-between w-full'>
      <img
        className='h-100 w-100 ml-60 xs:ml-82 mt-44'
        src={BenjiPng}
      />
      <img
        className='h-82 w-82 ml-170 xs:ml-210 mt-42'
        src={PinPonPng}
      />
      <img
        className='h-110 w-110 ml-60 xs:ml-70'
        src={SammyPng}
        /> 
    </div>
  );
}
