import React from 'react';

import QRCode from '../../../assets/encantos_qr_code.jpg';

export default function SuccessTopSection() {
  return (
    <div className='flex flex-col items-center max-w-520 text-center'>
      <img
        alt='QR Code'
        className='desktop:block hidden md:max-w-220'
        src={QRCode}
      />
      <h1 className='font-black inline leading-none mb-10 md:mb-0 md:mt-10 md:text-64'>
        You’re almost there!
      </h1>
      <h2 className='leading-normal'>
        Your child’s adventures in the storyverse are just moments away.
      </h2>
    </div>
  );
}
