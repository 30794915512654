import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';

import AsyncRender from '../../components/AsyncRender';
import GiftForm from './views/GiftForm';
import Success from './views/Success';
import {
  handleInputChange,
  requestGiftPromoCode,
  requestPaymentIntent,
  resetErrorMessage,
  setErrorMessage,
} from '../../store/redux/actions';

function SendGift({
  changeInput,
  createPaymentIntent,
  error,
  gift,
  loading,
  resetError,
  ...restProps
}) {
  useEffect(() => {
    createPaymentIntent();
  }, []);

  const handleChange = (event) => {
    if (error) resetError();
    const { emailNotification, termsAndPrivacy } = gift;
    const name = event.target.name;

    const isEmailNotification = name === 'emailNotification';
    const isTermsAndPrivacy = name === 'termsAndPrivacy';
    const isBoolean = isEmailNotification || isTermsAndPrivacy;
    const booleanVal = isTermsAndPrivacy
      ? !termsAndPrivacy
      : !emailNotification;

    const value = !isBoolean ? event.target.value : booleanVal;

    changeInput({ name, value });
  };

  const { pathname } = useLocation();
  const Component = pathname === '/send-gift/success' ? Success : GiftForm;

  return (
    <div className='flex flex-col items-center w-full'>
      <AsyncRender
        Component={Component}
        loading={loading}
        props={{
          error,
          gift,
          handleChange,
          ...restProps,
        }}
      />
    </div>
  );
}

function mapStateToProps({ user }) {
  const { error, gift, loading } = user;

  return {
    error,
    gift,
    loading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changeInput: (payload) =>
      dispatch(handleInputChange({ form: 'gift', ...payload })),
    createGift: (payload) => dispatch(requestGiftPromoCode(payload)),
    createPaymentIntent: () => dispatch(requestPaymentIntent()),
    resetError: () => dispatch(resetErrorMessage()),
    setError: (payload) => dispatch(setErrorMessage(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SendGift);
