import React from 'react';
import clsx from 'clsx';
import { cardDictionary } from './constants';
import useCardFlip from '../../hooks/useCardFlip';
import Checkbox from './Subscription/Checkbox';
import { browserName, CustomView } from 'react-device-detect';

export default function TwoSidedCard({
  addMargin,
  className,
  icon,
  isSelected,
  onClick,
  title,
  type,
  ...restProps
}) {
  const { setCardSide, showFront } = useCardFlip(true);
  const isSafari = browserName === 'Safari' ? true : false;
  const Component = showFront
    ? cardDictionary[type]['front']
    : cardDictionary[type]['back'];

  const marginTop = addMargin ? 'mt-14' : '';
  const largeMarginLeft = addMargin ? 'lg:ml-14' : '';
  const defaultClassNames = `bg-background border-2 border-solid border-background cursor-pointer
    flex flex-col items-center justify-center min-h-fitcontent ${marginTop} ${largeMarginLeft} lg:mt-0 py-45 px-12 relative rounded-xlarge px-35 md:px-55 shadow-encantosShadow`;
  const classes = clsx(className, defaultClassNames);

  return (
    <div
      id={`subscription-card-${title}`}
      className={classes}
      onClick={onClick}
    >
      {type === 'subscription' && (
        <Checkbox checkboxClasses='right-25 top-25' isSelected={isSelected} />
      )}
      {icon && <img src={icon} />}
      {restProps.front.banner && (
        <div
          className={`ribbon purple -mr-5 ${isSafari ? '-mt-41' : '-mt-96'}`}
        >
          {restProps.front.banner}
        </div>
      )}

      <h1
        id={`subscription-title-${title}`}
        className={`font-black leading-normal text-center ${icon && 'pt-20'}`}
      >
        {title}
      </h1>
      <Component flipCard={setCardSide} {...restProps} />
    </div>
  );
}
