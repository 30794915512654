import React from 'react';

import InactiveModal from './InactiveModal';
import CTA from '../../components/CTA';
import Error from '../../components/Error';
import FormInput from '../../components/FormInput';
import Banner from '../../components/Banner';

export default function ReferAFriendView({
  handleChange,
  handleReferAFriend,
  isActiveSubscription,
  referral,
  setError,
}) {
  const { email, error, first_name, promoSent } = referral;
  const handleSubmit = (event) => {
    event.preventDefault();
    if (!email || !first_name) {
      setError('Please complete all form fields');
    } else {
      handleReferAFriend({ email, first_name });
    }
  };

  return (
    <div className='ml-15 pr-15'>
      {!isActiveSubscription && <InactiveModal />}
      {promoSent && (
        <Banner>
          <svg
            className='h-25 mr-10 w-25'
            fill='none'
            stroke='currentColor'
            viewBox='0 0 24 24'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth={2}
              d='M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z'
            />
          </svg>
          <p className='text-16 px-5'>{`The promo code has been sent to your friend ${
            email && `at ${email}`
          }`}</p>
        </Banner>
      )}
      <div
        className={`flex flex-col justify-center px-20 lg:px-0 lg:flex-row rounded-xlarge shadow-encantosShadow py-15 lg:py-45 ${
          promoSent ? 'mt-10' : 'mt-0'
        }`}
      >
        <div className='text-24 lg:w-305 mb-24 lg:mb-0'>
          <p className='font-black leading-normal mb-20'>
            Tell a friend about Encantos!
          </p>
          <p className='leading-normal'>
            They&apos;ll get <b className='font-black'>20% off</b> an annual
            subscription and you can choose a free book!
          </p>
        </div>
        <div className='flex flex-col items-center justify-center'>
          {error && <Error className='text-center lg:w-340' error={error} />}
          <form
            className={`flex items-center w-full md:w-10/12 lg:w-full flex-col ${
              error ? 'pt-5' : 'pt-0'
            }`}
            onSubmit={handleSubmit}
          >
            <FormInput
              className='bg-transparent border h-56 mb-24 pl-20 rounded-xl text-16 w-full lg:w-340'
              handleChange={handleChange}
              name='first_name'
              placeholder='First Name'
              type='text'
              value={first_name}
            />
            <FormInput
              className='bg-transparent border h-56 mb-24 pl-20 rounded-xl text-16 w-full lg:w-340'
              handleChange={handleChange}
              name='email'
              placeholder='Email'
              type='text'
              value={email}
            />
            <CTA
              buttonClassName='bg-purple-light h-56 rounded-2xl self-end text-white w-145'
              name='Submit'
              textClassName='text-16 font-extrabold text-white'
              type='submit'
            />
          </form>
        </div>
      </div>
    </div>
  );
}
