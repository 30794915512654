import React from 'react';

import SkeletinaPng from '../../../assets/backgrounds/characters/skeletina.png';
import KikiPng from '../../../assets/backgrounds/characters/kiki.png';
import TinyTravelersPng from '../../../assets/backgrounds/characters/tiny_travelers.png';

export default function LoginCharacters() {
  return (
    <div className='flex justify-center w-full'>
      <img
        className= 'h-118 w-118 mt-24'
        src={SkeletinaPng}
      />
      <img
        className= 'h-106 w-106 -ml-15 mt-167 mb-40'
        src={TinyTravelersPng}
      />
      <img
        className= 'h-86 w-86 mt-80'
        src={KikiPng}
      /> 
    </div>
  );
}
