import React, { useContext } from 'react';
import { Redirect } from 'react-router-dom';
import Pool from '../UserPool';

export default function Landing({ location, user }) {
  if (user) {
    <Redirect to='/home' />;
  }
  return (
    <Redirect
      to={{ pathname: '/subscribe/create-account', search: location.search }}
    />
  );
}
