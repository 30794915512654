import { useState } from 'react';

export default function useCardFlip(initialValues) {
  const [showFront, flipCard] = useState(initialValues);

  const setCardSide = () => {
    flipCard((prev) => !prev);
  };

  return {
    setCardSide,
    showFront,
  };
}
