import React from 'react';
import { connect, useSelector } from 'react-redux';
import useForm from '../../hooks/useForm';
import {
  requestAddChild,
  requestDeleteChild,
  requestUpdateChild,
} from '../../store/redux/actions';
import CTA from '../CTA';
import FormInput from '../FormInput';
import { TiTrash } from 'react-icons/ti';
import Avatar from '../../assets/miscIcons/Avatar.png';

function ChildModal({
  handleClose,
  requestAddNewChild,
  requestUpdateChild,
  requestDeleteChild,
  id = null,
  avatar,
}) {
  const defaultAvatar = Avatar;
  const child = useSelector((state) =>
    state.user.children.find((item) => item.id === id)
  );

  const { values, handleChange } = useForm({
    initialValues: getInitialValues(),
  });

  let title = 'Add Child';
  let CTAName = 'Add';
  let submitForm = submitAddChild;
  let childId = '';

  if (id) {
    title = 'Edit Child Profile';
    submitForm = submitEditChild;
    CTAName = 'Save';
    childId = id;
  }

  function getInitialValues() {
    if (id && child) {
      return child;
    } else {
      return {
        first_name: '',
        birth_month: '01',
        birth_year: new Date().getFullYear().toString(),
        avatar,
      };
    }
  }

  function removedIdAndActive(values) {
    const formatted = { ...values };
    delete formatted.id;
    delete formatted.active;
    return formatted;
  }

  function submitAddChild(e) {
    e.preventDefault();
    requestAddNewChild(values);
  }

  function submitEditChild(e) {
    e.preventDefault();
    const formattedChild = removedIdAndActive(values);
    requestUpdateChild(formattedChild, childId);
  }

  function submitDeleteChild() {
    requestDeleteChild(childId);
  }

  const createYearList = () => {
    let currentYear = new Date().getFullYear(),
      years = [];
    const oldestAge = 12;
    let startYear = new Date().getFullYear() - oldestAge;
    while (currentYear >= startYear) {
      years.push(currentYear--);
    }
    return years;
  };

  const yearOptionList = createYearList().map((year, i) => {
    return (
      <option key={i} value={year}>
        {year}
      </option>
    );
  });

  const monthList = [
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10',
    '11',
    '12',
  ];

  const monthOptionList = monthList.map((month, i) => {
    return (
      <option key={i} value={month}>
        {month}
      </option>
    );
  });

  return (
    <div>
      <div className='flex flex-row mb-10 mt-10 sm:mb-30'>
        <p className='w-full mb-20 text-32 font-extrabold flex justify-center'>
          {title}
        </p>
      </div>
      <div className='flex flex-col sm:flex-row w-full'>
        <img
          className='w-144 h-144 mx-auto bg-transparent border-transparent sm:w-206 h-206'
          src={avatar || defaultAvatar}
        />
        <div className='w-full'>
          <form
            onSubmit={submitForm}
            className='px-15 flex flex-col items-center mt-30 w-full'
          >
            <FormInput
              className='bg-transparent border w-10/12 h-56 pl-20 text-16 sm:w-360 rounded-xl'
              handleChange={handleChange}
              name={'first_name'}
              placeholder={'First Name / Nickname'}
              type={'text'}
              value={values.first_name}
              required={true}
            />

            <div className='mr-15 mb-15 mt-30 flex items-center justify-center flex-row w-8/12 sm:w-full ml-30 '>
              <p className='mr-70 text-16'>Birth Month</p>

              <select
                id='monthsDropDown'
                onChange={handleChange}
                value={values.birth_month}
                name='birth_month'
                className='mr-25 pl-20 h-56 w-86 text-16 border rounded-xl cursor-pointer'
              >
                {monthOptionList}
              </select>
              <select
                id='yearsDropDown'
                onChange={handleChange}
                value={values.birth_year}
                name='birth_year'
                className='pl-20 h-56 w-86 text-16 border rounded-xl cursor-pointer'
              >
                {yearOptionList}
              </select>
            </div>

            <div className='w-9/12 mt-10 flex items-center justify-center flex-row space-x-25 sm:w-full justify-start md:mt-60'>
              {!id ? null : (
                <button
                  className='h-48 w-48 px-8 py-2 border-2 text-16 flex items-center justify-center border-purple-light border-solid text-purple-light rounded-xl'
                  onClick={() => {
                    submitDeleteChild();
                  }}
                >
                  <TiTrash className='h-20 w-20' />
                </button>
              )}
              <button
                className='h-48 w-138 px-8 py-2 border-2 text-16 border-purple-light border-solid text-purple-light rounded-xl sm:w-130'
                onClick={handleClose}
              >
                Cancel
              </button>
              <CTA
                buttonClassName='h-48 w-138 rounded-2xl bg-purple-light epp-hover text-white sm:w-130'
                textClassName='text-16 font-extrabold capitalize text-white'
                name={CTAName}
                type='submit'
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    requestAddNewChild: (payload) => dispatch(requestAddChild(payload)),
    requestUpdateChild: (payload, id) =>
      dispatch(requestUpdateChild({ payload, id })),
    requestDeleteChild: (id) => dispatch(requestDeleteChild({ id })),
  };
}

export default connect(null, mapDispatchToProps)(ChildModal);
