import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import useForm from '../hooks/useForm';
import FormInput from '../components/FormInput';
import { MessagingContext } from '../contexts/MessagingContext';

export default function Home({ user, authToken }) {
  const [studentData, setStudentData] = useState(null);
  const [aPIGData, setAPIGData] = useState([]);
  const { addSuccessMessage, addErrorMessage } = useContext(MessagingContext);
  const { values, handleChange } = useForm({
    initialValues: {
      firstName: '',
      lastName: '',
      password: '',
      userId: '',
      username: '',
    },
  });

  const baseUrl = process.env.REACT_APP_BASE_API_URL;

  // You can either start the query when this gets mounted or manually run it.
  useEffect(() => {
    // Automatically run the query if you want
    if (user) {
      GetApiGatewayStudentData();
    }
  }, [user]);

  const SaveRecordAPIGateway = async () => {
    return axios
      .put(
        `${baseUrl}/users`,
        {
          id:
            '8nHZSrCVAEXAHZHRWG5jxS434fa44fa' +
            Math.floor(Math.random() * 1000),
          guardianId: user.sub,
          firstName: values.firstName,
          lastName: values.lastName,
          productId: 'E0tlgKOjRI01G85ICPSG6Qnn8cr8Yrc6',
          progress: 'Third Chapter',
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authToken}`,
          },
        }
      )
      .then(
        (response) => {
          addSuccessMessage(
            'Add Student',
            'Your student was successfully added.'
          );
          GetApiGatewayStudentData();
        },
        (error) => {
          addErrorMessage(
            'Add Student',
            'Unable to add your student. Please try again.'
          );
        }
      );
  };

  const GetApiGatewayStudentData = () => {
      const getUrl = `${baseUrl}/users`;
      axios
        .get(getUrl, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((apigResp) => {
          let filteredResAPIG = apigResp.data.Items.filter(
            (item) => item.gaurdianId === user.sub
          );
          setAPIGData(filteredResAPIG);
        })
        .catch();
  };

  if (user && user['custom:firstName']) {
    return (
      <div className='page mb-5'>
        <div style={{ padding: '20px' }}>
          <p>API Gateway Query Response:</p>
          {aPIGData.length ? (
            <>
              {aPIGData.map((person, index) => (
                <p key={index}>
                  {person.firstName} {person.lastName}
                </p>
              ))}
            </>
          ) : (
            <span></span>
          )}
        </div>
        <div style={{ padding: '20px' }}>
          <p>GraphQL Query Response:</p>
          {studentData ? (
            <>
              {studentData.map((person, index) => (
                <p key={index}>
                  {person.firstName} {person.lastName}
                </p>
              ))}
            </>
          ) : (
            <span></span>
          )}
        </div>
        <p></p>
        <br />
        <br />
        <br />
        <br />
        <h3>Add Children</h3>
        <p></p>
        <p></p>
        <form onSubmit={SaveRecordAPIGateway}>
          <FormInput
            type={'text'}
            placeholder={'first name'}
            name={'firstName'}
            value={values.firstName}
            handleChange={handleChange}
          />
          <FormInput
            type={'text'}
            placeholder={'last name'}
            name={'lastName'}
            value={values.lastName}
            handleChange={handleChange}
          />
          <div className='inlineForm__submit'></div>
        </form>

        <p style={{ marginTop: '40px' }}>
          <button onClick={SaveRecordAPIGateway}>
            Click To Add A STUDENT / CHILD FOR THIS User Via API Gateway
          </button>
        </p>
      </div>
    );
  }
  return <div></div>;
}
