import LoadingSpinner from './LoadingSpinner';
import React from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { requestGTMEvent } from '../store/redux/actions';

function ButtonWithSpinner({
  buttonClassName,
  bgColor,
  handleClick,
  name,
  textClassName,
  disabled,
  id = 'Button click',
  showSpinner = false,
}) {
  const defaultButtonClassNames = `px-10 epp-hover ${
    bgColor ? bgColor : 'bg-purple-light'
  } text-white rounded-2xl`;

  const btnClassName = clsx(buttonClassName, defaultButtonClassNames);

  return (
    <button
      id={id}
      disabled={disabled}
      className={btnClassName}
      onClick={handleClick}
    >
      <p className={textClassName}>{name}</p>
      <LoadingSpinner
        isOn={true}
        displaySpinner={showSpinner}
        className={'flex min-w-fitcontent justify-right h-27'}
      />
    </button>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    requestGTM: (payload) => dispatch(requestGTMEvent(payload)),
  };
}

export default connect(null, mapDispatchToProps)(ButtonWithSpinner);
