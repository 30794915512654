import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { connect, useSelector } from 'react-redux';
import { requestDownloadGiftPDF } from '../../../store/redux/actions';
import ButtonWithSpinner from '../../../components/ButtonWithSpinner';

function SendGiftSuccess({ gift: { promoCode }, requestGiftPDF }) {
  const history = useHistory();
  const showSpinner = useSelector((state) => state.user.showSpinner);

  if (!promoCode) history.push('/send-gift');

  const environment = process.env.REACT_APP_STAGE;
  const isProd = environment === 'prod';
  const s3UrlPrefix = 'https://parents-content';
  const s3UrlSuffix = '.encantosworld.com';

  const src = `${s3UrlPrefix}${!isProd ? `.${environment}` : ''}${s3UrlSuffix}`;
  const downloadPDF = () => {
    const payload = { promoCode };
    requestGiftPDF(payload);
  };

  const [buttonDisabled, setButtonDisabled] = useState(showSpinner);
  useEffect(() => {
    setButtonDisabled(showSpinner);
  }, [showSpinner]);
  const buttonBg = `${
    buttonDisabled ? 'bg-gray-light border-gray-light' : 'bg-giftRed'
  }`;
  return (
    <div className='relative bg-teal-bright bg-contain bg-no-repeat lg:bg-successStarsDesktop flex flex-col items-center pb-50 lg:pb-20 w-full'>
      <img
        alt='stars'
        className='lg:hidden absolute top-20'
        src={`${src}/gifting/success_stars_mobile.svg`}
      />
      <div className='w-full md:w-602 lg:w-840 bg-background flex flex-col items-center justify-center my-10 lg:my-60 rounded-xlarge overflow-hidden shadow-encantosShadow text-16 p-30'>
        <img alt='Andy Icon' src={`${src}/app_icon.svg`} />
        <h1 className='font-black leading-normal pb-7 text-center'>
          Your gift is on its way!
        </h1>
        <h3 className='pb-7 text-center text-18 text-purple-light'>
          Thank you for giving the gift of learning and fun, the gift of
          Encantos.
        </h3>
        <p className='pb-7 text-center'>
          Everything&apos;s all set! The Encantos gift subscription you
          purchased was sent to the recipient&apos;s email address and your
          payment has been processed.
        </p>
        <div className='lg:w-790 bg-background flex flex-col items-center justify-center rounded-xlarge overflow-hidden shadow-encantosShadow mb-16 text-16 p-30'>
          <p className='font-black text-center text-18'>
            Want to send them a note yourself?
          </p>
          <p className='my-5 text-center text-grayText text-13'>
            Copy and paste the below into your own message
          </p>
          <hr className='border-gray-light border-1 border-solid my-10 w-full' />
          <p className='font-black mb-5 text-center text-16'>
            Encantos Subscription Gift Code
          </p>
          <p className='font-black text-center text-purple-light mb-15'>
            {promoCode}
          </p>
          <p className='font-black mb-5 text-center text-16'>
            Click to download your Gift Code PDF
          </p>

          <ButtonWithSpinner
            buttonClassName='flex justify-center items-center h-56 rounded-2xl text-white min-w-fitcontent'
            textClassName='text-16 capitalize text-white'
            bgColor={buttonBg}
            name={'Download Gift PDF'}
            handleClick={downloadPDF}
            showSpinner={showSpinner}
            disabled={buttonDisabled}
          />
        </div>
      </div>
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    requestGiftPDF: (payload) => dispatch(requestDownloadGiftPDF(payload)),
  };
}

export default connect(null, mapDispatchToProps)(SendGiftSuccess);
