import {
  REQUEST_PARENT,
  REQUEST_ADD_CHILD,
  REQUEST_UPDATE_ACCOUNT,
  REQUEST_ACTIVE_SUBSCRIPTIONS,
  REQUEST_UPDATE_CHILD,
  REFER_A_FRIEND,
  REQUEST_VALIDATE_PROMO_CODE,
  REQUEST_UPDATE_PLAN,
  REQUEST_CANCEL_SUBSCRIPTION,
  REQUEST_DELETE_CHILD,
  REQUEST_LOGIN,
  REQUEST_LOGOUT,
  REQUEST_USER_SESSION,
  REQUEST_CREATE_ACCOUNT,
  REQUEST_GTM,
  REQUEST_PAYMENT_INTENT,
  REQUEST_GIFT_PROMO_CODE,
  REQUEST_GIFT_PDF,
} from '../constants';
import { put, call, fork, takeEvery, select } from 'redux-saga/effects';
import {
  requestToken,
  requestSubscriptionDetails,
  requestUpdatePaymentMethod,
  requestCreateSubscription,
  requestParent,
  requestAddChild,
  requestUpdateAccount,
  requestActiveSubscriptions,
  requestCognitoUser,
  requestUpdateChild,
  requestReferAFriend,
  requestValidatePromoCode,
  requestUpdatePlan,
  requestCancelSubscription,
  requestDeleteChild,
  requestLogin,
  requestLogout,
  requestStripePaymentIntent,
  requestGiftPromo,
  requestGiftPDF,
} from '../services';
import {
  setUserData,
  receivedCreateSubscription,
  receivedCreateSubscriptionFailed,
  receivedCancelSubscription,
  receivedCancelSubscriptionFailed,
  receivedSubscriptionDetails,
  receivedSubscriptionDetailsFailed,
  receivedUpdatePaymentMethod,
  receivedUpdatePaymentMethodFailed,
  receivedAddChildFailure,
  receivedAddChildSuccess,
  receivedParentFailure,
  receivedParentSuccess,
  receivedUpdateAccountSuccess,
  receivedUpdateAccountFailure,
  receivedActiveSubscriptionsFailure,
  receivedActiveSubscriptionsSuccess,
  receivedUpdateChildSuccess,
  receivedUpdateChildFailure,
  receivedReferAFriend,
  receivedReferAFriendFailed,
  validatePromoCodeSuccess,
  validatePromoCodeFailure,
  receivedUpdatePlan,
  receivedUpdatePlanFailed,
  receivedDeleteChildSuccess,
  receivedDeleteChildFailure,
  receivedLogin,
  receivedLoginFailed,
  receivedLogout,
  receivedLogoutFailed,
  receivedUserSession,
  setErrorMessage,
  setLoading,
  receivedPaymentIntent,
  receivedGiftPromoCode,
  receivedDownloadGiftPDFSuccess,
  receivedDownloadGiftPDFFailure,
} from '../actions';

import { triggerGTMEvent } from '../services';

let authToken;

const getToken = (state) => {
  return state.user.authToken;
};

const getUserData = (state) => {
  return state.user.parent;
};

const getPriceId = (state) => {
  return state.user.priceId;
};

export function* createSubscription({ payload }) {
  const cognitoUser = yield setCognitoUser();
  const priceId = yield select(getPriceId);
  const paymentMethod =
    (payload.paymentMethod && payload.paymentMethod.id) || '';
  const promo = payload.promo;
  const history = payload.history;

  const userId = cognitoUser.sub || cognitoUser.id;

  try {
    if (!priceId || !userId) {
      throw 'The subscription could not be created';
    }
    const refersion = localStorage.getItem('rfsn_aid');
    const subscription = yield call(requestCreateSubscription, {
      parentId: userId,
      priceId,
      paymentMethod,
      promo,
      refersion,
    });

    if (history.location.pathname === '/parent-portal/manage-subscription') {
      history.push('/parent-portal/manage-subscription');
    } else {
      history.push('/subscribe/success');
    }

    yield put(receivedCreateSubscription(subscription));
  } catch (err) {
    const error = err.error || err;

    const message =
      error && !error.messageType
        ? error
        : error.messageType === 'single'
        ? error.message
        : error.message.join(', ');
    yield put(receivedCreateSubscriptionFailed(message));
  }
}

export function* cancelSubscription() {
  yield setToken();
  try {
    const subscription = yield call(requestCancelSubscription, authToken);
    yield put(receivedCancelSubscription(subscription));
  } catch (err) {
    yield put(receivedCancelSubscriptionFailed(err));
  }
}

function* setCognitoUser() {
  let userData = yield select(getUserData);
  if (!userData) {
    userData = yield call(requestCognitoUser);
    yield put(setUserData(userData));
  }
  return userData;
}

function* setToken() {
  authToken = yield call(requestToken);
  yield put({ type: 'SET_TOKEN', payload: authToken });
}

export function* getSubscriptionDetails() {
  yield setToken();
  try {
    const { data } = yield call(requestSubscriptionDetails, authToken);
    yield put(receivedSubscriptionDetails(data));
  } catch (err) {
    yield put(receivedSubscriptionDetailsFailed(err));
  }
}

export function* updatePaymentMethod({ payload }) {
  yield setToken();
  try {
    yield call(requestUpdatePaymentMethod, {
      token: authToken,
      paymentMethod: payload.id,
    });
    yield put(receivedUpdatePaymentMethod(payload));
  } catch (err) {
    yield put(receivedUpdatePaymentMethodFailed(err));
  }
}

export function* getParent() {
  yield setToken();
  try {
    const { data } = yield call(requestParent, authToken);
    yield put(receivedParentSuccess(data));
  } catch (err) {
    yield put(receivedParentFailure(err));
  }
}

export function* addChild({ payload }) {
  yield setToken();
  try {
    const { data } = yield call(requestAddChild, { authToken, payload });
    yield put(receivedAddChildSuccess(data));
  } catch (err) {
    yield put(receivedAddChildFailure(err));
  }
}

export function* updateChild({ Payload }) {
  yield setToken();
  try {
    const { data } = yield call(requestUpdateChild, { authToken, Payload });
    yield put(receivedUpdateChildSuccess(data));
  } catch (err) {
    yield put(receivedUpdateChildFailure(err));
  }
}

export function* deleteChild({ payload }) {
  yield setToken();
  try {
    const { data } = yield call(requestDeleteChild, { authToken, payload });
    yield put(receivedDeleteChildSuccess(data));
  } catch (err) {
    yield put(receivedDeleteChildFailure(err));
  }
}

export function* updateAccount({ payload }) {
  yield setToken();
  try {
    const { data } = yield call(requestUpdateAccount, { authToken, payload });
    yield put(receivedUpdateAccountSuccess(data));
  } catch (err) {
    yield put(receivedUpdateAccountFailure(err));
  }
}

export function* referAFriend({ payload }) {
  yield setToken();
  try {
    yield call(requestReferAFriend, { authToken, payload });
    yield put(receivedReferAFriend());
  } catch (err) {
    const {
      error: { messageType, message },
    } = err;
    const errMessage = messageType === 'single' ? message : message.join(', ');
    yield put(receivedReferAFriendFailed(errMessage));
  }
}

export function* getActiveSubscriptions() {
  try {
    const {
      data: { subscriptions },
    } = yield call(requestActiveSubscriptions);
    yield put(receivedActiveSubscriptionsSuccess(subscriptions));
  } catch (err) {
    yield put(receivedActiveSubscriptionsFailure(err));
  }
}

export function* validatePromoCode({ payload }) {
  try {
    const { data } = yield call(requestValidatePromoCode, {
      payload: {
        loading: payload.loading,
        promo_code: payload.promo_code,
      },
    });
    const response = validatePromoCodeSuccess(data);

    if (payload.selectedPrice !== response.payload.product_id) {
      yield put(
        validatePromoCodeFailure(
          'To redeem this promo code you need to go back and select another subscription plan'
        )
      );
    } else {
      yield put(response);
    }
  } catch (err) {
    const {
      error: { message, messageType },
    } = err;
    const errMessage = messageType === 'single' ? message : message.join(', ');
    yield put(validatePromoCodeFailure(errMessage));
  }
}

export function* updatePlan({ payload }) {
  yield setToken();
  try {
    yield call(requestUpdatePlan, { authToken, payload });
    yield put(receivedUpdatePlan());
  } catch (err) {
    const {
      error: { message, messageType },
    } = err;
    const errMessage = messageType === 'single' ? message : message.join(', ');
    yield put(receivedUpdatePlanFailed(errMessage));
  }
}

export function* login({ payload }) {
  const { history, historyLocation, ...loginPayload } = payload;

  try {
    const user = yield call(requestLogin, loginPayload);
    if (history && historyLocation) {
      history.push(historyLocation);
    }
    yield put(receivedLogin(user));
    yield call(getSubscriptionDetails);
  } catch (err) {
    if (err.code === 'PasswordResetRequiredException') {
      history.push(
        `/forgot-password?email=${encodeURIComponent(loginPayload.Username)}`
      );
    }

    yield put(receivedLoginFailed(err));
  }
}

export function* logout({ payload }) {
  try {
    const history = payload.history;
    const cognitoUser = yield setCognitoUser();
    const user = yield call(requestLogout, payload);

    window.heap.resetIdentity(cognitoUser.sub);

    yield put(receivedLogout(user));

    history.push('/');
  } catch (err) {
    yield put(receivedLogoutFailed(err));
  }
}

export function* resolveUserSession({ payload }) {
  const history = payload.history;

  try {
    yield setToken();
    const user = yield setCognitoUser();

    if (!user && history) {
      history.push('/');
    }

    yield getParent();
    yield getSubscriptionDetails();

    yield put(receivedUserSession(user));
  } catch (error) {
    yield put(receivedLogoutFailed(error));

    if (history) {
      history.push('/');
    }
  }
}

export function* updateAccountAuth({ loginPayload, updateAccountPayload }) {
  yield call(requestLogin, loginPayload);

  yield setToken();
  yield call(requestUpdateAccount, {
    authToken,
    payload: updateAccountPayload,
  });
}

export function* createAccount() {
  try {
    const cognitoUser = yield setCognitoUser();
    const loginPayload = {
      Username: cognitoUser.email.trim(),
      Password: cognitoUser.password,
      history,
    };
    const updateAccountPayload = {
      payload: {
        first_name: cognitoUser.first_name,
        last_name: cognitoUser.last_name,
        email: cognitoUser.email,
        settings: {
          marketing: {
            email: cognitoUser.emailNotification || false,
            sms: false,
          },
        },
      },
    };
    yield call(updateAccountAuth, { loginPayload, updateAccountPayload });
    yield put(setLoading(false));
  } catch (err) {
    const {
      error: { message, messageType },
    } = err;
    const errMessage = messageType === 'single' ? message : message.join(', ');
    yield put(setErrorMessage(errMessage));
  }
}

export function* requestPaymentIntent() {
  try {
    const {
      data: {
        auth_token: authToken,
        client_secret: clientSecret,
        gift_purchase_price: giftPurchasePrice,
      },
    } = yield call(requestStripePaymentIntent);
    yield put(
      receivedPaymentIntent({
        giftPurchasePrice,
        paymentIntentData: { authToken, clientSecret },
      })
    );
  } catch (err) {
    yield put(
      setErrorMessage(
        'Oops there is an issue with this page, please reach out to customer service for support.'
      )
    );
  }
}

export function* requestGiftPromoCode({ payload }) {
  try {
    const { history, ...restPayload } = payload;
    const {
      data: { gift },
    } = yield call(requestGiftPromo, { ...restPayload });
    yield put(receivedGiftPromoCode(gift));
    history.push('/send-gift/success');
  } catch (err) {
    yield put(
      setErrorMessage(
        'Oops there is an issue with this page, please reach out to customer service for support.'
      )
    );
  }
}

export function* downloadGiftPDF({ payload }) {
  try {
    const response = yield call(requestGiftPDF, { payload });
    window.open(response.data.signedUrl, '_blank');
    yield put(receivedDownloadGiftPDFSuccess());
  } catch (err) {
    yield put(receivedDownloadGiftPDFFailure(err));
  }
}

export function* callGTMEvent({ payload }) {
  yield call(triggerGTMEvent, payload);
}

function* watchTriggerGTM() {
  yield takeEvery(REQUEST_GTM, callGTMEvent);
}

function* watchUpdateAccount() {
  yield takeEvery(REQUEST_UPDATE_ACCOUNT, updateAccount);
}

function* watchGetSubscription() {
  yield takeEvery('REQUEST_SUBSCRIPTION_DETAILS', getSubscriptionDetails);
}

function* watchUpdatePaymentMethod() {
  yield takeEvery('REQUEST_UPDATE_PAYMENT_METHOD', updatePaymentMethod);
}

function* watchCreateSubscription() {
  yield takeEvery('REQUEST_CREATE_SUBSCRIPTION', createSubscription);
}
function* watchCancelSubscription() {
  yield takeEvery(REQUEST_CANCEL_SUBSCRIPTION, cancelSubscription);
}

function* watchGetParent() {
  yield takeEvery(REQUEST_PARENT, getParent);
}

function* watchAddChild() {
  yield takeEvery(REQUEST_ADD_CHILD, addChild);
}

function* watchGetActiveSubscriptions() {
  yield takeEvery(REQUEST_ACTIVE_SUBSCRIPTIONS, getActiveSubscriptions);
}

function* watchUpdateChild() {
  yield takeEvery(REQUEST_UPDATE_CHILD, updateChild);
}
function* watchDeleteChild() {
  yield takeEvery(REQUEST_DELETE_CHILD, deleteChild);
}

function* watchReferAFriend() {
  yield takeEvery(REFER_A_FRIEND, referAFriend);
}
function* watchValidateReferralPromo() {
  yield takeEvery(REQUEST_VALIDATE_PROMO_CODE, validatePromoCode);
}

function* watchRequestUpdatePlan() {
  yield takeEvery(REQUEST_UPDATE_PLAN, updatePlan);
}

function* watchRequestLogin() {
  yield takeEvery(REQUEST_LOGIN, login);
}

function* watchRequestLogout() {
  yield takeEvery(REQUEST_LOGOUT, logout);
}

function* watchRequestUserSession() {
  yield takeEvery(REQUEST_USER_SESSION, resolveUserSession);
}

function* watchRequestCreateAccount() {
  yield takeEvery(REQUEST_CREATE_ACCOUNT, createAccount);
}

function* watchRequestPaymentIntent() {
  yield takeEvery(REQUEST_PAYMENT_INTENT, requestPaymentIntent);
}

function* watchRequestGiftPromoCode() {
  yield takeEvery(REQUEST_GIFT_PROMO_CODE, requestGiftPromoCode);
}
function* watchDownloadGiftPDF() {
  yield takeEvery(REQUEST_GIFT_PDF, downloadGiftPDF);
}

export default function* stateWatcher() {
  yield fork(watchGetSubscription);
  yield fork(watchUpdatePaymentMethod);
  yield fork(watchCreateSubscription);
  yield fork(watchValidateReferralPromo);
  yield fork(watchCancelSubscription);
  yield fork(watchGetParent);
  yield fork(watchAddChild);
  yield fork(watchUpdateChild);
  yield fork(watchDeleteChild);
  yield fork(watchUpdateAccount);
  yield fork(watchReferAFriend);
  yield fork(watchGetActiveSubscriptions);
  yield fork(watchRequestUpdatePlan);
  yield fork(watchRequestLogin);
  yield fork(watchRequestLogout);
  yield fork(watchRequestUserSession);
  yield fork(watchRequestCreateAccount);
  yield fork(watchTriggerGTM);
  yield fork(watchRequestPaymentIntent);
  yield fork(watchRequestGiftPromoCode);
  yield fork(watchDownloadGiftPDF);
}
