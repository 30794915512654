import React from 'react';

import InlineButton from '../../InlineButton';

export default function CardBack({ back: { subTitle, text }, flipCard }) {
  return (
    <>
      <p className='mt-10 text-16 font-medium text-center'>{subTitle}</p>
      <p className='mt-10 text-16 font-medium text-center'>{text}</p>
      <InlineButton
        className='font-black mt-25'
        handleClick={flipCard}
        id='flipCard'
        name='← Back'
      />
    </>
  );
}
