import React from 'react';

import ModalBase from './ModalBase';
import QRCode from '../../assets/encantos_qr_code.jpg';

export default function DownloadModal({ handleClose, isOpen }) {

  return (
    <ModalBase
      handleClose={handleClose}
      isOpen={isOpen}
      containerClassName="w-full h-573 flex flex-wrap content-center justify-center sm:w-2/3 h-602 md:w-552 bg-white rounded-3xl p-10 relative"
    >
      <div className="flex items-center flex-col w-fitContent">
        <h4 className="text-24">Scan the QR code to download the app</h4>
        <img className="pt-20 w-300" src={QRCode} />
      </div>
    </ModalBase>
  );
}
