import React from 'react';
import { Link } from 'react-router-dom';

import InlineButton from '../InlineButton';

export default function Breadcrumbs({ linkVal, pathname }) {
  return (
    <div className='flex flex-row flex-wrap items-center'>
      <span className='mr-4'>⭠</span>
      <a className='font-black' href='https://encantosworld.com'>
        <InlineButton name='Back to home' />
      </a>
      &nbsp;
      <p className='text-base'>/</p>
      &nbsp;
      <Link to={pathname}>
        <InlineButton name={linkVal} />
      </Link>
    </div>
  );
}
