import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import SideNav from '../components/SideNav';
import { SideNavData } from '../components/SideNav/SideNavConstants';
import { SideNavLinks } from '../components/SideNav/SideNavConstants';
import ChildProfile from '../pages/ChildProfile';
import ManageSubscription from '../pages/ManageSubscription';
import ReferAFriend from '../pages/ReferAFriend';
import ParentAccount from '../pages/ParentAccount';
import UpgradePlan from '../pages/UpgradePlan';

function ParentPortalLayout({ isSubscriptionActive, parent }) {
  const parentName = parent.first_name;
  // get initials
  const firstName = parent.first_name || '';
  const lastName = parent.last_name || '';
  const initials = `${firstName.charAt(0)}${lastName.charAt(0) || ''}`;

  return (
    <div className='mx-auto pb-25 flex flex-col overflow-y-scroll sm:flex-row max-w-1200'>
      <div className='mx-auto ml-15 flex flex-col'>
        <SideNav
          isSubscriptionActive={isSubscriptionActive}
          sideNavData={SideNavData}
          sideNavLinks={SideNavLinks}
          userName={parentName}
          email={parent.email}
          initials={initials}
        />
      </div>
      <div className='flex mx-auto sm:ml-20 lg:min-w-843'>
        <Switch>
          <Route exact path='/parent-portal' component={ChildProfile}></Route>
          <Route path='/parent-portal/child-profile' component={ChildProfile} />
          <Route
            path='/parent-portal/parent-account'
            component={ParentAccount}
          />
          <Route
            path='/parent-portal/manage-subscription'
            component={ManageSubscription}
          />
          <Route
            path='/parent-portal/refer-a-friend'
            component={ReferAFriend}
          />
          <Route
            exact
            path='/parent-portal/upgrade-plan'
            component={UpgradePlan}
          />
        </Switch>
      </div>
    </div>
  );
}

export default ParentPortalLayout;
