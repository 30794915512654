import React from 'react';
import { Link } from 'react-router-dom';
import Check from '../../assets/miscIcons/Check.svg';

function CancelSuccessModal({ handleClose }) {
  return (
    <div>
      <div className='flex flex-col justify-center sm:w-full'>
        <img className='mt-40 mx-auto h-160 w-160' src={Check} />
        <p className='w-10/12 mx-auto mt-27 text-24 leading-8 font-bold text-center sm:w-434 md:text-32'>
          Subscription canceled successfully
        </p>
      </div>
      <hr className='w-10/12 mx-auto mt-37 border-gray-hrLine border-solid sm:w-456' />

      <div className='mt-33 flex flex-col justify-center'>
        <p className='text-24 text-center leading-8 sm:w-434'>
          We&apos;re sorry to see you go!
        </p>
        <p className='text-24 text-center leading-8 sm:w-434'>
          We&apos;ll miss you.
        </p>
      </div>
      <div className='mt-25 mb-40 flex justify-center sm:mt-63'>
        <Link className='font-black' to='/parent-portal/child-profile'>
          <button
            className='h-48 w-176 px-8 py-2  bg-purple-light border-solid text-16 text-white font-extrabold rounded-xl epp-hover sm:w-130'
            onClick={handleClose}
          >
            Back to Home
          </button>
        </Link>
      </div>
    </div>
  );
}

export default CancelSuccessModal;
