import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { requestGTMEvent } from '../store/redux/actions';
import LoadingSpinner from './LoadingSpinner';

function CTA({
  buttonClassName,
  bgColor,
  type,
  handleSubmit,
  name,
  textClassName,
  disabled,
  form = null,
  id = '',
  requestGTM,
  showSpinner = false,
}) {
  const [buttonDisabled, setButtonDisabled] = useState(disabled);

  const defaultButtonClassNames = `px-26 epp-hover ${
    buttonDisabled
      ? 'bg-gray-light border-gray-light rounded-2xl'
      : `${bgColor ? bgColor : 'bg-purple-light'} text-white rounded-2xl`
  }`;

  const btnClassName = clsx(buttonClassName, defaultButtonClassNames);

  useEffect(() => {
    setButtonDisabled(showSpinner);
  }, [showSpinner]);

  function handleClick(event) {
    requestGTM({
      type: 'event',
      event: {
        category: 'subscribe',
        action: 'click',
        label: 'location',
        value: id || 'CTA button click',
      },
    });
  }
  return (
    <button
      id={id}
      disabled={disabled}
      form={form}
      className={btnClassName}
      type={type}
      onClick={handleClick}
      onSubmit={handleSubmit}
    >
      <p className={textClassName}>{name}</p>
      <LoadingSpinner
        isOn={true}
        displaySpinner={showSpinner}
        className={'flex min-w-fitcontent justify-right h-27'}
      />
    </button>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    requestGTM: (payload) => dispatch(requestGTMEvent(payload)),
  };
}

export default connect(null, mapDispatchToProps)(CTA);
