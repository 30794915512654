import React from 'react';

import SkeletinaPng from '../../../assets/backgrounds/characters/skeletina.png';
import SenorTickTockPng from '../../../assets/backgrounds/characters/senor_tick_tock.png';
import KikiPng from '../../../assets/backgrounds/characters/kiki.png';
import BenjiPng from '../../../assets/backgrounds/characters/benji.png';
import TinyTravelersPng from '../../../assets/backgrounds/characters/tiny_travelers.png';
import PinPonPng from '../../../assets/backgrounds/characters/pin_pon.png';

export default function LoginCharacters() {

  return (
    <>
      <img
        className='absolute hidden md:block h-119 w-119'
        src={SkeletinaPng}
        style={{
          left: 'calc(50vw - 700px)',
          top: '13%',
        }}
      />
      <img
        className='absolute hidden md:block h-86 w-86'
        src={KikiPng}
        style={{
          top: '20%',
          left: 'calc(50vw - 450px)'
        }}
      /> 
      <img
        className='absolute hidden md:block h-130 w-130'
        src={TinyTravelersPng}
        style={{
          top: '30%',
          left: 'calc(50vw - 650px)',
        }}
      />
      <img
        className='absolute hidden h-170 w-170 md:block'
        src={BenjiPng}
        style={{
          right: 'calc(50vw - 800px)',
          top: '5%',
        }}
      />
      <img
        className='absolute hidden h-96 w-96 md:block'
        src={SenorTickTockPng}
        style={{
          right: 'calc(50vw - 500px)',
          top: '20%',
        }}
      />
      <img
        className='absolute hidden h-110 w-110 md:block'
        src={PinPonPng}
        style={{
          right: 'calc(50vw - 700px)',
          top: '33%',
        }}
      />
    </>
  );
}
