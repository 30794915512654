import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import {
  requestSubscriptionDetails,
  requestParent,
  setLoading,
  showUpdatePaymentMethod,
  requestUpdatePaymentMethod,
  resetErrorMessage,
  receivedUpdatePaymentMethodFailed,
  requestCancelSubscription,
  requestResetSubscriptionStatus,
} from '../store/redux/actions';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import StripeCardElement from '../components/StripeCardElement';
import { useHistory } from 'react-router-dom';
import PPHeader from '../components/PPHeader';
import Loading from '../components/Loading';
import AsyncRender from '../components/AsyncRender';
import yellowLogo from '../assets/Encantos_yellow_logo.svg';
import blueLogo from '../assets/encantos_logo_blue.svg';
import pinkLogo from '../assets/encantos_logo_pink.svg';

const logos = {
  yearly: pinkLogo,
  monthly: blueLogo,
  extra: yellowLogo,
};

import {
  displaySubscriptionPrice,
  formatDate,
  formatPrice,
} from '../formatters';
import ModalBase from '../components/modals/ModalBase';
import CancelSubModal from '../components/modals/CancelSubModal';
import PromoModal from '../components/modals/PromoModal';
import CancelSuccessModal from '../components/modals/CancelSuccessModal';
import PrimarySecondaryModalButtons from '../components/PrimarySecondaryModalButtons';
import AlreadyCanceldModal from '../components/modals/AlreadyCanceldModal';

import PlanDetailsView from './Subscribe/views/PlanDetails';
import PaymentInfoView from './Subscribe/views/PaymentInfo';

const getLogo = (subscription) => {
  const planName = subscription.details.name || '';
  return logos[planName.toLowerCase()] || yellowLogo;
};

const PaymentHistoryCard = ({ payments = [] }) => {
  const chunkSize = 3;
  const paymentChunks = Array(Math.ceil(payments.length / chunkSize))
    .fill()
    .map((_, index) => index * chunkSize)
    .map((begin) => payments.slice(begin, begin + chunkSize));
  const totalPaymentChunks = paymentChunks.length;
  const [showingPayments, setShowingPayments] = useState(
    paymentChunks[0] || []
  );
  const [chunk, setChunk] = useState(0);
  const [showMore, setShowMore] = useState(totalPaymentChunks > 1);
  const viewMore = () => {
    if (showMore) {
      const newChunk = chunk + 1;
      setChunk(newChunk);
      setShowingPayments(showingPayments.concat(paymentChunks[newChunk]));
      setShowMore(newChunk + 1 < totalPaymentChunks); // plus one to offset array base zero
    }
  };
  return (
    <div>
      {/* only show payment history when we have payment data*/}
      {payments.length < 1 ? null : (
        <div className='rounded-xlarge overflow-hidden shadow-encantosShadow text-16 p-30'>
          <div className='mx-auto'>
            <div id='paymentHistoryHeader'>
              <h3 className='font-black'>Payment History</h3>
            </div>
            <table className='text-left w-full table-fixed mb-30'>
              <thead className='mb-30 text-grayText border-b border-solid border-gray-300'>
                <tr>
                  <th className='py-20'>Date</th>
                  <th className='py-20'>Amount</th>
                  <th className='py-20'>Store</th>
                </tr>
              </thead>
              <tbody>
                {showingPayments.map((payment) => (
                  <tr
                    key={payment.id}
                    className='border-b border-solid border-gray-300'
                  >
                    <td className='py-20'>{formatDate(payment.date)}</td>
                    <td className='py-20'>{formatPrice(payment.amount)}</td>
                    <td className='py-20'>{payment.store}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div id='showMore'>
              {showMore ? (
                <a
                  className='block w-full text-right text-black'
                  onClick={viewMore}
                >
                  View more
                </a>
              ) : (
                <p className='block w-full text-right text-gray-300'>
                  View more
                </p>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const EditPaymentInfoCard = ({
  setShowUpdatePaymentMethod,
  updatePaymentMethod,
  error,
  setEditPaymentError,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const cancel = () => {
    setShowUpdatePaymentMethod(false);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    const card = elements.getElement(CardElement);
    const { error, paymentMethod } = await stripe
      .createPaymentMethod({
        type: 'card',
        card: card,
      })
      .catch((error) => ({ error }));

    if (error) {
      setEditPaymentError(error.message);
    } else {
      updatePaymentMethod(paymentMethod);
    }
  };

  return (
    <div className='rounded-xlarge overflow-hidden shadow-encantosShadow text-16 p-30 sm:w-550'>
      <div className='flex justify-center'>
        <div className='mx-auto'>
          <div id='editPaymentMethodCardHeader'>
            <p className='mb-30'>
              Update Credit or Debit Card
              {error && (
                <>
                  -
                  <span className='text-errorText'>
                    {error.message || error}
                  </span>
                </>
              )}
            </p>
          </div>

          {!stripe && !elements ? (
            <Loading />
          ) : (
            <>
              <div id='editPaymentMethodCardForm'>
                <StripeCardElement setError={setEditPaymentError} />
              </div>
              <div id='editCardBtn' className='w-full flex justify-start'>
                <PrimarySecondaryModalButtons
                  containerClassName='justify-start	space-x-20'
                  primaryBtnClassName='h-56 w-2/5 sm:w-180'
                  primaryBtnText='Cancel'
                  primaryBtnOnClick={cancel}
                  secondaryBtnClassName='h-56 w-2/5 sm:w-180'
                  secondaryBtnText='Update Payment Information'
                  secondaryBtnOnClick={handleSubmit}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const SubscriptionPanel = ({
  resetSubscriptionStatus,
  error,
  subscription,
  setShowUpdatePaymentMethod,
  cancelSubscription,
  subCancelSuccess,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showPromoModal, setShowPromoModal] = useState(false);
  const [showSuccessfulCancelModal, setShowSuccessfulCancelModal] =
    useState(subCancelSuccess);
  const scheduledForCancel =
    subscription && subscription.details.status === 'canceling_at_period_end';

  useEffect(() => {
    setShowSuccessfulCancelModal(subCancelSuccess);
  }, [subCancelSuccess]);

  const handleModalClose = () => {
    setIsModalOpen(false);
    setShowPromoModal(false);
    setIsModalOpen(false);
    setShowSuccessfulCancelModal(false);
    resetSubscriptionStatus();
  };

  const handleCancelSubClick = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    cancelSubscription();
    setIsModalOpen(false);
  };

  const handleCancelAnyway = () => {
    setShowPromoModal(false);
    setShowSuccessfulCancelModal(true);
  };

  function DisplayCorrectCancelSubModal() {
    if (scheduledForCancel) {
      // show modal if already scheduled for cancel
      return <AlreadyCanceldModal handleClose={handleModalClose} />;
    } else if (!showPromoModal && !subCancelSuccess) {
      return (
        <CancelSubModal
          handleClose={handleModalClose}
          handleCancel={handleCancel}
        />
      );
    } else if (!subCancelSuccess && showPromoModal) {
      return (
        <PromoModal
          handleClose={handleModalClose}
          handleCancel={handleCancelAnyway}
        />
      );
    } else return null;
  }

  const changePaymentInfo = () => {
    setShowUpdatePaymentMethod(true);
  };

  if (error && error.message) {
    return <p>{error.message}</p>;
  }

  const {
    details: { type },
  } = subscription;
  const isWeb = type === 'web';

  const paymentMethodSection = (
    <>
      <p className='text-16 mb-10'>Payment Method</p>
      <div className='flex justify-between text-16'>
        <p className='w-3/6'>
          <span className='uppercase'>
            {subscription.payment_information.brand}
          </span>{' '}
          {subscription.payment_information.last_4}
        </p>
        <a
          className='block w-full text-right text-black font-black'
          onClick={changePaymentInfo}
        >
          Change
        </a>
      </div>
    </>
  );

  const CancelSubscriptionWebLink = (
    <button onClick={handleCancelSubClick}>
      <a className='block w-full text-16 font-extrabold text-red-500 text-center '>
        Cancel Subscription
      </a>
    </button>
  );

  const CancelSubscriptionMobileLink = (
    <a className='block w-full pointer-events-none text-red-500 text-center '>
      Cancel Subscription in App
    </a>
  );

  const TrialingInfoMessage = (
    <p className='text-16 font-extrabold text-purple-light'>
      This subscription is in a trial period. The card below will be charged at
      the end of the trial period.
    </p>
  );

  const CanceledInfoMessage = (
    <p className='text-16 font-extrabold text-purple-light'>
      This subscription is scheduled to be canceled at the end of the current
      trial.
    </p>
  );

  const TrialEndDateInfo = (
    <div className='flex justify-between'>
      <p>Trial Membership Ends</p>
      {subscription.details.trial_end_date && (
        <p>{formatDate(subscription.details.trial_end_date)}</p>
      )}
    </div>
  );

  const NonTrialEndDateInfo = (
    <div id='membershipDate' className='flex justify-between text-16 py-10'>
      <p>Membership Ends</p>
      <p>
        {subscription.details.renewal_date &&
          formatDate(subscription.details.renewal_date)}
      </p>
    </div>
  );

  const hasTrialInfo =
    subscription.details.status === 'trialing' ||
    Boolean(subscription.details.trial_end_date);

  return (
    <div className='w-full mx-auto'>
      <div className='rounded-xlarge bg-white overflow-hidden shadow-encantosShadow text-16 p-30'>
        <div id='header' className='flex justify-between'>
          <div className='max-w-100'>
            <img src={getLogo(subscription)} className='w-full' />
          </div>
          <div className='flex-shrink-0 ml-10 md:ml-0'>
            <h3 className='mb-30 font-black'>Membership Plan</h3>
            <p className='mb-10 text-3xl font-black text-purple-light'>
              {subscription.details.name}
            </p>
            <p className='text-16'>
              {displaySubscriptionPrice(
                subscription.details.price,
                subscription.details.period
              )}
            </p>
          </div>
        </div>
        <div
          id='paymentMethod'
          className={`display-block pb-5 border-b border-solid border-gray-300 ${
            hasTrialInfo ? 'mt-0' : 'md:mt-45'
          }`}
        >
          {hasTrialInfo
            ? (subscription.details.status === 'trialing' &&
                TrialingInfoMessage) ||
              (Boolean(subscription.details.trial_end_date) &&
                subscription.details.status !== 'active' &&
                CanceledInfoMessage)
            : null}
          {isWeb ? (
            paymentMethodSection
          ) : (
            <>
              <p className='text-16 mb-10'>Payment Method</p>
              <p>Update your payment method in the app</p>
            </>
          )}
        </div>
        <div>{hasTrialInfo ? TrialEndDateInfo : NonTrialEndDateInfo}</div>
      </div>
      <div className='w-10/12 mx-auto text-16 text-center mt-20'>
        {isWeb ? CancelSubscriptionWebLink : CancelSubscriptionMobileLink}
      </div>
      <ModalBase
        isOpen={isModalOpen}
        handleClose={handleModalClose}
        containerClassName='w-full flex flex-wrap content-center justify-center sm:w-2/3 md:w-552 bg-white rounded-3xl p-10 relative'
      >
        <DisplayCorrectCancelSubModal />
      </ModalBase>
      {showSuccessfulCancelModal && (
        <>
          <ModalBase
            isOpen={showSuccessfulCancelModal}
            containerClassName='w-full flex flex-wrap content-center justify-center sm:w-2/3 md:w-552 bg-white rounded-3xl p-10 relative'
          >
            <CancelSuccessModal handleClose={handleModalClose} />
          </ModalBase>
        </>
      )}
    </div>
  );
};

function CreateSubscriptionView({ loading }) {
  const [stepCreateSubscription, setStepCreateSubscription] = useState(0);

  const configConfirmButton = {
    action: () => setStepCreateSubscription(2),
    title: 'Next',
  };
  const customBackButtonPlans = {
    action: () => setStepCreateSubscription(0),
    name: 'Cancel',
  };
  const customBackButtonPayment = {
    action: () => setStepCreateSubscription(1),
    name: 'Back to plans',
  };

  return (
    <>
      <div className='subscription-details'>
        <div className='px-15 pt-15'>
          {stepCreateSubscription === 0 && (
            <>
              <p>
                No subscription found.{' '}
                <a
                  href='https://www.encantosworld.com/encantos-app'
                  target='_blank'
                  rel='noreferrer'
                >
                  <b style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                    Click here
                  </b>{' '}
                </a>
                to create a new one
              </p>
            </>
          )}
          {stepCreateSubscription !== 0 && (
            <>
              <p className='text-16 font-bold capitalize pb-15'>
                CREATE SUBSCRIPTION
              </p>
            </>
          )}
          {stepCreateSubscription === 1 && (
            <AsyncRender
              Component={PlanDetailsView}
              loading={loading}
              props={{
                configConfirmButton,
                customBackButton: customBackButtonPlans,
              }}
            />
          )}
          {stepCreateSubscription === 2 && (
            <AsyncRender
              Component={PaymentInfoView}
              loading={loading}
              props={{
                customBackButton: customBackButtonPayment,
              }}
            />
          )}
        </div>
      </div>
    </>
  );
}

function ManageSubscriptionView({
  error,
  subscription,
  updateLoading,
  setShowUpdatePaymentMethod,
  showAddingPaymentMethod,
  updatePaymentMethod,
  resetError,
  setEditPaymentError,
  cancelSubscription,
  subCancelSuccess,
  resetSubscriptionStatus,
}) {
  const showSubscriptionPanel =
    subscription.isActive || subCancelSuccess ? true : false;
  return (
    <>
      {showSubscriptionPanel && (
        <div className='subscription-details'>
          {!showAddingPaymentMethod ? (
            <div className='grid grid-cols-1 gap-y-20 px-15 mb-25 sm:grid-cols-1 gap-x-25 gap-y-26 sm: md:grid-cols-1 md:max-w-lg md:content-around lg:grid-cols-2 gap-x-10 lg:max-w-7xl'>
              <>
                <SubscriptionPanel
                  resetSubscriptionStatus={resetSubscriptionStatus}
                  error={error}
                  subCancelSuccess={subCancelSuccess}
                  subscription={subscription}
                  setShowUpdatePaymentMethod={setShowUpdatePaymentMethod}
                  cancelSubscription={cancelSubscription}
                />
                {!error && (
                  <PaymentHistoryCard payments={subscription.payment_history} />
                )}
              </>
            </div>
          ) : (
            <div
              id='editPaymentInfo'
              className='grid grid-cols-1 gap-y-20 px-15 mb-25 sm:grid-cols-1 gap-x-25 gap-y-26 sm: md:grid-cols-1 md:max-w-lg md:content-around lg:grid-cols-1 gap-x-10 lg:max-w-7xl'
            >
              <EditPaymentInfoCard
                resetError={resetError}
                error={error}
                updateLoading={updateLoading}
                updatePaymentMethod={updatePaymentMethod}
                setShowUpdatePaymentMethod={setShowUpdatePaymentMethod}
                setEditPaymentError={setEditPaymentError}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
}

function ManageSubscription({
  loading,
  error,
  subscription,
  requestSubscription,
  requestParentInfo,
  cancelSubscription,
  updateLoading,
  setShowUpdatePaymentMethod,
  showAddingPaymentMethod,
  updatePaymentMethod,
  resetError,
  setEditPaymentError,
  resetSubscriptionStatus,
}) {
  const subCancelSuccess = useSelector((state) => state.user.subCancelSuccess);
  const preSelectedRoute = '/parent-portal/manage-subscription';
  const history = useHistory();

  useEffect(() => {
    history.push(preSelectedRoute);
    requestSubscription();
    requestParentInfo();
  }, []);

  const {
    details: { type },
  } = subscription;
  const isWeb = type === 'web';
  const headerTitle = 'Manage Subscription';
  const description = `Below, review your billing history, plan and payment type. Need to make some changes? ${
    isWeb
      ? `Just click on the section you're looking to update.`
      : `Please make any updates to your subscription or payment method in the ${
          type === 'ios' ? 'App' : 'Play'
        } Store.`
  }`;
  const buttonText =
    subscription &&
    subscription.details &&
    subscription.details.period === 'year'
      ? 'Change Plan'
      : 'Upgrade Plan';
  return (
    <div className='subscription-details pb-70 sm:pb-60 lg:pb-0'>
      <PPHeader
        title={headerTitle}
        description={description}
        loading={loading}
        hasSubscription={subscription.isActive}
        buttonPath={isWeb && '/parent-portal/upgrade-plan'}
        buttonText={isWeb && buttonText}
      ></PPHeader>

      {(subscription.isActive || subCancelSuccess) && (
        <AsyncRender
          Component={ManageSubscriptionView}
          loading={loading}
          props={{
            error,
            resetError,
            setEditPaymentError,
            setShowUpdatePaymentMethod,
            showAddingPaymentMethod,
            subscription,
            updateLoading,
            updatePaymentMethod,
            cancelSubscription,
            subCancelSuccess,
            resetSubscriptionStatus,
          }}
        />
      )}
      {!subscription.isActive && !subCancelSuccess && (
        <AsyncRender
          Component={CreateSubscriptionView}
          loading={loading}
          props={{
            preSelectedRoute,
            requestSubscription,
          }}
        />
      )}
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    requestSubscription: () => dispatch(requestSubscriptionDetails()),
    requestParentInfo: () => dispatch(requestParent()),
    updateLoading: (payload) => dispatch(setLoading(payload)),
    setShowUpdatePaymentMethod: (payload) =>
      dispatch(showUpdatePaymentMethod(payload)),
    updatePaymentMethod: (payload) =>
      dispatch(requestUpdatePaymentMethod(payload)),
    resetError: () => dispatch(resetErrorMessage()),
    setEditPaymentError: (payload) =>
      dispatch(receivedUpdatePaymentMethodFailed(payload)),
    cancelSubscription: () => dispatch(requestCancelSubscription()),
    resetSubscriptionStatus: () => dispatch(requestResetSubscriptionStatus()),
  };
}

function mapStateToProps({ user }, ownProps) {
  const { loading, error, subscription, showAddingPaymentMethod } = user;
  return {
    loading,
    error,
    subscription,
    showAddingPaymentMethod,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageSubscription);
