import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { logoDictionary } from '../constants';
import { requestGTMEvent, setPriceId } from '../../../store/redux/actions';
import InternalButton from '../../../components/InternalButton';
import TwoSidedCard from '../../../components/TwoSidedCard';
import BackToStepButton from '../../../components/BackButtons/BackToStepButton';

function PlanDetails({
  activeSubscriptions,
  currentPriceId,
  promoData,
  setSubscriptionId,
  configConfirmButton,
  customBackButton,
  isLoggedIn,
}) {
  const history = useHistory();

  useEffect(() => {
    const { code } = promoData;

    if (currentPriceId && code) {
      history.push('/subscribe/checkout');
    }
  }, []);

  return (
    <>
      {customBackButton && (
        <BackToStepButton
          className='font-bold'
          onClickAction={customBackButton.action}
          name={customBackButton.name}
          containerClassName='hidden md:flex mb-15'
        ></BackToStepButton>
      )}
      <div className='flex flex-col items-center lg:flex-row lg:items-start lg:justify-center'>
        {activeSubscriptions.map(
          (
            {
              id,
              name,
              avatar,
              language,
              price,
              product_id: stripeProductPriceId,
              ...restProps
            },
            idx
          ) => {
            const addMargin = idx !== 0;
            const onClick = (evt) => {
              if (evt.target.id !== 'flipCard') {
                if (id === currentPriceId) {
                  setSubscriptionId('');
                } else {
                  setSubscriptionId(stripeProductPriceId);
                }
              }
            };
            const isSelected = currentPriceId === stripeProductPriceId;

            const { promoValidated, productPriceId } = promoData;
            const promoCanBeApplied =
              promoValidated && stripeProductPriceId === productPriceId;
            const formattedRestProps = {
              ...language,
              ...restProps,
              id,
              name,
              price,
            };

            const languageFront =
              language && language.front ? language.front : {};
            const hasLanguage = Object.keys(languageFront).length !== 0;

            return hasLanguage ? (
              <TwoSidedCard
                key={`${name}-${idx}`}
                addMargin={addMargin}
                className={`hover:border-purple-light ${
                  isSelected && 'border-purple-light'
                } w-264`}
                icon={logoDictionary[avatar]}
                id={id}
                isSelected={isSelected}
                onClick={onClick}
                promo={{ promoCanBeApplied, ...promoData }}
                title={name}
                type='subscription'
                {...formattedRestProps}
              />
            ) : (
              ''
            );
          }
        )}
      </div>
      {currentPriceId && (
        <>
          <div className='flex pt-20 w-full justify-center content-center sm:pt-40 '>
            <span>
              <p className='w-full text-center px-40 text-12 sm:text-16 sm:text-left sm:w-450 sm:px-10'>
                <b>Android user? Stay tuned!</b> Encantos is available only for
                iOS devices in the U.S. Android app & more countries coming
                soon!
              </p>
            </span>
          </div>

          <div className='flex justify-center lg:justify-end mt-40'>
            {!configConfirmButton && (
              <InternalButton
                priceId={currentPriceId}
                id='select-plan-button'
                linkClassName='bg-purple-light px-45 py-19'
                name='Start Your Free Trial'
                path={`${
                  isLoggedIn
                    ? '/subscribe/checkout'
                    : '/subscribe/create-account'
                }`}
                pClassName='h-auto'
              />
            )}
            {configConfirmButton && (
              <button
                className='bg-purple-light px-45 py-19 epp-hover rounded-large text-white cursor-pointer flex font-black h-50 items-center w-max md:text-lg'
                onClick={() => configConfirmButton.action()}
                type='button'
              >
                {configConfirmButton.title}
              </button>
            )}
          </div>
        </>
      )}
    </>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    setSubscriptionId: (priceId) => dispatch(setPriceId(priceId)),
    requestGTM: (payload) => dispatch(requestGTMEvent(payload)),
  };
}

function mapStateToProps({
  user: {
    authToken,
    activeSubscriptions,
    priceId,
    checkout: {
      discount,
      minimum_amount,
      promo,
      promoValidated,
      type,
      product_id,
    },
  },
}) {
  const promoData = {
    code: promo,
    discount,
    minAmount: minimum_amount,
    productPriceId: product_id,
    promoValidated,
    type,
  };

  return {
    activeSubscriptions,
    currentPriceId: priceId,
    isLoggedIn: !!authToken,
    promoData,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PlanDetails);
