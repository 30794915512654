import React from 'react';

import ContentCard from '../../components/ContentCard';
import Huzzah from '../../assets/huzzah.svg';
import InternalButton from '../../components/InternalButton';

export default function Success() {
  const subTitleComponent = (
    <>
      <p className='text-16px font-black text-center'>
        Your information has been updated!
      </p>
      <p className='text-16px text-center'>
        Click below to acccess your account:
      </p>
    </>
  );

  return (
    <ContentCard
      className='md:h-472 md:w-552'
      iconProps={{ altText: 'party popper', icon: Huzzah }}
      subTitleComponent={subTitleComponent}
      title='Huzzah!'
    >
      <InternalButton
        linkClassName='bg-purple-light px-26 py-2 mt-30 md:px-19 md:py-4'
        name='Log In'
        path='/login'
      />
    </ContentCard>
  );
}
