import React from 'react';

import SkeletinaPng from '../../../assets/backgrounds/characters/skeletina.png';
import SenorTickTockPng from '../../../assets/backgrounds/characters/senor_tick_tock.png';
import KikiPng from '../../../assets/backgrounds/characters/kiki.png';
import BenjiPng from '../../../assets/backgrounds/characters/benji.png';
import TinyTravelersPng from '../../../assets/backgrounds/characters/tiny_travelers.png';
import PinPonPng from '../../../assets/backgrounds/characters/pin_pon.png';

export default function LoginCharacters() {
  return (
    <div className='flex justify-between px-85'>
      <img
        className= 'h-72 w-72 mt-45'
        src={SkeletinaPng}
      />
      <img
        className= 'h-80 w-80 mt-155'
        src={TinyTravelersPng}
      />
      <img
        className= 'h-86 w-86 mt-68'
        src={KikiPng}
      /> 
      <img
        className='h-92 w-92 mt-128'
        src={SenorTickTockPng}
      />
      <img
        className='h-103 w-103 mt-25'
        src={BenjiPng}
      />
      <img
        className='h-67 w-67 mt-160'
        src={PinPonPng}
      />
    </div>
  );
}
