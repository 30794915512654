import React from 'react';

import ModalBase from '../../components/modals/ModalBase';
import InternalButton from '../../components/InternalButton';

export default function UpgradePlanLandingModal({
  isMobile,
  isYearly,
  subscriptionType,
}) {
  const isYearlyText = (
    <p className='text-20 text-purple-dark text-center mb-40'>
      Click{' '}
      <a
        className='epp-hover text-purple-dark'
        href='https://encantos.helpshift.com/hc/en/3-encantos/section/6-billing/'
        rel='noreferrer'
        target='_blank'
      >
        here
      </a>{' '}
      to contact customer service to change your subscription from yearly to
      monthly.
    </p>
  );
  const isMobileText = (
    <p className='text-20 text-purple-dark text-center mb-40'>
      Please make any changes to your subscription in the{' '}
      {subscriptionType === 'ios' ? 'App' : 'Play'} Store
    </p>
  );
  return (
    <ModalBase
      isOpen={isYearly || isMobile}
      containerClassName='w-full h-573 flex flex-wrap content-center justify-center sm:w-2/3 h-602 md:w-552 bg-white rounded-3xl p-10 relative'
    >
      <div className='flex flex-col items-center'>
        {isMobile ? isMobileText : isYearlyText}
        <div className='flex'>
          <InternalButton
            linkClassName='bg-purple-light px-45 py-19'
            name='Back to Manage Subscription'
            path='/parent-portal/manage-subscription'
            pClassName='h-auto'
          />
        </div>
      </div>
    </ModalBase>
  );
}
