import React from 'react';
import PurchaseItem from './PurchaseItem';

const PurchaseList = ({ items = [], addItemToCart }) => {
  const GIFT_ITEM = {
    title: 'Gift product',
    image:
      'https://cdn.shopify.com/s/files/1/0506/0633/products/giftcard_x600.jpg?v=1571265997',
    description: 'Gift one of our products to your friend',
    type: 'GIFT',
    giftItems: items,
    addItemToCart,
  };

  return (
    <div className='purchase-list'>
      <h2>Products list</h2>
      <div className='products-lsit'>
        {items.map((item, idx) => (
          <PurchaseItem key={idx} {...item} addItemToCart={addItemToCart} />
        ))}
        <PurchaseItem {...GIFT_ITEM} />
      </div>
    </div>
  );
};

export default PurchaseList;
