import React from 'react';
import { Link } from 'react-router-dom';

const DesktopButton = ({ buttonPath, buttonText }) => {
  return (
    <Link
      className='flex items-center justify-center epp-hover hidden sm:inline-block h-56 w-173 text-white text-16 font-bold bg-purple-light rounded-large'
      to={buttonPath}
    >
      {buttonText}
    </Link>
  );
};

function PPHeader({
  title,
  description,
  buttonPath,
  buttonText,
  loading = false,
  hasSubscription,
}) {
  return (
    <div className='flex w-full px-15 pt-15 pb-15 header'>
      <div className='w-full max-w-400 '>
        <p className='mb-10 leading-normal w-full text-32 font-bold capitalize'>
          {title}
        </p>
        <p className='sm:w-3/4 text-16'>{description}</p>
      </div>

      {hasSubscription && buttonPath && !loading && (
        <DesktopButton buttonPath={buttonPath} buttonText={buttonText} />
      )}
    </div>
  );
}

export default PPHeader;
