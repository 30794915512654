import React from 'react';
import CTA from '../../CTA';
import BackButton from '../../BackButtons/BackButton';
import { connect } from 'react-redux';
import { requestGTMEvent } from '../../../store/redux/actions';
const creditCardChargedText =
  'Your credit card will be charged when you click Subscribe Now.';
const autoRenewalText = 'your membership will renew automatically.';
const subscriptionEndText = 'your membership will end.';

function Details({ disabled, trial, paymentRequired: paymentRequired }) {
  const trialText = () => {
    let response;
    if (trial.type === 'life') {
      response = 'Your subscription is free for life.';
    } else {
      response = `After the FREE ${trial.length} ${
        trial.period
      } trial period, ${
        paymentRequired
          ? `${autoRenewalText} ${creditCardChargedText}`
          : subscriptionEndText
      }`;
    }
    return response;
  };
  return (
    <div className='flex flex-col mt-16 lg:min-h-225 lg:mt-0 justify-end lg:pl-25 max-w-430'>
      <p className='px-15 lg:px-0 text-grayText'>{trialText()}</p>
      <div className='flex flex-wrap mb-10 mt-20 px-15 lg:px-0'>
        <p className='text-grayText'>
          You can cancel your subscription at any time, see
          <a
            className='text-purple-dark epp-hover mx-3'
            href='https://encantos.helpshift.com/hc/en/'
            rel='noreferrer'
            target='_blank'
          >
            here
          </a>
          for more information.
        </p>
      </div>
      <div className='flex justify-between md:justify-end'>
        <BackButton
          className='font-black'
          path='/subscribe/plan-details'
          containerClassName='md:hidden'
          name='Back'
        />
        <CTA
          id='subscribe-button'
          disabled={disabled}
          buttonClassName={`${
            disabled
              ? 'bg-gray-light border-gray-light'
              : 'bg-purple-light border-purple-light'
          } flex justify-center items-center h-56 rounded-2xl min-w-fitcontent`}
          name='Subscribe Now!'
          textClassName='text-16 font-extrabold text-white'
          type='submit'
          form='subscriptionForm'
        />
      </div>
    </div>
  );
}
function mapDispatchToProps(dispatch) {
  return {
    requestGTM: (payload) => dispatch(requestGTMEvent(payload)),
  };
}

export default connect(null, mapDispatchToProps)(Details);
