import React from 'react';

export default function ForgotCardFooter({ link, linkText, text, title }) {
  return (
    <div className='flex flex-col items-center'>
      <h2 className='font-black mb-7 text-center'>{title}</h2>
      <div className='flex flex-wrap justify-center text-center'>
        <p className='text-16'>{text}</p>
        {link && (
          <a
            className='epp-hover text-16 ml-5'
            href={link}
            rel='noreferrer'
            target='_blank'
          >
            {linkText}
          </a>
        )}
      </div>
    </div>
  );
}
