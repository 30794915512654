export const BREADCRUMB_DICTIONARY = {
  '/forgot-password': 'Forgot Password',
  '/home': `Parent's Portal / Child Profile`,
  '/parent-portal': `Parent's Portal / Child Profile`,
  '/parent-portal/child-profile': `Parent's Portal / Child Profile`,
  '/parent-account': `Parent's Portal / Parent Account`,
  '/parent-portal/parent-account': `Parent's Portal / Parent Account`,
  '/parent-portal/manage-subscription': `Parent's Portal / Manage Subscription`,
  '/parent-portal/upgrade-plan': `Parent's Portal / Upgrade Plan`,
  '/parent-portal/refer-a-friend': `Parent's Portal / Parent Account`,
  '/login': 'Sign In',
  '/subscribe/checkout': 'Subscription',
  '/subscribe/plan-details': 'Subscription',
  '/subscribe/create-account': 'Subscription',
  '/subscribe/success': 'Subscription',
  '/shop': 'Shop',
};
