import React from 'react';

import CTA from '../../components/CTA';
import ContentCard from '../../components/ContentCard';
import ChangeInbox from '../../assets/purple_inbox.svg';
import FormInput from '../../components/FormInput';
import Error from '../../components/Error';
import InputWithToggle from '../../components/InputWithToggle';

export default function ChangePassword({
  email,
  errMessage,
  handleChange,
  newPassword,
  newPasswordConfirm,
  onSubmitResetPassword,
  verificationCode,
}) {
  const changePWSubtitle = (
    <div className='text-16px font-medium text-center'>
      <p>
        A verification code has been sent to{' '}
        <span className='font-black'>{email || 'your email'}</span>. Please check your spam folder if
        you don&rsquo;t see a verification email inside your inbox. Please enter that code below:
      </p>
    </div>
  );

  return (
    <ContentCard
      cardFooterProps={{
        text: 'Try checking your junk or spam folder.',
        title: `Didn't receive the email?`,
      }}
      cardFooterType='forgot'
      className='md:w-552'
      iconProps={{ altText: 'envelope with at symbol', icon: ChangeInbox }}
      subTitleComponent={changePWSubtitle}
      title={['Almost there!', 'Check your inbox!']}
    >
      <form
        className='flex flex-col items-center mt-30 w-full'
        onSubmit={onSubmitResetPassword}
      >
        <FormInput
          className='bg-transparent border h-56 mb-30 pl-20 text-16px w-full'
          type='text'
          placeholder='verification code'
          name='verificationCode'
          value={verificationCode}
          handleChange={handleChange}
        />
        <InputWithToggle
          placeholder='new password'
          name='newPassword'
          value={newPassword}
          handleChange={handleChange}
          className='h-56 mb-30 border pl-20 pr-45 border-light-grey bg-transparent text-16px '
          containerClassName='relative w-full'
          iconClassName='text-2xl text-purple-light'
          iconContainerClassName='top-20 right-20'
        />
        <InputWithToggle
          placeholder='confirm password'
          name='newPasswordConfirm'
          value={newPasswordConfirm}
          handleChange={handleChange}
          className='h-56 mb-30 border pl-20 pr-45 border-light-grey bg-transparent text-16px '
          containerClassName='relative w-full'
          iconClassName='text-2xl text-purple-light'
          iconContainerClassName='top-20 right-20'
        />
        {errMessage && (
          <div className='mb-19'>
            <Error error={errMessage} />
          </div>
        )}
        <CTA
          buttonClassName={`min-h-56 rounded-2xl bg-purple-light text-white`}
          textClassName='text-16px font-extrabold capitalize text-white'
          name='Change Password'
          type='submit'
        />
      </form>
    </ContentCard>
  );
}
