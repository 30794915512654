import React from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { useHistory } from 'react-router';

import CTA from '../../../components/CTA';
import CreditCard from '../../../assets/miscIcons/red_creditcard.svg';
import Radio from '../../../assets/miscIcons/radio.svg';
import FormInput from '../../../components/FormInput';
import StripeCardElement from '../../../components/StripeCardElement';
import { formatPrice } from '../../../formatters';

export default function GiftForm({
  createGift,
  error,
  gift: {
    gifterEmail,
    gifterName,
    emailNotification,
    giftPurchasePrice,
    nameOnCard,
    paymentIntentData = {},
    recipientEmail,
    recipientName,
    termsAndPrivacy,
  },
  handleChange,
  setError,
}) {
  const history = useHistory();

  const environment = process.env.REACT_APP_STAGE;
  const isProd = environment === 'prod';
  const s3UrlPrefix = 'https://parents-content';
  const s3UrlSuffix = '.encantosworld.com';

  const src = `${s3UrlPrefix}${!isProd ? `.${environment}` : ''}${s3UrlSuffix}`;

  const stripe = useStripe();
  const elements = useElements();

  const setPaymentMethod = async () => {
    const card = elements.getElement(CardElement);
    return stripe
      .createPaymentMethod({
        type: 'card',
        card: card,
        billing_details: {
          name: nameOnCard,
        },
      })
      .catch((error) => ({ error }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { authToken } = paymentIntentData;
    const requiredInputs = [
      gifterEmail,
      gifterName,
      nameOnCard,
      recipientEmail,
      recipientName,
      termsAndPrivacy,
    ];
    const isFormComplete = requiredInputs.reduce((acc, cv) => {
      if (!acc) return false;
      if (!cv) return false;
      return true;
    }, true);

    if (isFormComplete) {
      const { error } = await setPaymentMethod();
      if (error) {
        setError(error.message);
      } else {
        createGift({
          authToken,
          gifterEmail,
          gifterName,
          emailNotification,
          giftPurchasePrice,
          history,
          recipientEmail,
          recipientName,
        });
      }
    } else {
      setError('Please complete all form fields below.');
    }
  };

  return (
    <div className='flex flex-col w-full'>
      <img
        alt='Gift a 1-year subscription to the encantos app'
        className='xs:hidden md:block bg-teal-bright h-428 mb-35 w-full'
        src={`${src}/gifting/gift_form_header_desktop.svg`}
      />
      <img
        alt='Gift a 1-year subscription to the encantos app'
        className='md:hidden bg-teal-bright h-428 mb-35 w-full'
        src={`${src}/gifting/gift_form_header_mobile.svg`}
      />
      <div className='flex flex-col lg:flex-row justify-center'>
        <div className='text-center lg:text-left lg:mr-10 lg:max-w-278'>
          <div className='flex mb-10 px-15 lg:px-0'>
            <p className='text-16'>
              <span className='mr-3 text-purple-light text-16'>
                Give the world of Encantos,
              </span>
              where stories come to life and make learning fun! Surprise them
              with a year of games, books, videos, and more for{' '}
              {formatPrice(giftPurchasePrice)}.
            </p>
          </div>
          <p className='text-12'>
            Available only in the U.S. for Apple iPhones and iPads.
          </p>
        </div>
        <div className='mx-10 relative flex flex-col items-center'>
          <form
            id='giftForm'
            className='lg:w-840 bg-background flex flex-col items-center justify-center rounded-xlarge overflow-hidden shadow-encantosShadow mb-16 text-16 p-30'
            onSubmit={handleSubmit}
          >
            {error && (
              <p className='mt-10 text-red-600'>{error.message || error}</p>
            )}
            <>
              <div className='flex flex-col lg:flex-row items-center mb-20 w-full'>
                <FormInput
                  className='bg-transparent border w-full h-56 pl-20 mb-30 lg:mb-0 text-16 sm:w-360 rounded-xl p-15'
                  handleChange={handleChange}
                  name='recipientName'
                  placeholder='Recipient Name'
                  type='text'
                  value={recipientName}
                />
                <FormInput
                  className='bg-transparent border w-full h-56 lg:ml-10 pl-20 text-16 sm:w-360 rounded-xl p-15'
                  handleChange={handleChange}
                  name='recipientEmail'
                  placeholder='Recipient Email'
                  type='text'
                  value={recipientEmail}
                />
              </div>
              <div className='flex flex-col lg:flex-row items-center w-full'>
                <FormInput
                  className='bg-transparent border w-full mb-30 lg:mb-0 h-56 pl-20 text-16 sm:w-360 rounded-xl p-15'
                  handleChange={handleChange}
                  name='gifterName'
                  placeholder='Your Name'
                  type='text'
                  value={gifterName}
                />
                <FormInput
                  className='bg-transparent border w-full h-56 mb-30 lg:mb-0 lg:ml-10 pl-20 text-16 sm:w-360 rounded-xl p-15'
                  handleChange={handleChange}
                  name='gifterEmail'
                  placeholder='Your Email'
                  type='text'
                  value={gifterEmail}
                />
              </div>
              <div className='flex justify-between mt-20 w-full'>
                <div className='flex items-center'>
                  <img alt='radio button icon' className='mr-12' src={Radio} />
                  <p className='font-black text-purple-dark text-16'>
                    Credit or Debit Card
                  </p>
                </div>
                <img alt='credit card icon' src={CreditCard} />
              </div>
              <div className='flex flex-col lg:flex-row items-center mt-30 w-full'>
                <FormInput
                  className='bg-transparent border mr-10 mb-30 w-full h-56 pl-20 text-16 sm:w-360 rounded-xl p-15'
                  handleChange={handleChange}
                  name='nameOnCard'
                  placeholder='Name on Card'
                  type='text'
                  value={nameOnCard}
                />
                <StripeCardElement setError={setError} />
              </div>
            </>
            <div className='flex flex-col items-left w-full'>
              <div className='flex items-center max-w-fitcontent'>
                <input
                  className='mx-5 mt-auto mb-auto'
                  name='emailNotification'
                  type='checkbox'
                  onChange={handleChange}
                  defaultChecked={true}
                />
                <label className='mx-auto md:mt-0 text-14'>
                  Keep me posted on the newest storyworlds, learning tips, and
                  more with emails from Encantos
                </label>
              </div>
              <div className='flex items-center max-w-fitcontent justify-center'>
                <input
                  id='termsInputBox'
                  className='mx-5 mt-auto mb-auto'
                  name='termsAndPrivacy'
                  type='checkbox'
                  onChange={handleChange}
                />
                <label className='mx-auto md:mt-0 text-14'>
                  I have read and agree to the
                  <a
                    className='text-purple-dark epp-hover mx-3'
                    href='https://encantosworld.com/terms-of-use'
                    rel='noreferrer'
                    target='_blank'
                  >
                    Terms of Service
                  </a>
                  and
                  <a
                    className='text-purple-dark epp-hover mx-3'
                    href='https://encantosworld.com/privacy-policy'
                    rel='noreferrer'
                    target='_blank'
                  >
                    Privacy Policy
                  </a>
                </label>
              </div>
            </div>
          </form>
          <div className='flex flex-col justify-between lg:flex-row lg:w-840'>
            <div className='bg-teal-light flex flex-col min-h-139 justify-center px-37 rounded-xlarge lg:w-400'>
              <p className='mb-24 text-16'>Order Summary</p>
              <div className='flex justify-between'>
                <p>Total due today</p>
                <p>{formatPrice(giftPurchasePrice)}</p>
              </div>
            </div>
            <div className='flex flex-col pl-20'>
              <div className='flex flex-wrap mb-10 px-15 lg:px-0'>
                <p className='text-grayText'>
                  You can cancel your subscription at any time, see
                  <a
                    className='text-purple-dark epp-hover mx-3'
                    href='https://encantos.helpshift.com/hc/en/'
                    rel='noreferrer'
                    target='_blank'
                  >
                    here
                  </a>
                  for more information.
                </p>
              </div>
              <CTA
                id='giftButton'
                buttonClassName='bg-giftRed border-purple-light h-56 rounded-2xl self-end text-white w-145 epp-hover'
                form='giftForm'
                name='Order Now!'
                textClassName='text-16 font-extrabold text-white'
                type='submit'
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
