import React from 'react';

export default function SocialCircle({ altText, href, svg, missingMargin, type }) {
  return (
    <a
      className={`flex ${type === 'header' ? 'border-2 border-solid border-purple-light' : 'bg-white hover:bg-lightGray'} h-30 items-center justify-center ${
        !missingMargin ? 'mr-8' : ''
      } rounded-full text-black text-center w-30`}
      href={href}
      rel='noreferrer'
      target='_blank'
    >
      <img className="flex items-center justify-center text-center" alt={altText} src={svg} />
    </a>
  );
}
