import React from 'react';

import StepCircle from './StepCircle';
import StepComplete from './StepComplete';
import StepLink from './StepLink';
import { subscriptionFlow } from './constants';

export default function Nav({ currentStep, isLoggedIn }) {
  return (
    <div className='flex md:flex-col pb-20 md:pb-0 md:pr-70 md:pt-25 md:self-start'>
      {subscriptionFlow.map(({ route, title }, index) => {
        const step = index + 1;
        const cannotEditStep = step === 1 && isLoggedIn;
        const isDisabled = step > currentStep;
        const isComplete =
          (!isDisabled && step !== currentStep) || cannotEditStep;
        const path = `/subscribe/${route}`;

        return (
          <div key={`${title}-${index}`} className='flex md:flex-col'>
            {step !== 1 && (
              <div
                className={`border-t-1.5 md:border-l-1.5 border-solid ${
                  isDisabled ? 'border-gray-light' : 'border-purple-light'
                } md:ml-15 mt-15 md:mt-0 md:h-100 w-30 md:w-0`}
              />
            )}
            <div className='flex'>
              {isComplete ? (
                <StepComplete route={path} step={step} />
              ) : (
                <StepCircle isDisabled={isDisabled} route={path} step={step} />
              )}
              <StepLink
                cannotEditStep={cannotEditStep}
                isDisabled={isDisabled}
                route={route}
                title={title}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
}
