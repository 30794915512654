import React from 'react';
import { useHistory } from 'react-router';
import InlineButton from '../InlineButton';
import clsx from 'clsx';

function BackButton({ name, className, containerClassName, path }) {
  const history = useHistory();
  const backPath = () => history.push(path);

  const defaultClassNames =
    'bg-white border-2 border-purple-light border-solid px-26 min-h-56 rounded-2xl epp-hover text-purple-light';
  const ClassName = clsx(className, defaultClassNames);
  return (
    <div className={`${containerClassName}`}>
      <InlineButton handleClick={backPath} className={ClassName} name={name} />
    </div>
  );
}

export default BackButton;
