import React from 'react';

import TinyTravelersPng from '../../../assets/backgrounds/characters/tiny_travelers.png';
import NickyPng from '../../../assets/backgrounds/characters/nicky.png';

export default function PlanDetailsChars() {
  return (
    <>
      <img
        className='absolute hidden h-79 w-79 md:block'
        src={NickyPng}
        style={{
          bottom: '51%',
          left: 'calc(50vw - 430px)',
        }}
      />
      <img
        className='absolute hidden md:block h-135 w-135'
        src={TinyTravelersPng}
        style={{
          bottom: '44.7%',
          left: 'calc(50vw - 570px)',
        }}
      />
    </>
  );
}
