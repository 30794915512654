const defaultMessage = 'An internal error occurred.';
const defaultListFormatter = (input) => input;
const defaultListItemFormatter = (input) => input || defaultMessage;

const getErrorMessage = (rawString) => {
  const inputStr = String(rawString);

  if (inputStr.length <= 50) {
    return inputStr;
  }

  return `${inputStr.slice(0, 47)}...`;
};

const formatList = (errorList, config) => {
  const listFormatter = config.listFormatter || defaultListFormatter;
  const listItemFormatter =
    config.listItemFormatter || defaultListItemFormatter;

  return {
    messageType: 'list',
    message: listFormatter(
      errorList.map((errorListItem) =>
        listItemFormatter(getErrorMessage(errorListItem.message))
      )
    ),
  };
};

const isMessageList = (error) =>
  error.messageType && error.messageType === 'list';

const formatError = (rawError, config) => {
  if (!rawError || !rawError.message) {
    return { message: defaultMessage, messageType: 'single' };
  }

  if (!isMessageList(rawError)) {
    return {
      message: getErrorMessage(rawError.message),
      messageType: 'single',
    };
  }

  return formatList(rawError.message, config);
};

const AppError = (rawError, config = {}) => {
  const error = rawError && rawError.error ? rawError.error : rawError;

  return {
    format: () => formatError(error, config),
  };
};

export default AppError;
