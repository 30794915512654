import React from 'react';

import useModal from '../../hooks/useModal';
import ProfileModal from './ProfileModal';
import ProfileInitials from './ProfileInitals';

export default function ProfileButton({ firstName, initials, logoutUser }) {
  const { showModal, setShowModal } = useModal(false);

  return (
    <>
      <button
        className='flex items-center justify-center epp-hover text-white rounded-full h-40 w-40'
        onClick={setShowModal}
      >
        <ProfileInitials initials={initials} />
      </button>
      <ProfileModal
        firstName={firstName}
        initials={initials}
        setShowModal={setShowModal}
        showModal={showModal}
        logoutUser={logoutUser}
      />
    </>
  );
}
