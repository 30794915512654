import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import useForm from '../hooks/useForm';
import FormInput from '../components/FormInput';

export default function QRCode() {
  const [pngImage, setPngImage] = useState('');
  const { values, handleChange } = useForm({
    initialValues: {
      qrSource: '',
    },
  });
  const baseUrl = process.env.REACT_APP_BASE_API_URL;
  const getQRCode = async (event) => {
    event.preventDefault();
    const getUrl = `${baseUrl}/lambs`;

    axios
      .post(getUrl, {
        qrBase: values.qrSource,
      })
      .then(function (response) {
        const imgSrc = response.data.substring(19);
        setPngImage(imgSrc.substring(0, imgSrc.length - 2) + 'C');
      })
      .catch(function (error) {});
  };

  return (
    <div className='page'>
      <h3 className='page__body'>
        This is a private page, only accessible to logged in users.
      </h3>
      <br />
      <br />
      <br />
      <p></p>
      <br />
      <p></p>
      <h3>Generate QR CODE</h3>
      <form onSubmit={getQRCode}>
        <FormInput
          type={'text'}
          placeholder={'QR Source URL'}
          name={'qrSource'}
          value={values.qrSource}
          handleChange={handleChange}
        />
        <div className='inlineForm__submit'></div>
        <button type='submit'>Generate QR Code</button>
      </form>
      <p></p>
      {pngImage ? <img src={`data:image/png;base64,${pngImage}`} /> : ''}
    </div>
  );
}
