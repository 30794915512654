import {
  HANDLE_INPUT_CHANGE,
  REQUEST_SUBSCRIPTION_DETAILS,
  RECEIVED_SUBSCRIPTION_DETAILS,
  RECEIVED_SUBSCRIPTION_DETAILS_FAILED,
  REQUEST_UPDATE_PAYMENT_METHOD,
  RECEIVED_UPDATE_PAYMENT_METHOD,
  RECEIVED_UPDATE_PAYMENT_METHOD_FAILED,
  REQUEST_SET_ERROR,
  REQUEST_RESET_ERROR,
  SET_SHOW_UPDATE_PAYMENT_METHOD,
  SET_LOADING,
  SET_PRICE_ID,
  REQUEST_CREATE_SUBSCRIPTION,
  RECEIVED_CREATE_SUBSCRIPTION,
  RECEIVED_CREATE_SUBSCRIPTION_FAILED,
  REQUEST_PARENT,
  RECEIVED_PARENT,
  RECEIVED_PARENT_FAILED,
  REQUEST_ADD_CHILD,
  RECEIVED_ADD_CHILD_FAILED,
  RECEIVED_ADD_CHILD,
  REQUEST_UPDATE_ACCOUNT,
  RECEIVED_UPDATE_ACCOUNT,
  RECEIVED_UPDATE_ACCOUNT_FAILED,
  REQUEST_ACTIVE_SUBSCRIPTIONS,
  RECEIVED_ACTIVE_SUBSCRIPTIONS,
  RECEIVED_ACTIVE_SUBSCRIPTIONS_FAILED,
  SET_FORM_ERROR,
  SET_UPDATE_PLAN_ID,
  SET_USER_DATA,
  REQUEST_UPDATE_CHILD,
  RECEIVED_UPDATE_CHILD_FAILED,
  RECEIVED_UPDATE_CHILD,
  REFER_A_FRIEND,
  RECEIVED_REFER_A_FRIEND,
  RECEIVED_REFER_A_FRIEND_FAILED,
  RESET_PROMO_SENT,
  VALIDATE_PROMO_CODE_SUCCESS,
  REQUEST_VALIDATE_PROMO_CODE,
  VALIDATE_PROMO_CODE_FAILURE,
  RESET_PROMO_ERROR,
  RESET_PROMO_VALIDATION,
  CLEAR_PROMO_CODE,
  SET_PROMO,
  REQUEST_UPDATE_PLAN,
  RECEIVED_UPDATE_PLAN,
  RECEIVED_UPDATE_PLAN_FAILED,
  REQUEST_CANCEL_SUBSCRIPTION,
  RECEIVED_CANCEL_SUBSCRIPTION,
  RECEIVED_CANCEL_SUBSCRIPTION_FAILED,
  REQUEST_DELETE_CHILD,
  RECEIVED_DELETE_CHILD_FAILED,
  RECEIVED_DELETE_CHILD,
  REQUEST_LOGIN,
  REQUEST_LOGOUT,
  RECEIVED_LOGIN,
  RECEIVED_LOGOUT,
  RECEIVED_LOGIN_FAILED,
  RECEIVED_LOGOUT_FAILED,
  REQUEST_USER_SESSION,
  RECEIVED_USER_SESSION,
  REQUEST_CREATE_ACCOUNT,
  REQUEST_ACCOUNT_CREATED,
  REQUEST_GTM,
  REQUEST_RESET_STATE,
  REQUEST_RESET_SPINNER,
  REQUEST_SET_SPINNER,
  REQUEST_RESET_SUBSCRIPTION_STATUS,
  REQUEST_PAYMENT_INTENT,
  RECEIVED_PAYMENT_INTENT,
  REQUEST_GIFT_PROMO_CODE,
  RECEIVED_GIFT_PROMO_CODE,
  REQUEST_GIFT_PDF,
  RECEIVED_GIFT_PDF,
  RECEIVED_GIFT_PDF_FAILED,
} from './constants';

export function handleInputChange(payload) {
  return {
    type: HANDLE_INPUT_CHANGE,
    payload: payload,
  };
}

export function setLoading(loading) {
  return {
    type: SET_LOADING,
    payload: loading,
  };
}

export function setUserData(payload) {
  return {
    type: SET_USER_DATA,
    payload: payload,
  };
}

export function showUpdatePaymentMethod(payload) {
  return {
    type: SET_SHOW_UPDATE_PAYMENT_METHOD,
    payload: payload,
  };
}
export function requestUpdatePaymentMethod(payload) {
  return {
    type: REQUEST_UPDATE_PAYMENT_METHOD,
    payload: payload,
  };
}

export function receivedUpdatePaymentMethod(paymentMethod) {
  return {
    type: RECEIVED_UPDATE_PAYMENT_METHOD,
    payload: paymentMethod,
  };
}

export function receivedUpdatePaymentMethodFailed(error) {
  return {
    type: RECEIVED_UPDATE_PAYMENT_METHOD_FAILED,
    payload: error,
  };
}

export function requestSubscriptionDetails() {
  return {
    type: REQUEST_SUBSCRIPTION_DETAILS,
  };
}

export function receivedSubscriptionDetailsFailed(error) {
  return {
    type: RECEIVED_SUBSCRIPTION_DETAILS_FAILED,
    payload: error,
  };
}

export function receivedSubscriptionDetails(subscription) {
  return {
    type: RECEIVED_SUBSCRIPTION_DETAILS,
    payload: subscription,
  };
}

export function setErrorMessage(error) {
  return {
    type: REQUEST_SET_ERROR,
    payload: error,
  };
}

export function resetErrorMessage(payload) {
  return {
    type: REQUEST_RESET_ERROR,
    payload,
  };
}

export function setFormError(payload) {
  return {
    type: SET_FORM_ERROR,
    payload,
  };
}

export function setPriceId(priceId) {
  return {
    type: SET_PRICE_ID,
    payload: priceId,
  };
}

export function requestCreateSubscription(payload) {
  return {
    type: REQUEST_CREATE_SUBSCRIPTION,
    payload: payload,
  };
}

export function receivedCreateSubscription(subscription) {
  return {
    type: RECEIVED_CREATE_SUBSCRIPTION,
    payload: subscription,
  };
}

export function receivedCreateSubscriptionFailed(error) {
  return {
    type: RECEIVED_CREATE_SUBSCRIPTION_FAILED,
    payload: error,
  };
}

export function requestCancelSubscription() {
  return {
    type: REQUEST_CANCEL_SUBSCRIPTION,
  };
}

export function receivedCancelSubscription(subscription) {
  return {
    type: RECEIVED_CANCEL_SUBSCRIPTION,
    payload: subscription,
  };
}

export function receivedCancelSubscriptionFailed(error) {
  return {
    type: RECEIVED_CANCEL_SUBSCRIPTION_FAILED,
    payload: error,
  };
}
export function requestParent() {
  return {
    type: REQUEST_PARENT,
  };
}

export function receivedParentFailure(error) {
  return {
    type: RECEIVED_PARENT_FAILED,
    payload: error,
  };
}

export function receivedParentSuccess(payload) {
  return {
    type: RECEIVED_PARENT,
    payload,
  };
}

export function requestAddChild(payload) {
  return {
    type: REQUEST_ADD_CHILD,
    payload,
  };
}

export function receivedAddChildFailure(error) {
  return {
    type: RECEIVED_ADD_CHILD_FAILED,
    payload: error,
  };
}

export function receivedAddChildSuccess(payload) {
  return {
    type: RECEIVED_ADD_CHILD,
    payload,
  };
}

export function requestUpdateChild(Payload) {
  return {
    type: REQUEST_UPDATE_CHILD,
    Payload,
  };
}

export function receivedUpdateChildFailure(error) {
  return {
    type: RECEIVED_UPDATE_CHILD_FAILED,
    payload: error,
  };
}

export function receivedUpdateChildSuccess(Payload) {
  return {
    type: RECEIVED_UPDATE_CHILD,
    payload: Payload,
  };
}

export function requestDeleteChild(payload) {
  return {
    type: REQUEST_DELETE_CHILD,
    payload,
  };
}

export function receivedDeleteChildFailure(error) {
  return {
    type: RECEIVED_DELETE_CHILD_FAILED,
    payload: error,
  };
}

export function receivedDeleteChildSuccess(payload) {
  return {
    type: RECEIVED_DELETE_CHILD,
    payload,
  };
}

export function requestUpdateAccount(payload) {
  return {
    type: REQUEST_UPDATE_ACCOUNT,
    payload,
  };
}

export function receivedUpdateAccountSuccess(payload) {
  return {
    type: RECEIVED_UPDATE_ACCOUNT,
    payload,
  };
}

export function receivedUpdateAccountFailure(payload) {
  return {
    type: RECEIVED_UPDATE_ACCOUNT_FAILED,
    payload,
  };
}

export function referAFriend(payload) {
  return {
    type: REFER_A_FRIEND,
    payload,
  };
}

export function receivedReferAFriend() {
  return {
    type: RECEIVED_REFER_A_FRIEND,
  };
}

export function receivedReferAFriendFailed(payload) {
  return {
    type: RECEIVED_REFER_A_FRIEND_FAILED,
    payload,
  };
}

export function resetPromoSent() {
  return {
    type: RESET_PROMO_SENT,
  };
}

export function requestActiveSubscriptions() {
  return {
    type: REQUEST_ACTIVE_SUBSCRIPTIONS,
  };
}

export function receivedActiveSubscriptionsSuccess(payload) {
  return {
    type: RECEIVED_ACTIVE_SUBSCRIPTIONS,
    payload,
  };
}

export function receivedActiveSubscriptionsFailure(error) {
  return {
    type: RECEIVED_ACTIVE_SUBSCRIPTIONS_FAILED,
    payload: error,
  };
}

export function setUpdatePlanId(payload) {
  return {
    type: SET_UPDATE_PLAN_ID,
    payload,
  };
}
export function validatePromoCode(payload) {
  return {
    type: REQUEST_VALIDATE_PROMO_CODE,
    payload,
  };
}

export function validatePromoCodeSuccess(payload) {
  return {
    type: VALIDATE_PROMO_CODE_SUCCESS,
    payload,
  };
}

export function validatePromoCodeFailure(payload) {
  return {
    type: VALIDATE_PROMO_CODE_FAILURE,
    payload,
  };
}

export function resetPromoCodeErrors() {
  return {
    type: RESET_PROMO_ERROR,
  };
}

export function clearPromoCode() {
  return {
    type: CLEAR_PROMO_CODE,
  };
}

export function setPromoCode(payload) {
  return {
    type: SET_PROMO,
    payload,
  };
}

export function resetPromoValidation() {
  return {
    type: RESET_PROMO_VALIDATION,
  };
}

export function requestUpdatePlan(payload) {
  return {
    type: REQUEST_UPDATE_PLAN,
    payload,
  };
}

export function receivedUpdatePlan(payload) {
  return {
    type: RECEIVED_UPDATE_PLAN,
  };
}

export function receivedUpdatePlanFailed(payload) {
  return {
    type: RECEIVED_UPDATE_PLAN_FAILED,
    payload,
  };
}

export function requestLogin(payload) {
  return {
    type: REQUEST_LOGIN,
    payload,
  };
}

export function requestLogout(payload) {
  return {
    type: REQUEST_LOGOUT,
    payload,
  };
}

export function receivedLogin(payload) {
  return {
    type: RECEIVED_LOGIN,
    payload,
  };
}

export function receivedLogout(payload) {
  return {
    type: RECEIVED_LOGOUT,
    payload,
  };
}

export function receivedLoginFailed(payload) {
  return {
    type: RECEIVED_LOGIN_FAILED,
    payload,
  };
}

export function receivedLogoutFailed(payload) {
  return {
    type: RECEIVED_LOGOUT_FAILED,
    payload,
  };
}

export function requestUserSession(payload) {
  return {
    type: REQUEST_USER_SESSION,
    payload,
  };
}

export function receivedUserSession(payload) {
  return {
    type: RECEIVED_USER_SESSION,
    payload,
  };
}

export function requestGTMEvent(payload) {
  return {
    type: REQUEST_GTM,
    payload,
  };
}

export function requestCreateAccount() {
  return {
    type: REQUEST_CREATE_ACCOUNT,
  };
}
export function requestAccountCreated() {
  return {
    type: REQUEST_ACCOUNT_CREATED,
  };
}

export function resetState() {
  return {
    type: REQUEST_RESET_STATE,
  };
}

export function requestSetSpinner() {
  return {
    type: REQUEST_SET_SPINNER,
  };
}

export function requestResetSpinner() {
  return {
    type: REQUEST_RESET_SPINNER,
  };
}

export function requestResetSubscriptionStatus() {
  return {
    type: REQUEST_RESET_SUBSCRIPTION_STATUS,
  };
}

export function requestPaymentIntent() {
  return {
    type: REQUEST_PAYMENT_INTENT,
  };
}

export function receivedPaymentIntent(payload) {
  return {
    type: RECEIVED_PAYMENT_INTENT,
    payload,
  };
}

export function requestGiftPromoCode(payload) {
  return {
    type: REQUEST_GIFT_PROMO_CODE,
    payload,
  };
}

export function receivedGiftPromoCode(payload) {
  return {
    type: RECEIVED_GIFT_PROMO_CODE,
    payload,
  };
}

export function requestDownloadGiftPDF(payload) {
  return {
    type: REQUEST_GIFT_PDF,
    payload,
  };
}
export function receivedDownloadGiftPDFSuccess(payload) {
  return {
    type: RECEIVED_GIFT_PDF,
    payload,
  };
}

export function receivedDownloadGiftPDFFailure(error) {
  return {
    type: RECEIVED_GIFT_PDF_FAILED,
    payload: error,
  };
}
