import React from 'react';
import clsx from 'clsx';

export default function FormInput({
  fail = false,
  handleChange,
  className,
  ...extraProps
}) {
  const defaultClassNames = `bg-white leading-6 rounded-lg ${
    fail && 'input--fail'
  }`;
  const classes = clsx('input', className, defaultClassNames);
  return <input className={classes} onChange={handleChange} {...extraProps} />;
}
