import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';

import Radio from '../../../assets/miscIcons/radio.svg';
import CreditCard from '../../../assets/miscIcons/creditcard.svg';
import StripeCardElement from '../../../components/StripeCardElement';
import Details from '../../../components/Subscribe/PaymentInfo/Details';
import FormInput from '../../../components/FormInput';
import BackToStepButton from '../../../components/BackButtons/BackToStepButton';
import OrderSummary from '../../../components/Subscribe/PaymentInfo/OrderSummary';
import Error from '../../../components/Error';
import {
  handleInputChange,
  requestCreateSubscription,
  resetErrorMessage,
  resetPromoValidation,
  setErrorMessage,
  setFormError,
  setLoading,
  validatePromoCode,
} from '../../../store/redux/actions';
import { formatDiscountPrice, formatPrice } from '../../../formatters';

const debounce = (func, timeout = 500) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
};

const verifyPromo = debounce((func, payload) => func(payload));

const PaymentForm = ({
  createSubscription,
  error,
  handleChange,
  nameOnCard,
  promo,
  selectedPriceId,
  setError,
  setPromoError,
  updateLoading,
  activeSubscriptions,
  customBackButton,
  changeInput,
  resetPromoError,
}) => {
  useEffect(() => {
    const { minAmount, paymentRequired } = promo;
    if (!paymentRequired) {
      const customClickEvent = new Event('customClickEvent', {
        bubbles: true,
        cancelable: true,
        composed: false,
      });
      handleSubmit(customClickEvent);
    }
    const planPrice = activeSubscriptions.reduce((acc, cv) => {
      if (acc) return acc;
      const { price, product_id: productId } = cv;
      if (productId === selectedPriceId) return price;
    }, undefined);
    if (minAmount >= planPrice)
      setPromoError('Promo code cannot be applied to this plan.');
  }, []);

  const planDetails = activeSubscriptions.reduce((acc, cv) => {
    if (acc) return acc;
    const { price, id, name, period, product_id: productId } = cv;
    if (productId === selectedPriceId) return { name, price, period };
  }, undefined);
  const { price, name } = planDetails || {};
  const {
    code,
    discount,
    promo_code_error,
    promoValidated,
    type,
    productPriceId,
  } = promo;
  const promoCanBeApplied =
    promoValidated && selectedPriceId === productPriceId;
  const discountPrice = formatDiscountPrice(discount, price, type);
  const stripe = useStripe();
  const elements = useElements();
  const history = useHistory();

  const trialText = () => {
    const length = promo.trialLength || 7;
    const period = promo.trialPeriod || 'day';
    const type = promo.couponType || null;
    return { length, period, type };
  };
  const setPaymentMethod = async () => {
    if (promo.paymentRequired) {
      const card = elements.getElement(CardElement);
      return stripe
        .createPaymentMethod({
          type: 'card',
          card: card,
          billing_details: {
            name: nameOnCard,
          },
        })
        .catch((error) => ({ error }));
    }
    return { paymentMethod: null, error: null };
  };

  const [buttonDisabled, setButtonDisabled] = useState(false);

  function setDisabledButton() {
    setButtonDisabled(true);
  }

  function resetDisabledButton() {
    setButtonDisabled(false);
  }

  const handleSubmit = async (event) => {
    setDisabledButton();
    event.preventDefault();
    const isMissingName = promo.paymentRequired && !nameOnCard.length;
    if (!isMissingName) {
      const { paymentMethod, error } = await setPaymentMethod();
      if (error) {
        setError(error.message);
        resetDisabledButton();
      } else {
        updateLoading(true);
        createSubscription({ paymentMethod, promo: code, history });
      }
    } else {
      setError('Name on card is a required field');
      resetDisabledButton();
    }
  };

  const backToChoosePlan = () => {
    changeInput({ name: 'promo', value: '' });
    resetPromoError();
    customBackButton.action();
  };

  return (
    <>
      {customBackButton && (
        <BackToStepButton
          className='font-bold'
          onClickAction={backToChoosePlan}
          name={customBackButton.name}
          containerClassName='hidden md:flex mb-15'
        ></BackToStepButton>
      )}
      <form
        id='subscriptionForm'
        className={`bg-background flex flex-col items-center justify-center rounded-xlarge overflow-hidden shadow-encantosShadow mb-16 text-16 ${
          promo.paymentRequired ? 'p-30' : ''
        }`}
        onSubmit={handleSubmit}
      >
        {promo.paymentRequired && (
          <>
            <div className='flex justify-between mt-20 w-full'>
              <div className='flex items-center'>
                <img alt='radio button icon' className='mr-12' src={Radio} />
                <p className='font-black text-purple-dark text-16'>
                  Credit or Debit Card
                </p>
              </div>
              <img alt='credit card icon' src={CreditCard} />
            </div>
            {error && <p className='text-red-600'>{error.message || error}</p>}
            <FormInput
              className='bg-transparent border w-full h-56 my-30 pl-20 text-16 sm:w-360 rounded-xl p-15'
              handleChange={handleChange}
              name='nameOnCard'
              placeholder='Name on Card'
              type='text'
              value={nameOnCard}
            />
            <StripeCardElement setError={setError} />
            <div className='flex flex-col w-full md:w-auto'>
              <label className='text-16 mb-7'>Promo Code</label>
              <div className='flex flex-col w-full items-center'>
                <FormInput
                  className={`bg-transparent border w-full h-56 pl-20 text-16 sm:w-360 rounded-xl p-15`}
                  handleChange={handleChange}
                  name='promo'
                  //disabled={promoCanBeApplied}
                  placeholder='Add your code'
                  type='text'
                  value={code}
                />
                {!promoCanBeApplied && (
                  <Error className='mb-30' error={promo_code_error} />
                )}
              </div>
            </div>
          </>
        )}
      </form>
      <div className='flex flex-col lg:flex-row'>
        <OrderSummary
          trial={trialText()}
          discountPrice={discountPrice}
          plan={`${name} Plan`}
          price={`${formatPrice(price)}`}
          promo={{ promoCanBeApplied, ...promo }}
        />
        <Details
          disabled={buttonDisabled}
          trial={trialText()}
          paymentRequired={promo.paymentRequired}
        />
      </div>
    </>
  );
};

function PaymentInfo({
  changeInput,
  checkout,
  error,
  parent,
  priceId,
  promoData,
  resetError,
  validatePromo,
  resetPromoError,
  resetValidPromo,
  accountCreated,
  ...restProps
}) {
  const { nameOnCard } = checkout;

  const handleChange = (event) => {
    if (error) resetError();
    const value = event.target.value;
    const name = event.target.name;
    changeInput({ name, value });
    if (name === 'promo') {
      if (value) {
        verifyPromo(validatePromo, {
          loading: false,
          promo_code: value,
          selectedPrice: priceId,
        });
      }
      if (promoData.promoValidated) {
        resetValidPromo();
      }
      if (promoData.promo_code_error) {
        resetPromoError();
      }
    }
  };

  return (
    <PaymentForm
      error={error}
      handleChange={handleChange}
      nameOnCard={nameOnCard}
      promo={promoData}
      changeInput={changeInput}
      resetPromoError={resetPromoError}
      selectedPriceId={priceId}
      userData={parent}
      {...restProps}
    />
  );
}

function mapDispatchToProps(dispatch) {
  return {
    changeInput: (payload) =>
      dispatch(handleInputChange({ form: 'checkout', ...payload })),
    createSubscription: (payload) =>
      dispatch(requestCreateSubscription(payload)),
    resetPromoError: () => dispatch(resetErrorMessage({ form: 'checkout' })),
    resetValidPromo: () => dispatch(resetPromoValidation()),
    setPromoError: (payload) =>
      dispatch(setFormError({ form: 'checkout', error: payload })),
    updateLoading: (payload) => dispatch(setLoading(payload)),
    setError: (payload) => dispatch(setErrorMessage(payload)),
    resetError: () => dispatch(resetErrorMessage()),
    validatePromo: (payload) => dispatch(validatePromoCode(payload)),
  };
}

function mapStateToProps({ user }, ownProps) {
  const {
    checkout: {
      discount,
      error: promo_code_error,
      minimum_amount,
      promo,
      promoValidated,
      type,
      product_id,
      payment_required,
      trial_length,
      trial_period,
      coupon_type,
      ...restCheckout
    },
    error,
    loading,
    parent,
    priceId,
    activeSubscriptions,
    accountCreated,
  } = user;

  const promoData = {
    code: promo,
    discount,
    minAmount: minimum_amount,
    productPriceId: product_id,
    promo_code_error,
    promoValidated,
    type,
    paymentRequired: payment_required !== undefined ? payment_required : true,
    trialLength: trial_length,
    trialPeriod: trial_period,
    couponType: coupon_type,
  };
  return {
    checkout: { ...restCheckout },
    error,
    loading,
    parent,
    priceId,
    promoData,
    activeSubscriptions,
    accountCreated,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentInfo);
