import React from 'react';
import encantosIcon from '../assets/encantos_icon_purple.png';

export default function Loading() {
  return (
    <div className='flex flex-col items-center justify-center w-full animate-pulse pb-15'>
      <img className='h-85 w-85' src={encantosIcon} />
      <h2 className='leading-normal text-purple-dark'>...loading</h2>
    </div>
  );
}
