import FacebookPurple from '../../assets/Socials/facebook_purple.svg';
import InstagramPurple from '../../assets/Socials/instagram_purple.svg';
import TwitterPurple from '../../assets/Socials/twitter_purple.svg';
import YoutubePurple from '../../assets/Socials/youtube_purple.svg';

const baseURL = 'https://encantosworld.com';
const isMobile = window.matchMedia('(max-width: 640px)').matches;
const downloadText = isMobile ? ' Download the App!' : ' Get the App!';
export const LINK_MAP = [
  [
    {
      href: `${baseURL}/storyteaching`,
      isInternal: false,
      title: 'Storyteaching',
    },
    {
      href: 'https://storyworld.encantosworld.com',
      isInternal: false,
      title: 'Storyworlds',
    },
  ],
  [
    {
      href: `${baseURL}/resources`,
      isInternal: false,
      title: 'Resources',
    },
    { href: `${baseURL}/about`, isInternal: false, title: 'About' },
  ],
  [
    {
      href: `${baseURL}/tips-and-resources?tab=tabs-blog`,
      isInternal: false,
      title: 'Blog',
    },
    {
      href: 'https://shop.encantosworld.com/',
      isInternal: false,
      title: 'Shop',
    },
  ],
  [
    {
      href: 'https://www.encantosworld.com/encantos-app',
      isInternal: false,
      title: `${downloadText}`,
    },
    { href: '/parent-portal/child-profile', title: `Parent's Portal` },
  ],
  [
    {
      href: `${baseURL}/faq`,
      isInternal: false,
      title: 'FAQs',
    },
    {
      href: `${baseURL}/about?tab=tabs-press`,
      isInternal: false,
      title: 'Press',
    },
  ],
  [
    {
      href: `${baseURL}/terms-of-use`,
      isInternal: false,
      title: 'Terms and Conditions',
    },
    {
      href: `${baseURL}/privacy-policy`,
      isInternal: false,
      title: 'Privacy Policy',
    },
  ],
  [{ href: 'https://www.encantosworld.com/faq', title: 'Contact Us' }],
];

export const SOCIAL_MAP = [
  {
    altText: 'facebook logo',
    href: 'https://www.facebook.com/EncantosBrands/',
    svg: FacebookPurple,
  },
  {
    altText: 'twitter logo',
    href: 'https://twitter.com/EncantosBrands',
    svg: TwitterPurple,
  },
  {
    altText: 'instagram logo',
    href: 'https://www.instagram.com/encantosbrands/',
    svg: InstagramPurple,
  },
  {
    altText: 'youtube logo',
    href: 'https://www.youtube.com/channel/UCFJJziTVsiIVSyRKvXHX37g',
    svg: YoutubePurple,
  },
];
