import React from 'react';

import PinPonPng from '../../../assets/backgrounds/characters/pin_pon.png';
import SenorTickTockPng from '../../../assets/backgrounds/characters/senor_tick_tock.png';
import KikiPng from '../../../assets/backgrounds/characters/kiki.png';
import SammyPng from '../../../assets/backgrounds/characters/sammy.png';

export default function PaymentInfoChars() {
  return (
    <>
      <img
        className='absolute hidden h-79 w-79 md:block'
        src={SenorTickTockPng}
        style={{
          bottom: '48%',
          left: 'calc(50vw - 410px)',
        }}
      />
      <img
        className='absolute hidden h-79 w-79 md:block'
        src={KikiPng}
        style={{
          bottom: '40.5%',
          left: 'calc(50vw - 560px)',
        }}
      />
      <img
        className='absolute hidden md:block h-135 w-135'
        src={PinPonPng}
        style={{
          bottom: '48.5%',
          left: 'calc(50vw - 585px)',
        }}
      />
      <img
        className='absolute hidden md:block h-135 w-135'
        src={SammyPng}
        style={{
          bottom: '35%',
          left: 'calc(50vw - 465px)',
        }}
      />
    </>
  );
}
