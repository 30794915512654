import React from 'react';
import { isAndroid, isIOS } from 'react-device-detect';

import EncantosYellow from '../../../assets/encantos_logo_yellow.png';
import SignIn from '../../../assets/sign-in.png';
import Storyteaching from '../../../assets/storyteaching.svg';
import ExternalLink from './ExternalLink';

const getMobileButton = () => {
  let route =
    'https://apps.apple.com/us/app/encantos-play-learn-grow/id1565254285';

  if (isAndroid) {
    route =
      'https://apps.apple.com/us/app/encantos-play-learn-grow/id1565254285';
  } else if (isIOS) {
    route =
      'https://apps.apple.com/us/app/encantos-play-learn-grow/id1565254285';
  }

  return (
    <ExternalLink
      id='download-Mobile-App'
      className='font-black desktop:hidden mt-15 px-30 py-15 rounded-2xl'
      label='Download Now'
      route={route}
    />
  );
};

export const stepDictionary = [
  {
    blurb: '',
    image: { alt: 'yellow encantos logo', src: EncantosYellow },
    mobileButton: getMobileButton(),
    title: '1. Scan the code & download the app.',
  },
  {
    blurb: '',
    image: { alt: 'blue asterisks', src: SignIn },
    title: '2. Sign in & create your child’s profile.',
  },
  {
    blurb: '',
    image: { alt: 'encantos logo blurb', src: Storyteaching },
    title: '3. Start playing & learning right away!',
  },
];
