import React from 'react';

import SocialCircle from './SocialCircle';
import { SOCIAL_MAP } from '../constants';

export default function SocialSection() {
  return (
    <div className='flex mb-12 lg:mb-0'>
      <p className='text-white text-base md:text-xl mr-20 md:mr-40'>
        Follow Us
      </p>
      {SOCIAL_MAP.map(({ href, svg }, idx) => {
        const isEnd = idx === SOCIAL_MAP.length - 1;
        return (
          <SocialCircle
            key={`social-icon-${idx}`}
            href={href}
            missingMargin={isEnd}
            svg={svg}
          />
        );
      })}
    </div>
  );
}
