import React from 'react';
import { ReactComponent as LoadingSvg } from '../assets/loader/oval.svg';

export default function LoadingSpinner({
  isOn = false,
  displaySpinner,
  className,
}) {
  const hasSpinner = isOn ? true : null;
  return (
    <>
      {hasSpinner && displaySpinner ? (
        <LoadingSvg className={className} />
      ) : null}
    </>
  );
}
