import React, { Component, createContext } from 'react';

let MessagingContext;
const { Provider, Consumer } = (MessagingContext = createContext());

class MessagingProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: null,
      successMessage: null,
      warningMessage: null,
      addErrorMessage: this.addErrorMessage,
      addSuccessMessage: this.addSuccessMessage,
      addWarningMessage: this.addWarningMessage,
      emptyAllMessages: this.emptyAllMessages,
    };
  }

  addErrorMessage = (label, description) => {
    const newErrorMessage = {
      label,
      description,
      show: this.state.errorMessage ? !this.state.errorMessage.show : true,
    };
    this.setState({ errorMessage: newErrorMessage });
  };

  addSuccessMessage = (label, description) => {
    const newSuccessMessage = {
      label,
      description,
      show: this.state.successMessage ? !this.state.successMessage.show : true,
    };
    this.setState({ successMessage: newSuccessMessage });
  };

  addWarningMessage = (label, description) => {
    const newWarningMessage = {
      label,
      description,
      show: true,
    };
    this.setState({ warningMessage: newWarningMessage });
  };

  emptyAllMessages = () => {
    this.setState({ successMessage: null });
    this.setState({ errorMessage: null });
    this.setState({ warningMessage: null });
  };

  render() {
    return <Provider value={this.state}>{this.props.children}</Provider>;
  }
}

export { MessagingProvider, Consumer as MessagingConsumer, MessagingContext };
