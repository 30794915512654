import React, { useState } from 'react';

import ExpandedNav from './ExpandedNav';

export default function MobileNav({ authToken, ...restProps }) {
  const [active, setActive] = useState(false);

  const handleClick = () => {
    setActive(!active);
  };

  const hamburgerButton = (
    <button className="flex items-center justify-center bg-purple-light rounded-lg h-44 w-44" onClick={handleClick}>
      <svg className="w-24 h-30" fill="none" stroke="#FFFFFF" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
      </svg>
    </button>
  );

  return <>{!active ? hamburgerButton : <ExpandedNav authToken={authToken} handleClick={handleClick} {...restProps} />}</>;
}
