import React, { useContext, useState } from 'react';
import FormInput from './../components/FormInput';
import CTA from './../components/CTA';
import useForm from '../hooks/useForm';
import { useEditUserProfile } from '../hooks/useAPIs';
import AWS from 'aws-sdk';
const cognito = new AWS.CognitoIdentityServiceProvider({ region: 'us-east-1' });

export default function EditProfile({ user, authToken }) {
  const { execute } = useEditUserProfile({ authToken });
  const phoneNumberVal = () => {
    user['phone_number']
      ? user['phone_number'].startsWith('+')
        ? user['phone_number']
        : `+${user['phone_number']}`
      : '';
  };
  const { values, handleChange } = useForm({
    initialValues: {
      firstName: user['custom:firstName'] || '',
      lastName: user['custom:lastName'] || '',
      email: user.email || '',
      phoneNumber: phoneNumberVal(),
      address1: user['custom:address1'] || '',
      address2: user['custom:address2'] || '',
      city: user['custom:city'] || '',
      state: user['custom:state'] || '',
      zip: user['custom:zip'] || '',
    },
  });

  const HandleEditUserProfile = (e) => {
    e.preventDefault();

    execute(values)
      .then((res) => {
        //TODO reset COGNITO USER TO SHOW THE LATEST DATA)
      })
      .catch();
  };

  return (
    <div className='page'>
      <div className='inlineForm'>
        <h3>Profile</h3>
        {/*{userProfileError && <div className="inlineForm__notif"><Error error={userProfileError.message}/></div>}*/}
        <form onSubmit={HandleEditUserProfile}>
          <FormInput
            type={'text'}
            placeholder={'First Name'}
            name={'firstName'}
            value={values.firstName}
            handleChange={handleChange}
          />

          <FormInput
            type={'text'}
            placeholder={'Last Name'}
            name={'lastName'}
            value={values.lastName}
            handleChange={handleChange}
          />

          <FormInput
            type={'text'}
            placeholder={'eMail Address'}
            name={'email'}
            value={values.email}
            handleChange={handleChange}
          />

          <FormInput
            type={'text'}
            placeholder={'Phone Number'}
            name={'phoneNumber'}
            value={values.phoneNumber}
            handleChange={handleChange}
          />

          <FormInput
            type={'text'}
            placeholder={'Address 1'}
            name={'address1'}
            value={values.address1}
            handleChange={handleChange}
          />

          <FormInput
            type={'text'}
            placeholder={'Address 2'}
            name={'address2'}
            value={values.address2}
            handleChange={handleChange}
          />

          <FormInput
            type={'text'}
            placeholder={'City'}
            name={'city'}
            value={values.city}
            handleChange={handleChange}
          />

          <FormInput
            type={'text'}
            placeholder={'State'}
            name={'state'}
            value={values.state}
            handleChange={handleChange}
          />

          <FormInput
            type={'text'}
            placeholder={'Zip Code'}
            name={'zip'}
            value={values.zip}
            handleChange={handleChange}
          />

          <div style={{ float: 'right' }}>
            <CTA name={'updateProfile'} type={'submit'} />
          </div>
        </form>
      </div>
    </div>
  );
}
