import React, { useCallback, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

import ProfileInitials from './ProfileInitals';
import InlineButton from '../InlineButton';

export default function ProfileModal({
  firstName,
  initials,
  setShowModal,
  showModal,
  logoutUser,
}) {
  const modalRef = useRef();
  const closeModal = (e) => {
    if (modalRef.current === e.target) {
      setShowModal(false);
    }
  };
  const keyPress = useCallback(
    (e) => {
      if (e.key === 'Escape' && showModal) setShowModal(false);
    },
    [setShowModal, showModal]
  );

  const history = useHistory();

  useEffect(() => {
    document.addEventListener('keydown', keyPress);
    return () => document.removeEventListener('keydown', keyPress);
  }, [keyPress]);

  return (
    <>
      {showModal ? (
        <div
          className='absolute inset-0 justify-end z-10 flex bg-transparent h-screen w-screen'
          ref={modalRef}
          onClick={closeModal}
        >
          <div className='bg-white top-45 right-15 md:top-60 md:right-40 relative flex flex-col justify-between rounded-md w-217 h-210 p-20 shadow-md'>
            <div className='flex justify-between h-full items-center'>
              <p className='font-black text-lg'>{firstName}</p>
              <ProfileInitials initials={initials} />
            </div>
            <hr className='border-gray-300 border-solid' />
            <div className='flex flex-col h-full justify-center'>
              <Link className='epp-hover text-16' to='/parent-portal/child-profile'>
                Parent&apos;s Portal
              </Link>
              <a className='w-max' href='https://encantos.helpshift.com/hc/en/'>
                <InlineButton className='h-24' name={'Help'} />
              </a>
              <InlineButton
                className='h-24'
                name={'Log Out'}
                handleClick={() => logoutUser({ history })}
              />
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}
