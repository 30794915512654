import React from 'react';
import clsx from 'clsx';

export default function Banner({ children, containerClass }) {
  const defaultContainerClasses =
    'bg-purple-light border flex max-h-fitcontent min-h-60 items-center mx-auto pl-30 rounded-md text-white';
  const containerClasses = clsx(containerClass, defaultContainerClasses);

  return <div className={containerClasses}>{children}</div>;
}
