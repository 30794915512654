import React from 'react';

import { stepDictionary } from './constants';

export default function SuccessBottomSection() {
  return (
    <div className='flex flex-col desktop:flex-row items-start justify-center mt-30 desktop:mt-50'>
      {stepDictionary.map(({ blurb, image, mobileButton, title }, idx) => {
        const { alt, src } = image;
        const lastStep = idx === stepDictionary.length - 1;

        return (
          <div
            key={`${title}-${idx}`}
            className={`flex flex-col justify-center items-center md-lg:flex-row w-261 ${
              lastStep ? 'md-lg:w-395' : 'mb-35 md-lg:mr-15 lg:mb-0 md-lg:w-345'
            }`}
          >
            <img
              alt={alt}
              className='md-lg:self-start h-120 w-120 desktop:h-115 desktop:w-115 lg:h-138 lg:w-138'
              src={src}
            />
            <div className='flex flex-col items-center lg:ml-15 text-center'>
              <p className='font-black leading-normal mt-15 mb-10 lg:my-0 text-24'>
                {title}
              </p>
              <p
                className={`${mobileButton && 'hidden desktop:block'} text-16`}
              >
                {blurb}
              </p>
              {mobileButton}
            </div>
          </div>
        );
      })}
    </div>
  );
}
