import React from 'react';

import SuccessModal from './SuccessModal';
import UpgradePlanLandingModal from './UpgradePlanLandingModal';
import CTA from '../../components/CTA';
import Error from '../../components/Error';
import InternalButton from '../../components/InternalButton';
import TwoSidedCard from '../../components/TwoSidedCard';
import { logoDictionary } from '../Subscribe/constants';

export default function PlansMap({
  activeSubscriptions,
  currentPlan,
  error,
  resetError,
  setError,
  setUpdatePlanId,
  subscriptionType,
  updatePlanRequest,
  updatePlanData,
}) {
  const { success, updatePlanId } = updatePlanData;

  const onSubmit = (evt) => {
    evt.preventDefault();
    if (!updatePlanId) {
      setError('Looks like you currently have this plan, select a new plan');
    } else {
      updatePlanRequest({ updatePlanId });
    }
  };

  const isYearly = activeSubscriptions.reduce((acc, cv) => {
    if (acc) return acc;
    const { name } = cv;
    if (name === 'Yearly' && currentPlan === name) return true;
    return false;
  }, false);
  const isMobile = subscriptionType !== 'web';

  return (
    <form onSubmit={onSubmit}>
      <UpgradePlanLandingModal
        isMobile={isMobile}
        isYearly={isYearly}
        subscriptionType={subscriptionType}
      />
      <SuccessModal success={success} />
      <Error className='text-center pb-15' error={error} />
      <div className='flex flex-col items-center lg:flex-row lg:items-start lg:justify-center'>
        {activeSubscriptions.map(
          ({ avatar, id, language, name, ...restProps }, idx) => {
            const onClick = (evt) => {
              if (error) resetError();
              if (evt.target.id !== 'flipCard') {
                if (name === currentPlan) {
                  setUpdatePlanId(id);
                } else {
                  setUpdatePlanId(id);
                }
              }
            };

            const isSelected =
              (!updatePlanId && currentPlan === name) || updatePlanId === id;
            const formattedRestProps = { ...language, ...restProps, name, id };

            return (
              <TwoSidedCard
                key={`${name}-${idx}`}
                addMargin={idx !== 0}
                className={`hover:border-purple-light ${
                  isSelected && 'border-purple-light'
                } w-264`}
                icon={logoDictionary[avatar]}
                isSelected={isSelected}
                onClick={onClick}
                title={name}
                type='subscription'
                {...formattedRestProps}
              />
            );
          }
        )}
      </div>
      <div className='flex justify-center lg:justify-end mt-40'>
        <InternalButton
          linkClassName='border-2 border-purple-light border-solid text-purple-light px-45 py-19 mr-20'
          name='Cancel'
          path='/parent-portal/manage-subscription'
          pClassName='h-auto'
        />
        <CTA
          buttonClassName='px-45 py-19 rounded-large bg-purple-light text-white'
          textClassName='text-16 font-extrabold capitalize text-white'
          name='Confirm'
          type='submit'
        />
      </div>
    </form>
  );
}
