import React from 'react';
import { Link } from 'react-router-dom';

import InlineButton from '../../InlineButton';

export default function FooterLink({ addPadding, isInternal = true, label, route }) {
  let ExternalLink = (
    <a
      className='text-white epp-hover'
      href={route}
    >
      <InlineButton className={`${addPadding ? 'pb-24' : 'pb-24 lg:pb-0'}`} name={label} />
    </a>
  );

  let InternalLink = (
    <Link className='text-white' to={route}>
      <InlineButton className={`${addPadding ? 'pb-24' : 'pb-24 lg:pb-0'}`} name={label} />
    </Link>
  );

  return <>{isInternal ? InternalLink : ExternalLink}</>;
}
