const formatDate = (dateInteger) =>
  new Intl.DateTimeFormat('en-us').format(new Date(dateInteger * 1000));
const formatPrice = (price) => `$${price / 100}`;

const displaySubscriptionPrice = (price, period) =>
  `${formatPrice(price)} / ${period}`;

const formatDiscountPrice = (discount, price, type) => {
  let dollars = price / 100;
  const discountAmount = discount / 100;
  if (type === 'percent') {
    dollars = dollars * (1 - discountAmount);
  } else if (type === 'amount') {
    dollars = dollars - discountAmount;
  }
  return `$${dollars.toFixed(2)}`;
};

const formatters = {
  formatDiscountPrice,
  displaySubscriptionPrice,
  formatDate,
  formatPrice,
};

export default formatters;

export {
  formatDiscountPrice,
  displaySubscriptionPrice,
  formatDate,
  formatPrice,
};
