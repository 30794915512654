import React from 'react';

import SenorTickTockPng from '../../../assets/backgrounds/characters/senor_tick_tock.png';
import SammyPng from '../../../assets/backgrounds/characters/sammy.png';
import TinyTravelersPng from '../../../assets/backgrounds/characters/tiny_travelers.png';
import PinPonPng from '../../../assets/backgrounds/characters/pin_pon.png';

export default function ForgotPwChars() {
  return (
    <div className='flex justify-between w-full'>
      <img
        className='h-80 w-80 mb-29 mt-108'
        src={TinyTravelersPng}
      />
      <img
        className='h-88 w-88 mt-32'
        src={SammyPng}
      /> 
      <img
        className='h-92 w-92 mt-75'
        src={SenorTickTockPng}
      />
      <img
        className='h-106 w-106 mb-40 mt-92 -mr-15'
        src={PinPonPng}
      />
    </div>
  );
}
