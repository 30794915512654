import React from 'react';
import clsx from 'clsx';

function PrimarySecondaryModalButtons({
  containerClassName,
  primaryBtnClassName,
  secondaryBtnClassName,
  primaryBtnText,
  secondaryBtnText,
  primaryBtnOnClick,
  secondaryBtnOnClick,
}) {
  const defContainerClassNames = 'flex justify-center';
  const defPrimaryBtnClassNames =
    'px-8 py-2 border-2 text-16 border-purple-light border-solid text-purple-light rounded-xl epp-hover';
  const defSecondaryBtnClassNames =
    'px-8 py-2  bg-purple-light text-16 border-solid text-white rounded-xl epp-hover';
  const containerClass = clsx(containerClassName, defContainerClassNames);
  const primaryClassName = clsx(primaryBtnClassName, defPrimaryBtnClassNames);
  const secondaryClassName = clsx(
    secondaryBtnClassName,
    defSecondaryBtnClassNames
  );
  return (
    <div className={containerClass}>
      <button className={primaryClassName} onClick={primaryBtnOnClick}>
        {primaryBtnText}
      </button>

      <button className={secondaryClassName} onClick={secondaryBtnOnClick}>
        {secondaryBtnText}
      </button>
    </div>
  );
}

export default PrimarySecondaryModalButtons;
