import React from 'react';

export default function OrderSummary({
  trial,
  discountPrice,
  plan,
  price,
  promo = {},
}) {
  const { code, promoCanBeApplied, paymentRequired } = promo;
  const priceToDisplay = promoCanBeApplied ? discountPrice : price;
  const trialText =
    trial &&
    `${trial.length} ${
      trial.period.charAt(0).toUpperCase() + trial.period.slice(1)
    }${trial.length > 1 ? 's' : ''} Free`;
  return (
    <div className='bg-purple-main flex flex-col min-h-225 justify-center px-37 rounded-xlarge lg:w-400'>
      <p className='font-black mb-24 text-16'>Order Summary</p>
      {paymentRequired && (
        <div className='flex justify-between'>
          <p>{plan}</p>
          <p>{priceToDisplay}</p>
        </div>
      )}
      {promoCanBeApplied && (
        <div className='flex justify-between'>
          <p>Promo Code Applied</p>
          <p>{code}</p>
        </div>
      )}
      {trial && !trial.type && (
        <div className='flex justify-between'>
          <p>Free Trial Offer</p>
          <p>{trialText}</p>
        </div>
      )}

      <div className='flex justify-between'>
        <p>Total due today</p>
        <p>{trial ? '$0.00' : priceToDisplay}</p>
      </div>
    </div>
  );
}
