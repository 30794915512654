import React from 'react';
import PrimarySecondaryModalButtons from '../PrimarySecondaryModalButtons';

function PromoModal({ handleCancel, handleClose }) {
  return (
    <div>
      <div className='flex flex-col justify-center sm:w-full'>
        <p className='w-full mt-40 flex justify-center text-32 font-black '>
          Before you go...
        </p>
        <div className='w-full'>
          <p className='w-292 mx-auto mt-27 text-16 font-normal text-center sm:w-400 md:text-16'>
            We understand that Encantos can be expensive for hard working
            families, and we don&apos;t want your pollito to miss out on the
            opportunity to learn 21st century skills they need to thrive.
          </p>
          <p className='w-292 mx-auto mt-27  text-16 font-black text-center sm:w-400 md:text-16'>
            To help make it easier for you to stay, we’d like to offer you...**{' '}
          </p>
        </div>
      </div>
      <hr className='w-292 mx-auto mt-37 border-gray-hrLine border-solid sm:w-456' />

      <div className='mt-30 flex flex-wrap justify-center sm:w-456'>
        <p className='text-16 text-center font-extrabold'>
          *For annual subscribers:
        </p>
        <p className='mt-15 text-16 text-center'>
          We’d like to offer you three months FREE if you choose to continue
          your subscription today. That’s a savings of $20.97!
        </p>
      </div>

      <PrimarySecondaryModalButtons
        containerClassName='mt-25 mb-40 flex-col items-center space-x-0 space-y-20 sm:flex-row sm:space-x-20 sm:space-y-0  md:mt-63'
        primaryBtnClassName='h-48 w-212 font-extrabold'
        primaryBtnText='Cancel Anyway'
        primaryBtnOnClick={handleCancel}
        secondaryBtnClassName='h-48 w-212 font-extrabold'
        secondaryBtnText='Redeem Offer'
        secondaryBtnOnClick={handleClose}
      />
    </div>
  );
}

export default PromoModal;
