import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Modal, Button, FormLabel } from '@material-ui/core';
import { orange, green } from '@material-ui/core/colors';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 50,
    paddingBottom: 20,
    paddingLeft: 20,
    paddingRight: 20,
  },
  paper: {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
    width: 400,
    background: theme.palette.background.paper,
    border: 'none',
    boxShadow: theme.shadows[5],
    padding: 15,
    borderRadius: 3,
  },
  label: {
    marginBottom: 20,
  },
  cancelButton: {
    marginLeft: 10,
    marginTop: 20,
    color: '#FFF',
    minWidth: 150,
    backgroundColor: orange[500],
    '&:hover': {
      backgroundColor: orange[700],
    },
  },
  confirmButton: {
    marginTop: 20,
    color: '#FFF',
    minWidth: 150,
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
}));

const ConfirmationModal = ({
  openModal,
  content,
  confirmFunction,
  closeModalFunction,
}) => {
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only in the first render
  const [open, setOpen] = useState(openModal);

  const onConfirm = () => {
    confirmFunction();
    closeModalFunction();
  };

  const onClose = () => {
    closeModalFunction();
  };

  useEffect(() => {
    setOpen(openModal);
  }, [openModal]);

  return (
    <Modal open={open} onClose={onClose} className={classes.root}>
      <div className={classes.paper}>
        <FormLabel>{content}</FormLabel>
        <br />
        <Button
          className={classes.confirmButton}
          onClick={onConfirm}
          variant='contained'
          disableElevation
          startIcon={<CheckCircleIcon />}
        >
          Yes
        </Button>
        <Button
          className={classes.cancelButton}
          onClick={onClose}
          variant='contained'
          disableElevation
          startIcon={<CancelIcon />}
        >
          Cancel
        </Button>
      </div>
    </Modal>
  );
};

ConfirmationModal.propTypes = {
  content: PropTypes.string,
  openModal: PropTypes.func,
  confirmFunction: PropTypes.func,
  closeModalFunction: PropTypes.func,
};

export default ConfirmationModal;
