import React, { useContext, useState, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

export default function PrivateRoute(props) {
  const { component: Component, authToken, requestSession, ...rest } = props;
  let history = useHistory();

  useEffect(() => {
    if (!authToken) {
      requestSession({ history });
    }
  }, []);

  return (
    <Route
      {...rest}
      render={(props) =>
        authToken ? <Component {...props} {...rest} /> : <div></div>
      }
    />
  );
}
