import React from 'react';

import SenorTickTockPng from '../../../assets/backgrounds/characters/senor_tick_tock.png';
import SammyPng from '../../../assets/backgrounds/characters/sammy.png';
import KikiPng from '../../../assets/backgrounds/characters/kiki.png';
import TinyTravelersPng from '../../../assets/backgrounds/characters/tiny_travelers.png';

export default function ChangePwSuccessChars() {
  return (
    <div className='flex flex-col justify-between pb-24'>
      <img
        className='h-96 w-96 ml-146 xs:ml-233 mt-50'
        src={SenorTickTockPng}
      />
      <img
        className='h-133 w-133'
        src={SammyPng}
        /> 
      <img
        className='h-96 w-96 ml-130 xs:ml-225 mt-12'
        src={KikiPng}
      />
      <img
        className='h-146 w-146 mt-50'
        src={TinyTravelersPng}
      />
    </div>
  );
}
