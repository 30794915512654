import {
  HANDLE_INPUT_CHANGE,
  SET_TOKEN,
  REQUEST_SUBSCRIPTION_DETAILS,
  RECEIVED_SUBSCRIPTION_DETAILS,
  RECEIVED_SUBSCRIPTION_DETAILS_FAILED,
  REQUEST_UPDATE_PAYMENT_METHOD,
  SET_LOADING,
  SET_SHOW_UPDATE_PAYMENT_METHOD,
  RECEIVED_UPDATE_PAYMENT_METHOD,
  RECEIVED_UPDATE_PAYMENT_METHOD_FAILED,
  REQUEST_SET_ERROR,
  REQUEST_RESET_ERROR,
  SET_PRICE_ID,
  REQUEST_CREATE_SUBSCRIPTION,
  RECEIVED_CREATE_SUBSCRIPTION,
  RECEIVED_CREATE_SUBSCRIPTION_FAILED,
  REQUEST_PARENT,
  RECEIVED_PARENT,
  RECEIVED_PARENT_FAILED,
  REQUEST_ADD_CHILD,
  RECEIVED_ADD_CHILD,
  RECEIVED_ADD_CHILD_FAILED,
  REQUEST_UPDATE_ACCOUNT,
  RECEIVED_UPDATE_ACCOUNT,
  RECEIVED_UPDATE_ACCOUNT_FAILED,
  REQUEST_ACTIVE_SUBSCRIPTIONS,
  RECEIVED_ACTIVE_SUBSCRIPTIONS,
  RECEIVED_ACTIVE_SUBSCRIPTIONS_FAILED,
  SET_UPDATE_PLAN_ID,
  SET_USER_DATA,
  REQUEST_UPDATE_CHILD,
  RECEIVED_UPDATE_CHILD,
  RECEIVED_UPDATE_CHILD_FAILED,
  REQUEST_DELETE_CHILD,
  RECEIVED_DELETE_CHILD,
  RECEIVED_DELETE_CHILD_FAILED,
  REFER_A_FRIEND,
  RECEIVED_REFER_A_FRIEND,
  RECEIVED_REFER_A_FRIEND_FAILED,
  RESET_PROMO_SENT,
  VALIDATE_PROMO_CODE_SUCCESS,
  REQUEST_VALIDATE_PROMO_CODE,
  VALIDATE_PROMO_CODE_FAILURE,
  RESET_PROMO_ERROR,
  RESET_PROMO_VALIDATION,
  SET_PROMO,
  CLEAR_PROMO_CODE,
  SET_FORM_ERROR,
  REQUEST_UPDATE_PLAN,
  RECEIVED_UPDATE_PLAN,
  RECEIVED_UPDATE_PLAN_FAILED,
  REQUEST_CANCEL_SUBSCRIPTION,
  RECEIVED_CANCEL_SUBSCRIPTION,
  RECEIVED_CANCEL_SUBSCRIPTION_FAILED,
  REQUEST_LOGIN,
  RECEIVED_LOGIN,
  RECEIVED_LOGIN_FAILED,
  REQUEST_LOGOUT,
  RECEIVED_LOGOUT,
  RECEIVED_LOGOUT_FAILED,
  REQUEST_USER_SESSION,
  RECEIVED_USER_SESSION,
  REQUEST_GTM,
  REQUEST_CREATE_ACCOUNT,
  REQUEST_ACCOUNT_CREATED,
  REQUEST_RESET_STATE,
  REQUEST_SET_SPINNER,
  REQUEST_RESET_SPINNER,
  REQUEST_RESET_SUBSCRIPTION_STATUS,
  REQUEST_PAYMENT_INTENT,
  RECEIVED_PAYMENT_INTENT,
  REQUEST_GIFT_PROMO_CODE,
  RECEIVED_GIFT_PROMO_CODE,
  REQUEST_GIFT_PDF,
  RECEIVED_GIFT_PDF,
  RECEIVED_GIFT_PDF_FAILED,
} from './constants';
import AppError from '../../formatters/error';

export const initialState = {
  authToken: null,
  authError: null,
  activeSubscriptions: [],
  userData: null,
  createdAccount: false,
  checkout: {
    active: false,
    error: null,
    nameOnCard: '',
    promo: '',
    promoValidated: false,
  },
  gift: {
    active: false,
    emailNotification: true,
    error: null,
    gifterEmail: '',
    gifterName: '',
    nameOnCard: '',
    promoCode: '',
    recipientEmail: '',
    recipientName: '',
    termsAndPrivacy: false,
  },
  referral: {
    email: '',
    error: null,
    first_name: '',
    promoSent: false,
  },
  showAddingPaymentMethod: false,
  subscription: {
    details: {},
    isActive: true,
    payment_history: [],
    payment_information: {},
  },
  subCancelSuccess: false,
  updatePlanData: {
    updatePlanId: '',
    success: false,
  },
  parent: {},
  children: [],
  firstName: '',
  lastName: '',
  loading: false,
  showSpinner: false,
  error: null,
  priceId: 'ec9c5f67-0327-407f-8c94-015d2f7a8ddf',
};

const userReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case REQUEST_PARENT: {
      return {
        ...state,
        loading: true,
      };
    }
    case SET_USER_DATA: {
      return {
        ...state,
        parent: payload,
      };
    }
    case HANDLE_INPUT_CHANGE: {
      const { form, name, value } = payload;
      state[form][name] = value;

      return {
        ...state,
        [form]: {
          ...state[form],
          [name]: value,
        },
      };
    }
    case RECEIVED_PARENT: {
      return {
        ...state,
        parent: payload.parent,
        children: payload.children,
        loading: false,
      };
    }
    case RECEIVED_PARENT_FAILED: {
      return {
        ...state,
        error: payload.error,
        loading: false,
      };
    }
    case SET_TOKEN:
      return {
        ...state,
        authToken: payload,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: payload,
      };
    case SET_SHOW_UPDATE_PAYMENT_METHOD:
      return {
        ...state,
        showAddingPaymentMethod: payload,
      };
    case REQUEST_UPDATE_PAYMENT_METHOD:
      return {
        ...state,
        payload,
        showAddingPaymentMethod: true,
        loading: true,
      };
    case RECEIVED_UPDATE_PAYMENT_METHOD:
      return {
        ...state,
        showAddingPaymentMethod: false,
        subscription: {
          ...state.subscription,
          payment_information: {
            brand: payload.card.brand,
            last_4: payload.card.last4,
          },
        },
        loading: false,
      };
    case RECEIVED_UPDATE_PAYMENT_METHOD_FAILED:
      return {
        ...state,
        error: AppError(payload, {
          listFormatter: (list) => list.join(', '),
        }).format(),
        loading: false,
      };
    case REQUEST_SET_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case REQUEST_RESET_ERROR:
      if (payload) {
        const { form } = payload;
        return {
          ...state,
          [form]: {
            ...state[form],
            error: null,
          },
        };
      }
      return {
        ...state,
        error: null,
      };
    case SET_FORM_ERROR:
      // eslint-disable-next-line no-case-declarations
      const { error, form } = payload;
      return {
        ...state,
        [form]: {
          ...state[form],
          error,
        },
      };
    case REQUEST_SUBSCRIPTION_DETAILS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case RECEIVED_SUBSCRIPTION_DETAILS:
      return {
        ...state,
        subscription: {
          details: payload.subscription,
          isActive: ['trialing', 'active'].includes(
            payload.subscription.status
          ),
          payment_history: payload.payment_history,
          payment_information: payload.payment_information,
        },
        error: null,
        loading: false,
      };
    case RECEIVED_SUBSCRIPTION_DETAILS_FAILED:
      return {
        ...state,
        error: payload.error,
        loading: false,
        subscription: {
          ...state.subscription,
          isActive: false,
        },
      };
    case SET_PRICE_ID:
      return {
        ...state,
        priceId: payload,
      };
    case REQUEST_CREATE_SUBSCRIPTION:
      return {
        ...state,
        loading: true,
        paymentMethod: payload,
      };
    case RECEIVED_CREATE_SUBSCRIPTION:
      return {
        ...state,
        subscription: {
          ...state.subscription,
          details: payload.subscription,
          isActive: ['trialing', 'active'].includes(
            payload.subscription.status
          ),
        },
        loading: false,
        showSpinner: false,
      };
    case RECEIVED_CREATE_SUBSCRIPTION_FAILED:
      return {
        ...state,
        error: payload,
        loading: false,
        showSpinner: false,
      };
    case REQUEST_CANCEL_SUBSCRIPTION:
      return {
        ...state,
        loading: true,
      };
    case RECEIVED_CANCEL_SUBSCRIPTION:
      // eslint-disable-next-line no-case-declarations
      const { status, trial_end } = payload.subscription;
      return {
        ...state,
        subscription: {
          ...state.subscription,
          details: {
            ...state.subscription.details,
            status: status,
            trial_end_date: trial_end,
          },
          isActive: ['trialing', 'active'].includes(
            payload.subscription.status
          ),
        },
        subCancelSuccess: true,
        loading: false,
      };
    case RECEIVED_CANCEL_SUBSCRIPTION_FAILED:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case REQUEST_ADD_CHILD: {
      return {
        ...state,
        loading: true,
      };
    }
    case RECEIVED_ADD_CHILD: {
      return {
        ...state,
        children: [...state.children, payload],
        loading: false,
      };
    }
    case RECEIVED_ADD_CHILD_FAILED: {
      return {
        ...state,
        error: payload.error,
        loading: false,
      };
    }
    case REQUEST_UPDATE_CHILD: {
      return {
        ...state,
        loading: true,
      };
    }
    case RECEIVED_UPDATE_CHILD: {
      return {
        ...state,
        children: [
          ...state.children.filter((child) => child.id !== payload.id),
          payload,
        ],
        loading: false,
      };
    }
    case RECEIVED_UPDATE_CHILD_FAILED: {
      return {
        ...state,
        error: payload.error,
        loading: false,
      };
    }

    case REQUEST_DELETE_CHILD: {
      return {
        ...state,
        loading: true,
      };
    }
    case RECEIVED_DELETE_CHILD: {
      return {
        ...state,
        children: [
          ...state.children.filter((child) => child.id !== payload.id),
          payload,
        ],
        loading: false,
      };
    }
    case RECEIVED_DELETE_CHILD_FAILED: {
      return {
        ...state,
        error: payload.error,
        loading: false,
      };
    }
    case REQUEST_UPDATE_ACCOUNT: {
      return {
        ...state,
        loading: true,
        showSpinner: true,
      };
    }
    case RECEIVED_UPDATE_ACCOUNT: {
      return {
        ...state,
        parent: payload,
        loading: false,
        showSpinner: false,
        error: null,
      };
    }
    case RECEIVED_UPDATE_ACCOUNT_FAILED: {
      return {
        ...state,
        error: payload.error,
        loading: false,
        showSpinner: false,
      };
    }
    case REFER_A_FRIEND: {
      return {
        ...state,
        loading: true,
      };
    }
    case REQUEST_VALIDATE_PROMO_CODE: {
      const loading = payload && 'loading' in payload ? payload.loading : true;
      return {
        ...state,
        loading,
      };
    }
    case REQUEST_ACTIVE_SUBSCRIPTIONS: {
      return {
        ...state,
        loading: true,
      };
    }
    case RECEIVED_REFER_A_FRIEND: {
      return {
        ...state,
        loading: false,
        referral: {
          ...state.referral,
          promoSent: true,
        },
      };
    }
    case RECEIVED_REFER_A_FRIEND_FAILED: {
      return {
        ...state,
        loading: false,
        referral: {
          ...state.referral,
          error: payload,
          promoSent: false,
        },
      };
    }
    case RESET_PROMO_SENT: {
      return {
        ...state,
        referral: {
          ...state.referral,
          promoSent: false,
        },
      };
    }
    case RECEIVED_ACTIVE_SUBSCRIPTIONS: {
      return {
        ...state,
        activeSubscriptions: payload,
        loading: false,
      };
    }
    case RECEIVED_ACTIVE_SUBSCRIPTIONS_FAILED: {
      return {
        ...state,
        error: AppError(payload).format(),
        loading: false,
      };
    }
    case SET_UPDATE_PLAN_ID: {
      return {
        ...state,
        updatePlanData: {
          ...state.updatePlanData,
          updatePlanId: payload,
        },
      };
    }
    case REQUEST_UPDATE_PLAN: {
      return {
        ...state,
        loading: true,
      };
    }
    case RECEIVED_UPDATE_PLAN: {
      return {
        ...state,
        error: null,
        loading: false,
        updatePlanData: {
          ...state.updatePlanData,
          success: true,
        },
      };
    }
    case RECEIVED_UPDATE_PLAN_FAILED: {
      return {
        ...state,
        error: AppError(payload).format(),
        loading: false,
        updatePlanData: {
          ...state.updatePlanData,
          success: false,
        },
      };
    }
    case VALIDATE_PROMO_CODE_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const { id, valid, ...restProps } = payload;
      return {
        ...state,
        checkout: {
          ...state.checkout,
          error: !valid ? `The ${id} code is not valid` : null,
          promo: id,
          promoValidated: valid,
          ...restProps,
        },
        loading: false,
      };
    case VALIDATE_PROMO_CODE_FAILURE:
      return {
        ...state,
        checkout: {
          ...state.checkout,
          error: payload,
          promoValidated: false,
        },
        loading: false,
      };
    case RESET_PROMO_ERROR:
      return {
        ...state,
        checkout: { ...state.checkout, error: null },
      };
    case CLEAR_PROMO_CODE:
      return {
        ...state,
        checkout: {
          ...state.checkout,
          promo: '',
          promoValidated: false,
          error: null,
        },
      };
    case SET_PROMO:
      return {
        ...state,
        checkout: {
          ...state.checkout,
          promo: payload,
        },
      };
    case RESET_PROMO_VALIDATION:
      return {
        ...state,
        checkout: {
          ...state.checkout,
          promoValidated: false,
        },
      };
    case REQUEST_LOGIN: {
      return {
        ...state,
        loading: true,
        showSpinner: true,
      };
    }
    case RECEIVED_LOGIN: {
      window.heap.identify(payload.idToken.payload.sub);

      return {
        ...state,
        authError: null,
        loading: false,
        showSpinner: false,
        userData: null,
        authToken: payload.idToken.jwtToken,
        createdAccount: true,
      };
    }
    case RECEIVED_LOGIN_FAILED: {
      return {
        ...state,
        authError: payload,
        loading: false,
        showSpinner: false,
      };
    }
    case REQUEST_LOGOUT: {
      return {
        ...state,
        loading: true,
      };
    }
    case REQUEST_RESET_STATE: {
      return {
        ...initialState,
        activeSubscriptions: [...state.activeSubscriptions],
      };
    }
    case RECEIVED_LOGOUT: {
      return {
        ...initialState,
        activeSubscriptions: [...state.activeSubscriptions],
      };
    }
    case RECEIVED_LOGOUT_FAILED: {
      return {
        ...state,
        authError: payload,
        loading: false,
      };
    }
    case REQUEST_USER_SESSION: {
      return {
        ...state,
      };
    }
    case RECEIVED_USER_SESSION: {
      return {
        ...state,
      };
    }
    case REQUEST_GTM: {
      return {
        ...state,
      };
    }
    case REQUEST_CREATE_ACCOUNT: {
      return {
        ...state,
        loading: true,
      };
    }
    case REQUEST_ACCOUNT_CREATED: {
      return {
        ...state,
        createdAccount: true,
      };
    }
    case REQUEST_SET_SPINNER: {
      return {
        ...state,
        showSpinner: true,
      };
    }
    case REQUEST_RESET_SPINNER: {
      return {
        ...state,
        showSpinner: false,
      };
    }
    case REQUEST_RESET_SUBSCRIPTION_STATUS: {
      return {
        ...state,
        subCancelSuccess: false,
      };
    }
    case REQUEST_PAYMENT_INTENT: {
      return {
        ...state,
        loading: true,
      };
    }
    case RECEIVED_PAYMENT_INTENT: {
      return {
        ...state,
        loading: false,
        gift: {
          ...state.gift,
          ...payload,
        },
      };
    }
    case REQUEST_GIFT_PDF: {
      return {
        ...state,
        showSpinner: true,
      };
    }
    case RECEIVED_GIFT_PDF: {
      return {
        ...state,
        showSpinner: false,
      };
    }
    case RECEIVED_GIFT_PDF_FAILED: {
      return {
        ...state,
        error: payload.error,
        showSpinner: false,
      };
    }
    case REQUEST_GIFT_PROMO_CODE: {
      return {
        ...state,
        loading: true,
      };
    }
    case RECEIVED_GIFT_PROMO_CODE: {
      const {
        promo_code,
        recipient: { email, first_name },
      } = payload;
      return {
        ...state,
        gift: {
          ...state.gift,
          promoCode: promo_code,
          recipientEmail: email,
          recipientName: first_name,
        },
        loading: false,
      };
    }
    default:
      return state;
  }
};

export default userReducer;
