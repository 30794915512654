import React from 'react';
import { Modal } from '@material-ui/core';

export default function ModalBase({
  children,
  isOpen = false,
  handleClose,
  containerClassName,
}) {
  return (
    <Modal
      className='px-10 flex items-center justify-center'
      open={isOpen}
      onClose={handleClose}
      aria-labelledby='modal-title'
      aria-describedby='description'
    >
      <div className={containerClassName}>
        {handleClose && (
          <button
            onClick={handleClose}
            className=' mt-15 mr-10 right-10 absolute sm:mr-20 text-purple-close epp-hover'
          >
            <p className='h-34 w-34 text-3xl text-purple-close font-extralight'>
              X
            </p>
          </button>
        )}
        <div>{children}</div>
      </div>
    </Modal>
  );
}
