import React, { useState } from 'react';
import subscriptionsData from '../../assets/subscriptionsData.json';

const PurchaseItem = ({
  id = -1,
  title = 'Product',
  image = '',
  description = '',
  price = 0,
  addItemToCart,
  type = 'PRODUCT',
  giftItems = [],
  ...restProps
}) => {
  const [selectedSub, setSelectedSub] = useState('none');
  const [fullDescription, setFullDescription] = useState(false);
  const [giftItem, setGiftItem] = useState({
    id: -1,
    name: '',
    email: '',
  });

  const onSubscriptionChange = (e) => {
    setSelectedSub(e.target.value);
  };

  const onGiftItemChange = ({ target: { name, value } }) => {
    setGiftItem((prevGiftItem) => ({
      ...prevGiftItem,
      [name]: value,
    }));
  };

  const toggleDescription = () => setFullDescription(!fullDescription);

  const onItemAdd = () => {
    const subscription = subscriptionsData.find(
      (subscription) => Number(subscription.value) === Number(selectedSub)
    );

    const item =
      type === 'GIFT'
        ? {
            ...giftItems[giftItem.id],
            image,
            subscription,
            type,
            options: {
              name: giftItem.name,
              email: giftItem.email,
            },
          }
        : {
            id,
            type,
            title,
            image,
            subscription,
            price,
          };

    addItemToCart && addItemToCart(item);
  };

  const isValidGiftItem = () => {
    const { id, name, email } = giftItem;

    return !!~Number(id) && name.length >= 3 && email.length >= 3;
  };

  return (
    <div className='product-item' {...restProps}>
      <div className='product-image'>
        <img src={image} alt='Product' />
      </div>
      <div className='product-info'>
        <div className='product-info-header'>
          <h3>{title}</h3>
          <span className='price'>
            $
            {(type === 'GIFT' &&
              !!~giftItem.id &&
              Number(giftItems[giftItem.id].price).toFixed(2)) ||
              Number(price).toFixed(2)}
          </span>
        </div>
        <p>
          {description.length > 80 ? (
            <>
              {fullDescription
                ? description
                : description.slice(0, 200).trim() + '...'}
              <span
                className='product-description-controll'
                onClick={toggleDescription}
              >
                {fullDescription ? 'Show less' : 'Show more'}
              </span>
            </>
          ) : (
            description
          )}
        </p>
        <div
          className={
            type === 'GIFT'
              ? 'product-info-controll gift'
              : 'product-info-controll'
          }
        >
          {type === 'GIFT' && (
            <>
              <input
                className='input'
                placeholder='Gift receiver name'
                value={giftItem.name}
                name='name'
                onChange={onGiftItemChange}
              />
              <input
                className='input'
                placeholder='Gift receiver email'
                value={giftItem.email}
                name='email'
                type='email'
                onChange={onGiftItemChange}
              />
              <select value={giftItem.id} name='id' onChange={onGiftItemChange}>
                <option value='-1' disabled>
                  Choose gift product
                </option>
                {giftItems.map((gift, idx) => (
                  <option key={idx} value={idx}>
                    {gift.title}
                  </option>
                ))}
              </select>
            </>
          )}
          <select value={selectedSub} onChange={onSubscriptionChange}>
            <option value='none' disabled>
              Choose subscription
            </option>
            {subscriptionsData.map((subscription, idx) => (
              <option key={idx} value={subscription.value}>
                {subscription.label}
              </option>
            ))}
          </select>
          <button
            className='button btn'
            disabled={
              selectedSub === 'none' || (type === 'GIFT' && !isValidGiftItem())
            }
            onClick={onItemAdd}
          >
            Add to cart
          </button>
        </div>
      </div>
    </div>
  );
};

export default PurchaseItem;
