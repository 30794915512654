import React from 'react';

import ModalBase from '../../components/modals/ModalBase';
import InternalButton from '../../components/InternalButton';

export default function SuccessModal({ success }) {
  return (
    <ModalBase
      isOpen={success}
      containerClassName='w-full h-573 flex flex-wrap content-center justify-center sm:w-2/3 h-602 md:w-552 bg-white rounded-3xl p-10 relative'
    >
      <div className='flex flex-col items-center'>
        <div className='px-5 pb-15 text-center'>
          <h2>Your plan has been updated! </h2>
          <p className='pt-7'>
            The card on file will be charged when the current monthly term ends.
          </p>
        </div>
        <div className='flex'>
          <InternalButton
            linkClassName='bg-purple-light px-45 py-19'
            name='Back to Manage Subscription'
            path='/parent-portal/manage-subscription'
            pClassName='h-auto'
          />
        </div>
      </div>
    </ModalBase>
  );
}
