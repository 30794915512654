import React from 'react';
import shoppingBag from '../../assets/bag.svg';
import Search from './Search';
import ProfileButton from './ProfileButton';
import PreAuthButtons from './PreAuthButtons';
import ExternalButton from '../ExternalButton';
import DownloadButton from '../DownloadButton';

export default function HeaderButtons({
  pathname,
  authToken,
  parent,
  logoutUser,
}) {
  // get initials
  const firstName = parent.first_name || '';
  const lastName = parent.last_name || '';
  const initials = `${firstName.charAt(0)}${lastName.charAt(0) || ''}`;

  return (
    <div className='flex flex-row items-center'>
      {/* <Search /> */}
      <a
        className='flex items-center justify-center hover:bg-gray-300 rounded-full h-40 w-40 lg:mr-29'
        href={'https://shop.encantosworld.com/'}
      >
        <img src={shoppingBag} alt='shopping bag' />
      </a>
      {/* <DownloadButton
        id='download-button-header'
        containerClassName='bg-purple-light mr-24 px-34 rounded-large w-max'
      >
        <p className='font-bold text-white text-16'>Download the App</p>
      </DownloadButton> */}
      {authToken ? (
        <ProfileButton
          firstName={firstName}
          initials={initials}
          logoutUser={logoutUser}
        />
      ) : (
        <PreAuthButtons pathname={pathname} />
      )}
    </div>
  );
}
