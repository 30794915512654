import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import CTA from '../components/CTA';
import FormInput from '../components/FormInput';
import PPHeader from '../components/PPHeader';
import useForm from '../hooks/useForm';
import { requestUpdateAccount, requestParent } from '../store/redux/actions';
import { parentAccount } from './Subscribe/constants';
import AsyncRender from '../components/AsyncRender';
import { Link } from 'react-router-dom';
import PhoneInput from 'react-phone-number-input/input';
import ToggleSwitchWithLabel from '../components/ToggleSwitchWithLabel';

const ParentAccountView = ({
  handleChange,
  handleSave,
  inputClassNames,
  setPhoneNumber,
  values,
  error,
}) => {
  const showSpinner = useSelector((state) => state.user.showSpinner);

  const PreferencesPanel = () => {
    return (
      <div className='h-275 bg-white rounded-xlarge shadow-encantosShadow sm:w-350'>
        <div className='mx-15'>
          <div className='mt-25 px-15 flex flex-col justify-center w-full sm:px-25'>
            <p className='flex w-5/6 mt-25 text-24 font-black sm:mt-5 md:w-full'>
              {parentAccount.preferencesTitle}
            </p>
            <p className='mt-10 text-16 font-bold'>
              {parentAccount.preferencesDesc}
            </p>
          </div>
          <div className='pl-25 mt-25 '>
            <ToggleSwitchWithLabel
              label={parentAccount.emails}
              inputName='toggleEmail'
              checked={values.toggleEmail}
              handleChange={handleChange}
            />
            <ToggleSwitchWithLabel
              label={parentAccount.sms}
              inputName='toggleSMS'
              checked={values.toggleSMS}
              disabled={!values.phone_number}
              handleChange={handleChange}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className='flex w-full flex-col px-15 pt-15 mb-25 sm:flex-row space-x-45 mt-5'>
      <>
        <div className='sm:w-450'>
          <form className='lg:h-690' onSubmit={handleSave}>
            <div className='flex flex-col space-x-0 sm:flex-row md:space-x-15'>
              <FormInput
                required={true}
                type={'text'}
                className={inputClassNames}
                placeholder={'First Name'}
                name={'first_name'}
                value={values.first_name}
                handleChange={handleChange}
              />
              <FormInput
                required={true}
                type={'text'}
                className={inputClassNames}
                placeholder={'Last Name'}
                name={'last_name'}
                value={values.last_name}
                handleChange={handleChange}
              />
            </div>
            <div className='flex flex-col'>
              <FormInput
                required={true}
                type={'email'}
                className={inputClassNames}
                placeholder={'Email Address'}
                name={'email'}
                value={values.email}
                handleChange={handleChange}
              />
              <FormInput
                type={'text'}
                className={inputClassNames}
                placeholder={'Apt, Suite, Bldg, Gate Code (optional)'}
                name={'address'}
                value={values.address}
                handleChange={handleChange}
              />
              <FormInput
                type={'text'}
                className={inputClassNames}
                placeholder={'City'}
                name={'city'}
                value={values.city}
                handleChange={handleChange}
              />
              <div className='flex flex-col sm:flex-row md:space-x-15 '>
                <FormInput
                  type={'text'}
                  className={inputClassNames}
                  placeholder={'State'}
                  name={'state'}
                  value={values.state}
                  handleChange={handleChange}
                />

                <FormInput
                  type={'text'}
                  maxLength={5}
                  className={inputClassNames}
                  placeholder={'Zip'}
                  name={'zip'}
                  value={values.zip}
                  handleChange={handleChange}
                />
              </div>
              <PhoneInput
                className={`${inputClassNames} rounded-lg`}
                placeholder='Phone Number'
                country='US'
                name='phone_number'
                value={values.phone_number}
                onChange={setPhoneNumber}
              />

              <div className='flex justify-end space-x-15'>
                <Link to='/parent-portal/child-profile'>
                  <CTA
                    bgColor='bg-white'
                    buttonClassName='h-48 w-138 px-8 py-2 border-2 border-purple-light border-solid text-16 text-purple-light rounded-xl sm:w-130'
                    name={'Cancel'}
                    type={'submit'}
                  />
                </Link>

                <CTA
                  buttonClassName='flex flex-row justify-center items-center h-48 w-138 text-16 text-white sm:w-130'
                  className='rounded-2xl bg-purple-light  epp-hover'
                  name={'Save'}
                  type={'submit'}
                  showSpinner={showSpinner}
                />
              </div>
            </div>
          </form>
          {/* Mobile */}
          <div className='xl:hidden'>
            <PreferencesPanel />
          </div>
        </div>
        {/* Desktop */}
        <div className='hidden xl:flex w-300'>
          <PreferencesPanel />
        </div>
      </>
    </div>
  );
};

function ParentAccount({
  updateAccount,
  parent,
  loading,
  requestParentInfo,
  error,
}) {
  const { values, setValues, handleChange, setPhoneNumber } = useForm({
    initialValues: {
      first_name: parent.first_name || '',
      last_name: parent.last_name || '',
      email: parent.email || '',
      address: parent.mailing_address || '',
      city: parent.city || '',
      state: parent.region || '',
      zip: parent.postal_code || '',
      phone_number: parent.phone_number || '',
      toggleEmail:
        parent && parent.setting && parent.settings.marketing.email
          ? true
          : false,

      toggleSMS:
        parent && parent.setting && parent.settings.marketing.sms
          ? true
          : false,
    },
  });

  useEffect(() => {
    requestParentInfo();
  }, []);

  useEffect(() => {
    if (parent.email) {
      setValues({
        ...values,
        first_name: parent.first_name,
        last_name: parent.last_name,
        email: parent.email,
        address: parent.mailing_address || '',
        city: parent.city || '',
        state: parent.region || '',
        zip: parent.postal_code || '',
        phone_number: parent.phone_number || '',
        toggleEmail:
          parent.settings.marketing && parent.settings.marketing.email
            ? true
            : false,
        toggleSMS:
          parent.settings.marketing && parent.settings.marketing.sms
            ? true
            : false,
      });
    }
  }, [parent]);

  const handleSave = (event) => {
    event.preventDefault();
    const payload = {
      first_name: values.first_name,
      last_name: values.last_name,
      email: values.email,
      update_email: values.email !== parent.email,
      phone_number: values.phone_number,
      address: {
        address_1: values.address,
        address_2: '',
        city: values.city,
        state: values.state,
        postal_code: values.zip,
      },
      settings: {
        marketing: {
          email: values.toggleEmail || false,
          sms: values.toggleSMS || false,
        },
      },
    };

    updateAccount({ payload });
  };

  const inputClassNames =
    'h-56 w-full m-w-360 mb-15 pl-20 pr-45 bg-transparent text-16px sm:mb-30';

  const formatError = (error) => {
    if (error.messageType === 'list') {
      return error.message.map((item, index) => (
        <p className='text-red-500' key={index}>
          {item}
        </p>
      ));
    } else {
      return error.message;
    }
  };
  return (
    <div className='mx-auto mt-15 sm:min-w-full md:mt-80'>
      <PPHeader
        title={parentAccount.headerTitle}
        description={parentAccount.description}
      />
      {error && (
        <div className='pl-15 w-full flex flex-col justify-center'>
          {formatError(error)}
        </div>
      )}
      <ParentAccountView
        handleChange={handleChange}
        handleSave={handleSave}
        inputClassNames={inputClassNames}
        setPhoneNumber={setPhoneNumber}
        values={values}
      />
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    updateAccount: (payload) => dispatch(requestUpdateAccount(payload)),
    requestParentInfo: () => dispatch(requestParent()),
  };
}

function mapStateToProps({ user }, ownProps) {
  const { parent, loading, subscription, error } = user;
  return {
    parent,
    loading,
    subscription,
    error,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ParentAccount);
