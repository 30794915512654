import React from 'react';
import InlineButton from '../InlineButton';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';

function BackToStepButton({
  name,
  className,
  containerClassName,
  path,
  onClickAction,
}) {
  let clickAction = onClickAction;

  if (!clickAction && path) {
    const history = useHistory();
    clickAction = () => history.push(path);
  }

  const defaultClassNames = 'sm:flex flex-row flex-wrap items-center';
  const ClassName = clsx(containerClassName, defaultClassNames);
  return (
    <div className={ClassName}>
      <span className='mr-4'>⭠</span>
      &nbsp;
      <InlineButton
        handleClick={clickAction}
        className={className}
        name={name}
      />
    </div>
  );
}

export default BackToStepButton;
