import React, { useState, useEffect, useContext } from 'react';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { useLocation } from 'react-router-dom';
import { isMobileOnly, isTablet } from 'react-device-detect';

import ContentCard from '../../components/ContentCard';
import ForgotLock from '../../assets/purple_lock.svg';
import CTA from '../../components/CTA';
import Pool from '../../UserPool';
import useForm from '../../hooks/useForm';
import FormInput from '../../components/FormInput';
import { MessagingContext } from '../../contexts/MessagingContext';
import ChangePassword from './ChangePassword';
import Success from './Success';
import { screenSpecificStyling } from '../../components/BackgroundImage/constants';
import ForgotPwCharsTablet from '../../components/BackgroundImage/TabletChars/ForgotPwChars';
import ForgotPwCharsMobile from '../../components/BackgroundImage/MobileChars/ForgotPwChars';
import ChangePwCharsTablet from '../../components/BackgroundImage/TabletChars/ChangePwChars';
import ChangePwCharsMobile from '../../components/BackgroundImage/MobileChars/ChangePwChars';
import ChangePwSuccessCharsTablet from '../../components/BackgroundImage/TabletChars/ChangePwSuccessChars';
import ChangePwSuccessCharsMobile from '../../components/BackgroundImage/MobileChars/ChangePwSuccessChars';

export default function ForgotPassword() {
  const [codeSent, setCodeSent] = useState(false);
  const [errMessage, setErrMessagae] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const { addSuccessMessage, addErrorMessage } = useContext(MessagingContext);
  const code = useLocation().search;
  const urlSearchParams = new URLSearchParams(code);
  const emailParam = urlSearchParams.get('email');

  const getCode = (Username) => {
    const cognitoUser = new CognitoUser({
      Username,
      Pool,
    });

    cognitoUser.forgotPassword({
      onSuccess: function (result) {
        setCodeSent(true);
      },
      onFailure: function (err) {
        addErrorMessage('Reset password', err.message);
        setCodeSent(false);
      },
    });
  };

  const { values, handleChange } = useForm({
    initialValues: {
      verificationCode: '',
      email: emailParam || '',
      newPassword: '',
      newPasswordConfirm: '',
    },
  });

  useEffect(() => {
    setCodeSent(false);
    setErrMessagae(false);
    setSuccessMessage(false);
    if (emailParam) {
      getCode(emailParam);
    }
  }, []);

  const onSubmitGetCode = (event) => {
    event.preventDefault();
    const { email } = values;
    getCode(email);
  };

  const onSubmitResetPassword = (event) => {
    event.preventDefault();

    const { email, verificationCode, newPassword, newPasswordConfirm } = values;
    const isEmptyInput =
      !verificationCode || !newPassword || !newPasswordConfirm;

    if (isEmptyInput) {
      setErrMessagae(
        'Need to complete the form above before submitting request.'
      );
    } else if (newPassword !== newPasswordConfirm) {
      setErrMessagae(
        'The new password and the confirm password values do not match.'
      );
    } else if (newPassword.length < 8) {
      setErrMessagae('Password must be 8 or more characters long');
    } else {
      const cognitoUser = new CognitoUser({
        Username: email,
        Pool: Pool,
      });

      return new Promise((resolve, reject) => {
        cognitoUser.confirmPassword(verificationCode, newPassword, {
          onFailure(err) {
            setErrMessagae(err.message);
            reject(err);
          },
          onSuccess() {
            setSuccessMessage(true);
            resolve();
          },
        });
      });
    }
  };

  const isStep1 = !codeSent;
  const isStep2 = codeSent && !successMessage;

  const step = isStep1 ? 'step1' : isStep2 ? 'step2' : 'success';
  const screenStyle = screenSpecificStyling('pt-24', 'pt-40', 'pt-0 pb-0');

  return (
    <div
      className={`flex flex-col items-center justify-center ${screenStyle} px-20 md:px-80`}
    >
      {!codeSent && (
        <div>
          <ContentCard
            cardFooterProps={{
              link: 'https://encantos.helpshift.com/hc/en/',
              linkText: 'here',
              text: 'Visit our FAQs',
              title: 'Need a little help?',
            }}
            cardFooterType='forgot'
            className='md:h-648 md:w-552'
            iconProps={{
              altText: 'lock with a question mark',
              icon: ForgotLock,
            }}
            subTitle={`No worries! It's easy to get a new one.`}
            title='Forgot your password?'
          >
            <form
              className='flex flex-col items-center mt-30 w-full'
              onSubmit={onSubmitGetCode}
            >
              <FormInput
                className='bg-transparent border h-56 w-full mb-30 pl-20 text-16'
                handleChange={handleChange}
                name={'email'}
                placeholder={"Parent's email address"}
                type={'email'}
                value={values.email}
              />
              <CTA
                buttonClassName='min-h-56 rounded-2xl bg-purple-light text-white'
                textClassName='text-16 font-extrabold capitalize text-white'
                name='Send Verification Code'
                type='submit'
              />
            </form>
          </ContentCard>
          {(isTablet && <ForgotPwCharsTablet />) ||
            (isMobileOnly && <ForgotPwCharsMobile />)}
        </div>
      )}
      {codeSent && !successMessage && (
        <div className='flex flex-col items-center'>
          <ChangePassword
            email={values.email}
            errMessage={errMessage}
            handleChange={handleChange}
            newPassword={values.newPassword}
            newPasswordConfirm={values.newPasswordConfirm}
            onSubmitResetPassword={onSubmitResetPassword}
            verificationCode={values.verificationCode}
          />
          {(isTablet && <ChangePwCharsTablet />) ||
            (isMobileOnly && <ChangePwCharsMobile />)}
        </div>
      )}
      {successMessage && (
        <div>
          <Success />
          {(isTablet && <ChangePwSuccessCharsTablet />) ||
            (isMobileOnly && <ChangePwSuccessCharsMobile />)}
        </div>
      )}
    </div>
  );
}
