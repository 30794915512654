import { useState } from 'react';
import { PurchaseList, PurchaseCart } from '../components/Purchase';
import productsData from '../assets/productsData.json';
import '../sass/purchase.scss';
import React from 'react';

const Purchase = () => {
  const [selectedItems, setSelectedItems] = useState([]);

  const addItemToCart = (item) => {
    const _selectedItems = JSON.parse(JSON.stringify(selectedItems));

    const foundIndex = _selectedItems.findIndex(
      ({ id, subscription, type }) =>
        id === item.id &&
        item.subscription.value === subscription.value &&
        type === item.type
    );

    if (!~foundIndex) {
      _selectedItems.push({
        ...item,
        amount: 1,
      });
    } else {
      _selectedItems[foundIndex].amount += 1;
    }

    setSelectedItems(_selectedItems);
  };

  const deleteItemFromCart = (item) => {
    setSelectedItems((prevSelected) =>
      prevSelected.filter(
        ({ id, subscription, type }) =>
          !(
            id === item.id &&
            item.subscription.value === subscription.value &&
            type === item.type
          )
      )
    );
  };

  const changeItemAmountInCart = (item, amount) => {
    if (amount < 1) {
      deleteItemFromCart(item);
      return;
    }

    const _selectedItems = JSON.parse(JSON.stringify(selectedItems));

    const foundIndex = _selectedItems.findIndex(
      ({ id, subscription, type }) =>
        id === item.id &&
        item.subscription.value === subscription.value &&
        type === item.type
    );

    if (!~foundIndex) {
      return;
    }

    _selectedItems[foundIndex].amount = amount;
    setSelectedItems(_selectedItems);
  };

  return (
    <div className='page'>
      <div className='purchase-container'>
        <PurchaseList items={productsData} addItemToCart={addItemToCart} />
        <PurchaseCart
          items={selectedItems}
          onItemDelete={deleteItemFromCart}
          onItemAmountChange={changeItemAmountInCart}
        />
      </div>
    </div>
  );
};

export default Purchase;
